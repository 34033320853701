// vafから移植したRepositry
import exampleRepository from './_vaf/exampleRepository'
import vketSsoRepository from './_vaf/vketSsoRepository'

// My Vket独自のRepository
import { helpRepository } from './helpRepository'
import { assetSettingsRepository } from './assetsSettingsRepository'

export type Methods = 'get' | 'post' | 'put' | 'delete' | 'patch'

export type Repository = {
  [key in Methods]?: { [key: string]: unknown | undefined }
}

export type Repositories = {
  [key: string]: Repository
}

export const repositories = {
  example: exampleRepository,
  vketsso: vketSsoRepository,
  help: helpRepository,
  assetSettings: assetSettingsRepository,
} as const satisfies Repositories

export type RepositoryKey = keyof typeof repositories

export const repositoryFactory = {
  get: <K extends keyof typeof repositories>(name: K) => repositories[name],
}

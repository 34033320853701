/**
 * @group For Developers
 * @category Repositories
 * @module Auth (Vket SSO)
 * @remarks Vket SSO Repository
 * @ref https://hikky.atlassian.net/wiki/spaces/ACCOUNT/pages/462258411/JS+ID
 */
import { z } from 'zod'
import { requireRuntimeConfig } from '@/plugins/_vaf/runtimeConfig'
import { api } from '@/utils/_vaf/api'
import { ssoUserSchema } from '@/models/_vaf/vketSso'
import { ensureValueOf } from '@/utils/zod'
import { raiseError } from '@/utils/_vaf/error'

const fetchSsoProfileResponseSchema = z.object({
  user: ssoUserSchema,
})
const fetchSsoTokenResponseSchema = z.object({
  jwt: z.string(),
})
const fetchSsoJwkResponseSchema = z.object({
  d: z.string(),
  dp: z.string(),
  dq: z.string(),
  e: z.string(),
  kid: z.string(),
  kty: z.string(),
  n: z.string(),
  p: z.string(),
  q: z.string(),
  qi: z.string(),
})

type FetchSsoProfileResponse = z.infer<typeof fetchSsoProfileResponseSchema>
type FetchSsoTokenResponse = z.infer<typeof fetchSsoTokenResponseSchema>
type FetchSsoJwkResponse = z.infer<typeof fetchSsoJwkResponseSchema>

const vketSsoRepository = {
  get: {
    /**
     * @remarks VketSSO: 自分のプロフィールを取得する
     * @ref https://hikky.atlassian.net/wiki/spaces/BKS/pages/668240004/VketSSO
     */
    async fetchSsoProfile(): Promise<FetchSsoProfileResponse | null> {
      const config = requireRuntimeConfig()
      const domain =
        config?.public?.ssoDomain || raiseError('undefined ssoDomain')
      const result = await api.get(`${domain}/profile/me`, {
        credentials: 'include',
      })
      if (!result) return null
      ensureValueOf(fetchSsoProfileResponseSchema, result)
      return result
    },
    /**
     * @remarks VketSSO: 自分のトークンを取得する
     * @ref https://hikky.atlassian.net/wiki/spaces/BKS/pages/668240004/VketSSO
     */
    async fetchSsoToken(origin = ''): Promise<FetchSsoTokenResponse | null> {
      const config = requireRuntimeConfig()
      const domain =
        config?.public?.ssoDomain || raiseError('undefined ssoDomain')
      const audience =
        origin || (window ? window.location.origin : config?.public?.url)
      if (!audience) return null
      const result = await api.get(
        `${domain}/auth/token?audience=${audience}`,
        {
          credentials: 'include',
        }
      )
      if (!result) return null
      ensureValueOf(fetchSsoTokenResponseSchema, result)
      return result
    },
    /**
     * @remarks VketSSO: 自分のJWKを取得する
     * @ref https://hikky.atlassian.net/wiki/spaces/BKS/pages/668240004/VketSSO
     */
    async fetchSsoJwk(): Promise<FetchSsoJwkResponse | null> {
      const config = requireRuntimeConfig()
      const domain =
        config?.public?.ssoDomain || raiseError('undefined ssoDomain')
      const result = await api.get(`${domain}/auth/discovery/keys`)
      if (!result) return null
      ensureValueOf(fetchSsoJwkResponseSchema, result)
      return result
    },
  },
}

export default vketSsoRepository

/**
 * @group Feature List
 * @category Composables
 * @module Auth (Vket SSO)
 */
import { InjectionKey } from 'vue'
import { z } from 'zod'
import type { SsoUser } from '@/models/_vaf/vketSso'
import { requireRuntimeConfig } from '@/plugins/_vaf/runtimeConfig'
import vketSsoRepository from '@/repositories/_vaf/vketSsoRepository'

const useAuthVketSso = () => {
  const REPOSITORY_NAME = 'vketsso'
  const SESSION_STORAGE_KEY_EXP = 'sso-token-expires-in'
  const SESSION_STORAGE_KEY_IAT = 'sso-token-created-at'
  const _config = requireRuntimeConfig()
  const _ssoDomain =
    _config?.public?.ssoDomain || raiseError('undefined ssoDomain')
  const _vketSsoRepository = useApi(REPOSITORY_NAME).repository.value
  const _ssoUser = useState<SsoUser | null>(`${REPOSITORY_NAME}-user`)
  const aliveToken = useState<string | null>(`${REPOSITORY_NAME}-ac`)

  /**
   * @remarks VketSSO: token取得(Fetcher)
   */
  const _fetchToken = async () => {
    try {
      const result = await _vketSsoRepository.get.fetchSsoToken()
      if (!result || result?.jwt === null) {
        throw new Error('Failed to get token')
      }

      const decodedToken = decodeJwt(result.jwt)
      ensureValueOf(
        z.object({
          exp: z.number(),
          iat: z.number(),
        }),
        decodedToken
      )

      setSessionStorageValue(SESSION_STORAGE_KEY_EXP, String(decodedToken.exp))
      setSessionStorageValue(SESSION_STORAGE_KEY_IAT, String(decodedToken.iat))
      aliveToken.value = result.jwt
    } catch (e) {
      console.error(`${e}`)
      aliveToken.value = null
    }
  }

  /**
   * @remarks VketSSO: token検証(sessionStorageを用いた有効期限の確認)
   * @return boolean
   */
  const _verifyTokenByExp = () => {
    if (!aliveToken.value) return false
    const aliveTokenExpiresIn = getSessionStorageValue(SESSION_STORAGE_KEY_EXP)
    const expired = aliveTokenExpiresIn || 0
    const expiredUnixTime = Number(expired)
    // note: 桁数が異なるので1/1000倍にする
    const currentUnixTime = new Date().getTime() / 1000
    if (currentUnixTime < expiredUnixTime) return true
    removeSessionStorageValue(SESSION_STORAGE_KEY_EXP)
    removeSessionStorageValue(SESSION_STORAGE_KEY_IAT)
    aliveToken.value = null
    return false
  }

  /**
   * @remarks VketSSO: ログイン
   * @param redirectUri string default: `${ssoDomain}/close` は閉じるだけのページ
   */
  const login = (redirectUri = `${_ssoDomain}/close`) => {
    // デフォルト引数が null になることがあるので、null の場合のフォールバック指定
    if (!redirectUri) {
      redirectUri = `${_ssoDomain}/close`
    }
    const url = `${_ssoDomain}/auth/vket_account/login?redirect_uri=${redirectUri}`
    return window.open(url)
  }

  /**
   * @remarks VketSSO: ログアウト
   * @param redirectUri string default: `${ssoDomain}/close` は閉じるだけのページ
   */
  const logout = (redirectUri = `${_ssoDomain}/close`) => {
    // デフォルト引数が null になることがあるので、null の場合のフォールバック指定
    if (!redirectUri) {
      redirectUri = `${_ssoDomain}/close`
    }
    const url = `${_ssoDomain}/auth/vket_account/logout?callback_url=${redirectUri}`
    return window.open(url)
  }

  /**
   * @remarks VketSSO: SSO User をfetchする
   */
  const fetchSsoUser = async () => {
    try {
      const result = await _vketSsoRepository.get.fetchSsoProfile()
      if (!result || result?.user === null)
        throw new Error('Failed to fetch sso profile')
      _ssoUser.value = result.user
    } catch (e) {
      console.error(e)
      _ssoUser.value = null
    }
  }

  /**
   * @remarks VketSSO: SSO User をリセットする
   */
  const resetSsoUser = () => {
    _ssoUser.value = null
  }

  /**
   * @remarks VketSSO: SSO User のをstateをreturnする
   * @param awaitRefetch boolean default: true
   * @return Ref<SsoUser | null>
   */
  const getSsoUserState = async (awaitRefetch = true) => {
    if (awaitRefetch) await fetchSsoUser()
    return readonly(_ssoUser)
  }

  /**
   * @remarks VketSSO: login中である場合はtrue, そうでない場合はfalseをreturnする
   * @param awaitRefetch boolean default: true
   * @return boolean
   */
  const isLoggedIn = async (awaitRefetch = true) => {
    const currentState = await getSsoUserState(awaitRefetch)
    return currentState.value !== undefined && currentState.value !== null
  }

  /**
   * @remarks VketSSO: token取得(Getter)
   */
  const getToken = async (
    returnType: 'encoded' | 'decodedObject' | 'decodedJson' = 'encoded'
  ) => {
    try {
      if (!_verifyTokenByExp()) {
        await _fetchToken()
      }
      if (!aliveToken.value) throw new Error('Failed to get token')

      const decodedToken = decodeJwt(aliveToken.value)
      ensureValueOf(
        z.object({
          exp: z.number(),
          iat: z.number(),
        }),
        decodedToken
      )
      // note: returnType = 'decodedObject' はデコードして返す
      if (returnType === 'decodedObject') return decodedToken
      // note: returnType = 'decodedJson' はデコードした結果をJSONとして返す
      if (returnType === 'decodedJson') return JSON.stringify(decodedToken)
      // note: デフォルトはそのまま返す
      return aliveToken.value
    } catch (e) {
      console.error(`${e}`)
      return null
    }
  }

  /**
   * @remarks VketSSO: token検証Keyの取得
   */

  const getJwk = async (): Promise<void> => {
    try {
      const result = await vketSsoRepository.get.fetchSsoJwk()
      if (!result) throw new Error('Failed to get jwk')
      // TODO: jwkの使用タイミングで追加実装。現在はheliportからもheliscriptからも使わないはず

      console.info(`${result}`)
    } catch (e) {
      console.error(`${e}`)
    }
  }

  return {
    login,
    logout,
    fetchSsoUser,
    resetSsoUser,
    getSsoUserState,
    getToken,
    getJwk,
    isLoggedIn,
  }
}

export default useAuthVketSso

export type UseAuthVketSso = ReturnType<typeof useAuthVketSso>
export const UseAuthVketSsoInjectionKey: InjectionKey<UseAuthVketSso> =
  Symbol('auth-vket-sso')

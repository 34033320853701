import type { FetchOptions } from 'ohmyfetch'
import { myVketApi } from '@/utils/myVketApi'
import { getApiBaseUrl } from '@/utils/url'
import { requireValueOf } from '@/utils/zod'
import {
  getApiPlatformCodeHelpsResponseSchema,
  getApiPlatformCodeHelpsResponseType,
} from '@/models/resourceApi'

export const helpResponseSchema = getApiPlatformCodeHelpsResponseSchema
export type HelpResponse = getApiPlatformCodeHelpsResponseType

const defaultFetchOptions = (): FetchOptions => {
  const baseUrl = getApiBaseUrl().resourceApiBaseUrl
  return {
    baseURL: baseUrl,
  }
}

export const helpRepository = {
  get: {
    async getHelp() {
      const fetchOptions = defaultFetchOptions()
      const result = await myVketApi.get('/helps', fetchOptions)
      return requireValueOf(helpResponseSchema, result)
    },
  },
  post: {},
  put: {},
  patch: {},
  delete: {},
}

import type { FetchOptions } from 'ohmyfetch'
import { getApiBaseUrl } from '@/utils/url'
import { myVketApi } from '@/utils/myVketApi'
import { requireValueOf } from '@/utils/zod'
import {
  getAssetSettingLabelResponseSchema,
  getAssetSettingLabelResponseType,
} from '@/models/assetUploaderApi'

export const assetSettingLabelResponseSchema =
  getAssetSettingLabelResponseSchema
export type AssetSettingLabelResponse = getAssetSettingLabelResponseType

const prefixPath = '/asset_settings'

const defaultFetchOptions = (): FetchOptions => {
  const baseUrl = getApiBaseUrl().assetUploaderApiBaseUrl
  return {
    baseURL: `${baseUrl}${prefixPath}`,
  }
}

export const assetSettingsRepository = {
  get: {
    async getAssetSettings(label: string) {
      const fetchOptions = defaultFetchOptions()
      const result = await myVketApi.get(`/${label}`, fetchOptions)
      return requireValueOf(assetSettingLabelResponseSchema, result)
    },
  },
  post: {},
  put: {},
  delete: {},
}

import snakecaseKeys from 'snakecase-keys'
import { requireRuntimeConfig } from '@/plugins/_vaf/runtimeConfig'
import { raiseError } from '@/utils/_vaf/error'

/**
 * 環境ごとのAPIのURLを返す
 */
export const getApiBaseUrl = () => {
  const config = requireRuntimeConfig()
  const isMock = config.public?.USE_API_MOCK
  const baseUrl =
    config.public?.NUXT_ENV_BASE_URL ?? raiseError('Missing config baseUrl')
  const apiUrl =
    config.public?.NUXT_ENV_API_URL ?? raiseError('Missing config apiUrl')
  const platformCode =
    config.public?.NUXT_ENV_PLATFORM_CODE ??
    raiseError('Missing config platformCode')
  const channelSessionApiUrl =
    config.public?.NUXT_ENV_CHANNEL_SESSION_API_BASE_URL ??
    raiseError('Missing config channelSessionApiURL')
  const cloudStoreApiUrl =
    config.public?.NUXT_ENV_CHANNEL_SESSION_API_BASE_URL ??
    raiseError('Missing config cloudStoreApiUrl')
  const assetUploaderApiUrl =
    config.public?.NUXT_ENV_UPLOADER_API_BASE_URL ??
    raiseError('Missing config assetUploaderApiUrl')

  const resourceApiBaseUrl = `${baseUrl}${apiUrl}/${isMock ? '' : platformCode}`
  return {
    resourceApiBaseUrl,
    channelSessionApiBaseURL: channelSessionApiUrl,
    cloudStoreApiBaseUrl: cloudStoreApiUrl,
    assetUploaderApiBaseUrl: assetUploaderApiUrl,
  }
}

/**
 * 連想配列をgetのクエリ文字列に変換する
 * @param queryObject クエリパラメータを設定した連想配列
 * @returns getのクエリに渡す文字列
 *
 * @example queryObject {limit: 10, offset: 0, keyword: "hoge"}
 */
export const createUrlSearchParams = (queryObject: Object) => {
  const _queryObject = snakecaseKeys(queryObject, { deep: true })

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const conversionObject = (obj: any, key = ''): string[][] => {
    return Object.entries(obj)
      .filter((item) => item[1] !== undefined)
      .map((item) => {
        if (Array.isArray(item[1])) {
          return item[1].map((_item) => {
            if (typeof _item === 'object') {
              // NOTE: 必要であれば実装するが、存在しないケースだと思うので一旦保留
              // return conversionObject(_item, `${key || ''}[]`)
            }
            return [`${key || ''}${item[0]}[]`, String(_item)]
          })
        }
        if (typeof item[1] === 'object') {
          // NOTE: 必要であれば実装するが、存在しないケースだと思うので一旦保留
          // return conversionObject(item[1], `${key || ''}[${item[0]}]`)
        }
        // NOTE: flatを使うために配列を2重で返す
        return [[`${key || ''}${item[0]}`, String(item[1])]]
      })
      .flat()
  }

  return new URLSearchParams(
    Object.fromEntries(conversionObject(_queryObject))
  ).toString()
}

/**
 * @description ./utils/_vaf/api.tsを継承したmyVket用のAPI呼び出し処理を実装している
 * myVketで実装しているすべてのAPI呼び出しはこのファイルを通して行う
 *
 * @packageDocumentation
 */
import type { FetchOptions } from 'ohmyfetch'
import { api } from '@/utils/_vaf/api'
import { useAuth } from '@/composables/useAuth'

const headers: FetchOptions['headers'] = {
  Accept: 'application/json',
}

// cookieから認証用トークンを取得しなおす
const refreshIdToken = async () => {
  const auth = useAuth()

  if (!auth.state.value.idToken) return
  try {
    await auth.getTokens()
    if (auth.state.value.idToken) {
      headers.Authorization = `Bearer ${auth.state.value.idToken}`
    }
  } catch (error) {
    console.error(error)
    auth.logout()
  }
}

const defaultFetchOptions = (): FetchOptions => {
  return {
    headers,
    onResponseError: async (ctx) => {
      if (
        process.client &&
        ctx &&
        ctx.response &&
        ctx.response.status === 401
      ) {
        const auth = useAuth()
        await auth.getTokens()
      }
    },
  }
}

export const myVketApi = {
  get: async (path: string, fetchOptions: FetchOptions = {}) => {
    await refreshIdToken()
    const options = {
      ...defaultFetchOptions(),
      ...fetchOptions,
    }
    return api.get(path, options)
  },
  post: async (path: string, fetchOptions: FetchOptions = {}) => {
    await refreshIdToken()
    const options = {
      ...defaultFetchOptions(),
      ...fetchOptions,
    }
    return api.post(path, options)
  },
  put: async (path: string, fetchOptions: FetchOptions = {}) => {
    await refreshIdToken()
    const options = {
      ...defaultFetchOptions(),
      ...fetchOptions,
    }
    return api.put(path, options)
  },
  patch: async (path: string, fetchOptions: FetchOptions = {}) => {
    await refreshIdToken()
    const options = {
      ...defaultFetchOptions(),
      ...fetchOptions,
    }
    return api.patch(path, options)
  },
  delete: async (path: string, fetchOptions: FetchOptions = {}) => {
    await refreshIdToken()
    const options = {
      ...defaultFetchOptions(),
      ...fetchOptions,
    }
    return api.delete(path, options)
  },
} as const

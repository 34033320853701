import { computed } from 'vue'
import type { InjectionKey } from 'vue'
import { SsoUser } from '@/models/_vaf/vketSso'

type AuthState = {
  idToken: string | null
}

/**
 * @description useAuthVketSsoをラップしている。こちらにMy Vket独自の改修を行う
 */
export const useAuth = () => {
  const COOKIE_KEY_JWT = 'sso-token-jwt'

  /** state */
  const state = useState<AuthState>('auth-state', () => ({
    idToken: null,
  }))
  const me = useState<SsoUser | null>()

  /** composables */
  const route = useRoute()
  const authVketSso = useAuthVketSso()
  const root = useRoot()
  const locale = useLocale()

  /** computed */
  // VketAccountのユーザー情報が取得できているか？
  const isGottenMe = computed(() => !!me.value)

  // ログイン必須の画面か？
  const isRequiredLogin = computed(() => {
    // MyPage配下全て
    if (/^\/(?:en\/)?mypage(\/?$|\/)/.test(route.path)) return true
    // アップロードページ
    if (/^\/(?:en\/)?avatar\/uploader\/?$/.test(route.path)) return true
    // チュートリアル
    if (/^\/(?:en\/)?tutorial\/?$/.test(route.path)) return true
    return false
  })

  const initAuth = () => {
    return authVketSso.getSsoUserState().then((res) => {
      // NOTE: nullの場合は未ログインとして扱う
      if (res.value === null && isRequiredLogin.value) {
        return login(locale.localePath(route.path || '/'))
      }
      me.value = res.value
      // ログイン済みの場合 -> トークンを取得
      getTokens()
      return ''
    })
  }

  /**
   * ログイン処理を行う
   * ログイン画面は別タブで行うため以下のような動きをすると画面が固まるのでトップページに移動しつつログインができたら指定の画面へ戻るようにする
   * ・ログイン画面のタブを開いたまま元のタブに戻る
   * ・新規ウィンドウが何らかの影響で開けない
   * NOTE: 可能であればuseAuth内でエラートーストを出したかったが、useI18nがエラーになるため、画面側でトーストの表示を行う
   * @param redirectUrl ログイン後のリダイレクト先
   * @returns Promise<string> エラーメッセージのキー
   */
  const login = (redirectUrl = route.fullPath) => {
    root.startLoading()
    const newWindow = authVketSso.login()
    // NOTE: ポップアップブロッカーやオリジンポリシー違反でwindowが開けない場合はnullが返却される
    if (!newWindow) {
      location.href = locale.localePath('/')
      return 'login.error.window'
    }
    // ウィンドウが戻ってくきたら、リダイレクトする
    return new Promise<string>((resolve) => {
      const checkWindowClosed = setInterval(async () => {
        if (newWindow.closed) {
          clearInterval(checkWindowClosed)
          const isLoggedIn = await authVketSso.isLoggedIn()
          if (isLoggedIn) {
            location.href = redirectUrl
            return resolve('')
          }
          // タブを手動で閉じた場合はログインが行われないのでトップのページに戻る
          location.href = locale.localePath('/')
          resolve('login.error.failure')
        }
      }, 1000)
    }).catch((error) => {
      console.error(error)
      // NOTE: エラーになると画面が固まるのでメッセージを表示しトップページに戻る
      location.href = locale.localePath('/')
      return 'login.error.failure'
    })
  }

  /**
   * NOTE: 可能であればuseAuth内でエラートーストを出したかったが、useI18nがエラーになるため、画面側でトーストの表示を行う
   * @returns
   */
  const logout = async () => {
    try {
      root.startLoading()
      await removeSingleCookieValue(COOKIE_KEY_JWT)
      const newWindow = authVketSso.logout()

      // NOTE: ポップアップブロッカーやオリジンポリシー違反でwindowが開けない場合はnullが返却される
      if (!newWindow) {
        location.href = locale.localePath('/')
        return 'login.error.window'
      }

      await new Promise((resolve) => {
        const checkWindowClosed = setInterval(() => {
          if (newWindow.closed) {
            clearInterval(checkWindowClosed)
            location.href = locale.localePath('/')
            resolve(true)
          }
        }, 1000)
      })
    } catch (error) {
      console.error(error)
      // NOTE: エラーになると画面が固まるのでメッセージを表示しトップページに戻る
      location.href = locale.localePath('/')
      return 'login.error.failure'
    }
  }

  /** 認証用トークン取得 */
  const getTokens = async () => {
    try {
      const decodedToken = await authVketSso.getToken('encoded')
      state.value.idToken = String(decodedToken)
      setSingleCookieValue(COOKIE_KEY_JWT, String(decodedToken))
    } catch (e) {
      throw new Error(`${e}`)
    }
  }

  return {
    state: readonly(state),
    isGottenMe,
    isRequiredLogin,
    me,
    initAuth,
    login,
    logout,
    getTokens,
  }
}

export type AuthComposable = ReturnType<typeof useAuth>

export const authInjectionKey: InjectionKey<AuthComposable> =
  Symbol('auth-composable')

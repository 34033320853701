import type { InjectionKey } from 'vue'

// modules
import { OFFICIAL_VKET_ID } from '@/utils/constants'

type RootStateType = {
  isLoading: boolean
  isShowSPMenu: boolean // sp時の上部ヘッダーメニューを表示しているかどうか
  isHideHeaderMargin: boolean // sp時の上部ヘッダーメニュー分のmarginを付けるかどうか
  stopScroll: boolean
}

export const useRoot = () => {
  const state = reactive<RootStateType>({
    isLoading: true,
    isShowSPMenu: true,
    isHideHeaderMargin: false,
    stopScroll: false,
  })

  // todo: this.$nuxt.$loading.start をscript setup内で行えるようにしたら置換
  const startLoading = () => {
    state.isLoading = true
    // TODO: scroll無効化解除されないことがあるのでコメントアウト
    // disableScroll()
  }

  const endLoading = () => {
    state.isLoading = false
    // TODO: scroll無効化解除されないことがあるのでコメントアウト
    // enableScroll()
  }

  const stopEvent = (e: Event) => {
    e.preventDefault()
  }

  const disableScroll = () => {
    if (process.server) return
    document.addEventListener('touchmove', stopEvent, { passive: false })
    document.addEventListener('mousewheel', stopEvent, { passive: false })
    state.stopScroll = true
  }

  const enableScroll = () => {
    if (process.server) return
    document.removeEventListener('touchmove', stopEvent)
    document.removeEventListener('mousewheel', stopEvent)
    state.stopScroll = false
  }

  const setIsDisplaySPMenu = (value: boolean) => {
    state.isShowSPMenu = value

    if (value) {
      state.isHideHeaderMargin = false
    }
  }

  const hideHeaderMargin = () => {
    state.isShowSPMenu = false
    state.isHideHeaderMargin = true
  }

  const isOfficialAccount = (vketId?: string) => OFFICIAL_VKET_ID === vketId

  return {
    state: readonly(state),
    startLoading,
    endLoading,
    disableScroll,
    enableScroll,
    setIsDisplaySPMenu,
    hideHeaderMargin,
    isOfficialAccount,
  }
}

export type RootComposable = ReturnType<typeof useRoot>
export const rootInjectionKey: InjectionKey<RootComposable> = Symbol('root')

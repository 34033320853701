/**
 * Nuxt3 FWにおける API composables。
 *
 * @packageDocumentation
 */

import type { FetchOptions } from 'ohmyfetch'
import { UseFetchOptions } from 'nuxt/app'
import { repositoryFactory, RepositoryKey } from '@/repositories/factory'

export const fetcher = async (
  path: string,
  options: UseFetchOptions<FetchOptions>
) => {
  if (options.method === 'GET') {
    // NOTE: SSR、CSRでkeyが変わるのでpathをkeyにする
    // GET以外のリクエストはkeyを指定すると2回目以降のレスポンスがキャッシュされるので設定しない
    options.key = path
  }

  const { data, error } = await useFetch(path, options)
  if (error.value) throw new Error(error.value.message)
  return data.value
}

const _getRepo = <K extends RepositoryKey>(endpoint: K) => {
  return repositoryFactory.get(endpoint)
}

export default function useApi<K extends RepositoryKey>(endpoint: K) {
  const repository = ref(_getRepo(endpoint))
  return {
    repository,
  }
}

import { z } from "zod";

// ファイル内で使うためのschema
const GeneralWorld = z.object({ id: z.number().int(), name: z.string(), description: z.string(), shortDescription: z.string(), isOfficial: z.boolean(), tags: z.array(z.unknown()), thumbnail: z.object({ url: z.string().url().nullable() }), maxNumberOfPeople: z.number().int(), numberOfLikes: z.number().int(), numberOfVisits: z.number().int(), platformCode: z.string(), creatorName: z.string(), worldSetId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminGeneralWorldsBody = z.object({ name: z.string(), description: z.string(), shortDescription: z.string(), isOfficial: z.boolean(), tags: z.array(z.string()), thumbnail: z.string(), maxNumberOfPeople: z.number().int(), numberOfVisits: z.number().int(), platformCode: z.string(), creatorName: z.string() }).partial();
const Message = z.object({ message: z.string() });
const UrlObject = z.object({ url: z.string().nullable() });
const HousingWorldUser = z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), topPageAvatarId: z.number().int(), icon: UrlObject, createdAt: z.string(), updatedAt: z.string() });
const HousingWorld = z.object({ id: z.number().int(), housingSceneId: z.number().int(), worldId: z.string(), worldSetId: z.number().int(), name: z.string(), description: z.string(), shortDescription: z.string(), isOfficial: z.boolean(), tags: z.array(z.string()), thumbnail: z.object({ url: z.string() }), maxNumberOfPeople: z.number().int(), numberOfLikes: z.number().int(), numberOfVisits: z.number().int(), platformCode: z.string(), creatorName: z.string(), createdAt: z.string(), updatedAt: z.string(), vketId: z.string(), liked: z.boolean().optional(), user: HousingWorldUser });
const postApiPlatformCodeHousingWorldsListBody = z.object({ worldIds: z.array(z.string()) });
const patchApiPlatformCodeHousingWorldsWorldIdBody = z.object({ name: z.string(), description: z.string(), shortDescription: z.string(), isOfficial: z.boolean(), passward: z.string(), tag: z.array(z.string()), maxNumberOfPeople: z.number().int(), platformCode: z.string(), creatorName: z.string() }).partial();
const postApiPlatformCodeUserProfilesBody = z.object({ name: z.string(), introduction: z.string().nullable(), icon: z.string().nullable(), topPageAvatarId: z.number(), deleteTopPageAvatarId: z.boolean().optional(), showAchievements: z.boolean().optional() });
const Achievement = z.object({ id: z.number().int(), title: z.string(), description: z.string(), titleEn: z.string(), descriptionEn: z.string(), thumbnail: z.object({ url: z.string().nullable() }), ingameThumbnail: z.object({ url: z.string().nullable() }), accomplishedAt: z.string().optional(), createdAt: z.string(), updatedAt: z.string() });
const User = z.object({ id: z.number().int(), vketId: z.string() });
const Profile = z.object({ name: z.string(), id: z.number().int(), icon: z.object({ url: z.string().nullable(), circle: z.object({ url: z.string().nullable() }) }), userId: z.number().int(), introduction: z.string(), platformCode: z.string(), topPageAvatarId: z.number().nullable(), showAchievements: z.boolean(), isCompleteTutorial: z.boolean(), createdAt: z.string().datetime({ offset: true }), updatedAt: z.string().datetime({ offset: true }), vketId: z.string(), followCount: z.number(), followerCount: z.number(), achievements: z.array(Achievement), achievementCount: z.number(), user: User });
const postApiPlatformCodeUserProfilesListBody = z.object({ vketIds: z.array(z.string()) });
const ProfileDetails = z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: z.object({ url: z.string().nullable(), circle: z.object({ url: z.string().nullable() }) }), topPageAvatarId: z.number().nullable(), blockStatus: z.boolean().optional(), blockedStatus: z.boolean().optional(), followStatus: z.boolean().optional(), followerStatus: z.boolean().optional(), followCount: z.number(), followerCount: z.number(), mutualFollowCount: z.number().optional(), mutualFollowerCount: z.number().optional(), achievements: z.array(Achievement), achievementCount: z.number(), showAchievements: z.boolean(), createdAt: z.string().datetime({ offset: true }), updatedAt: z.string().datetime({ offset: true }), user: User, sendMessage: z.boolean() });
const RelationshipUser = z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string().url().nullable() }), topPageAvatarId: z.number().int().nullable(), followCount: z.number().int(), followerCount: z.number().int(), followStatus: z.boolean(), followerStatus: z.boolean() });
const MutualRelationshipUser = z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string().url().nullable() }), topPageAvatarId: z.number().int().nullable() });
const News = z.object({ id: z.number().int(), category: z.string(), title: z.string(), banner: z.object({ url: z.string().nullable() }), text: z.string().nullable(), detailUrl: z.string(), titleEn: z.string(), bannerEn: UrlObject, textEn: z.string().nullable(), detailUrlEn: z.string(), platformCode: z.string(), displayAt: z.string(), finishAt: z.string(), isPublished: z.boolean(), order: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminNewsBody = z.object({ title: z.string(), banner: z.string().optional(), text: z.string().optional(), detailUrl: z.string().optional(), titleEn: z.string().optional(), bannerEn: z.string().optional(), textEn: z.string().optional(), detailUrlEn: z.string().optional(), displayAt: z.string().optional(), finishAt: z.string().optional(), isPublished: z.boolean().optional(), order: z.number().int().optional() });
const putApiPlatformCodeAdminNewsBody = z.array(z.object({ id: z.number().int(), order: z.number().int() }));
const BlockUser = z.object({ id: z.number().int(), userId: z.number().int(), targetUserId: z.number().int(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), targetUser: z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string() }), topPageAvatarId: z.number().int() }) });
const postApiPlatformCodeUserReportsBody = z.object({ vketId: z.string(), reportReason: z.string(), influenceTarget: z.string(), othersReason: z.string().optional() });
const UserReport = z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), reportStatus: z.string(), reportReason: z.string(), influenceTarget: z.string(), othersReason: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeWorldReportsBody = z.object({ worldSetId: z.number().int(), influenceTarget: z.string(), reportCategory: z.string(), othersReason: z.string().optional() });
const WorldReport = z.object({ id: z.number().int(), vketId: z.string(), worldSetId: z.number().int(), reportStatus: z.string(), reportCategory: z.string(), influenceTarget: z.string(), othersReason: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeReportsBody = z.object({ vketId: z.number().int(), reportReason: z.string().optional(), influenceTarget: z.string().optional(), othersReason: z.string().optional() });
const Report = z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), reportStatus: z.string(), reportReason: z.string(), influenceTarget: z.string(), othersReason: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const UserAchievement = z.object({ label: z.string(), title: z.string(), description: z.string(), condition: z.string(), titleEn: z.string(), descriptionEn: z.string(), conditionEn: z.string(), thumbnail: z.object({ url: z.string().nullable() }), ingameThumbnail: z.object({ url: z.string().nullable() }), accomplishedAt: z.string(), isAccomplished: z.boolean(), accomplishedUsersCount: z.number().int(), showProfile: z.boolean(), startAt: z.string(), isPublished: z.boolean(), createdAt: z.string(), updatedAt: z.string() });
const AchievementReward = z.object({ id: z.number().int(), achievementId: z.number().int(), cloudStoreItemId: z.number().int(), presentId: z.number().int().nullable(), openedAt: z.string().nullable() });
const AchievementDetail = z.object({ id: z.number().int(), title: z.string(), description: z.string(), titleEn: z.string(), thumbnail: z.object({ url: z.string().nullable() }), ingameThumbnail: z.object({ url: z.string().nullable() }), accomplishedAt: z.string(), showProfile: z.boolean(), isAccomplished: z.boolean(), accomplishedUsersCount: z.number().int(), accomplishedUsers: z.array(z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: z.object({ url: z.string() }), topPageAvatarId: z.number().int(), createdAt: z.string(), updatedAt: z.string() })), achievementRewards: z.array(AchievementReward) });
const CheckRoom = z.object({ id: z.number().int(), roomId: z.number().int(), vketId: z.string(), name: z.string(), icon: z.object({ url: z.string() }), message: z.string(), messageAt: z.string(), unreadCount: z.number().int(), lastReadMessageId: z.number().int().nullable(), blockStatus: z.boolean(), blockedStatus: z.boolean(), mutualFollowStatus: z.boolean(), sendMessage: z.boolean(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeUserDirectMessagesBody = z.object({ roomId: z.number().int().optional(), vketId: z.string().optional(), message: z.string() });
const Room = z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), icon: z.object({ url: z.string() }), message: z.string(), messageAt: z.string(), unreadCount: z.number().int(), lastReadMessageId: z.number().int().nullable(), blockStatus: z.boolean(), blockedStatus: z.boolean(), mutualFollowStatus: z.boolean(), sendMessage: z.boolean(), createdAt: z.string(), updatedAt: z.string() });
const HousingScene = z.object({ id: z.number().int(), userId: z.number().int(), worldId: z.string(), sceneJson: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), thumbnail: z.object({ url: z.string().nullable() }), published: z.boolean(), createdAt: z.string(), updatedAt: z.string() });
const PresetHousingHomeData = z.object({ id: z.number().int(), platformCode: z.string(), name: z.string(), path: z.string(), filename: z.string(), order: z.number().int(), tutorialUse: z.boolean(), createdAt: z.string(), updatedAt: z.string() });
const HousingSceneDetail = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), worldId: z.string(), sceneJson: z.string(), name: z.string(), introduction: z.string(), thumbnail: z.object({ url: z.string().nullable() }), published: z.boolean(), createdAt: z.string(), updatedAt: z.string(), presetHousingHome: PresetHousingHomeData.nullable() });
const Icon = z.object({ url: z.string().nullable(), circle: UrlObject });
const UserObject = z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), icon: Icon, topPageAvatarId: z.number().int().nullable() });
const World = z.object({ id: z.number().int(), name: z.string().nullable(), description: z.string().nullable(), shortDescription: z.string().nullish(), spatiumCode: z.string().optional(), worldId: z.string(), housingSceneId: z.number().int().optional(), passwordDigest: z.string().nullish(), isPickup: z.boolean().optional(), pickupOrder: z.number().int().optional(), isOfficial: z.boolean(), officialOrder: z.number().int().optional(), thumbnail: UrlObject, maxNumberOfPeople: z.number().int().optional(), numberOfVisits: z.number().int().optional(), platformCode: z.string(), publishedAt: z.string().nullish(), outGameUrl: z.string().nullish(), outGameName: z.string().nullish(), ingameUrl: z.string().nullish(), creatorVketId: z.string().optional(), creatorName: z.string().nullable(), creatorNameEn: z.string().nullish(), createdAt: z.string(), updatedAt: z.string(), worldSetId: z.number().int(), numberOfLikes: z.number().int(), visitCount: z.number().int(), liked: z.boolean() });
const WorldImage = z.object({ url: z.string().url() });
const WorldPcLoadingImage = z.object({ url: z.string().url() });
const WorldMbLoadingImage = z.object({ url: z.string().url() });
const WorldPortal = z.object({ worldId: z.string(), worldSetId: z.number().int(), id: z.number().int(), platformCode: z.string(), name: z.string(), description: z.string(), thumbnail: z.object({}).partial(), publishedAt: z.string(), creatorVketId: z.string(), creatorName: z.string(), creatorNameEn: z.string(), numberOfLikes: z.number().int(), visitCount: z.number().int(), outGameUrl: z.string(), outGameName: z.string(), ingameUrl: z.string(), isPickup: z.boolean(), isOfficial: z.boolean(), eventUse: z.boolean(), canHostPaidEvent: z.boolean(), createdAt: z.string(), updatedAt: z.string(), liked: z.boolean().optional(), images: z.array(WorldImage).optional(), pcLoadingImages: z.array(WorldPcLoadingImage).optional(), mbLoadingImages: z.array(WorldMbLoadingImage).optional() });
const InternalWorldPortal = z.object({ id: z.number().int(), name: z.string(), description: z.string(), thumbnail: UrlObject, publishedAt: z.string().nullable(), spatiumCode: z.string(), worldId: z.string(), outGameUrl: z.string(), outGameName: z.string(), ingameUrl: z.string(), isOfficial: z.boolean().nullable(), platformCode: z.string(), creatorVketId: z.string(), creatorName: z.string(), creatorNameEn: z.string(), createdAt: z.string(), isPickup: z.boolean().nullable(), updatedAt: z.string(), canHostPaidEvent: z.boolean(), eventUse: z.boolean(), publishToPortal: z.boolean(), nsfw: z.boolean(), images: z.array(WorldImage).optional(), pcLoadingImages: z.array(WorldPcLoadingImage).optional(), mbLoadingImages: z.array(WorldMbLoadingImage).optional() });
const postInternalWorldPortalsBody = z.object({ name: z.string(), description: z.string(), publishedAt: z.string(), outGameUrl: z.string(), outGameName: z.string(), ingameUrl: z.string(), isPickup: z.string(), isOfficial: z.string(), platformCode: z.string(), creatorVketId: z.string(), creatorName: z.string(), creatorNameEn: z.string(), worldId: z.string(), thumbnail: z.string(), remoteThumbnailUrl: z.string(), "canHostPaidEvent ": z.boolean(), images: z.array(WorldImage), pcLoadingImages: z.array(WorldPcLoadingImage), mbLoadingImages: z.array(WorldMbLoadingImage) }).partial();
const postApiPlatformCodeUserGuestProfilesBody = z.object({ uuid: z.string(), name: z.string(), introduction: z.string().optional(), icon: z.string().optional() });
const GuestProfile = z.object({ id: z.number().int(), uuid: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: UrlObject, createdAt: z.string(), updatedAt: z.string() });
const putApiPlatformCodeUserGuestProfilesUuidBody = z.object({ name: z.string(), introduction: z.string().optional(), icon: z.string().optional() });
const postApiPlatformCodeHousingScenesWorldIdChangeTextureBody = z.object({ textures: z.array(z.object({ item: z.string(), uuid: z.string(), textureId: z.string(), materialId: z.string() })), nftTextures: z.array(z.object({ item: z.string(), uuid: z.string(), materialId: z.string(), contactAddress: z.string(), tokenId: z.string() })), sceneJson: z.string() });
const postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBody = z.object({ textures: z.array(z.object({ item: z.string(), uuid: z.string(), materialId: z.string(), contractAddress: z.string(), tokenId: z.string() }).partial()) }).partial();
const postApiPlatformCodeHousingScenesWorldIdChangeLinkBody = z.object({ links: z.array(z.object({ item: z.string(), uuid: z.string(), link: z.string() }).partial()) }).partial();
const postApiTemporaryUploadsTextureBody = z.object({ width: z.string(), height: z.string(), clipType: z.string(), image: z.string() }).partial();
const TemporaryUpload = z.object({ id: z.number().int(), userId: z.number().int(), image: z.object({ url: z.string(), texture: UrlObject }), createdAt: z.string(), updatedAt: z.string(), width: z.number().int().nullable(), height: z.number().int().nullable(), clipType: z.string(), url: z.string() });
const postApiTemporaryUploadsNoClipBody = z.object({ clipType: z.string(), image: z.string() }).partial();
const getApiPlatformCodeHousingTextureImagesBody = z.object({ platformCode: z.string(), filename: z.string(), image: z.string().optional(), width: z.string(), height: z.string() });
const Image = z.object({ url: z.string().nullable(), thumbnail: UrlObject, clipped: UrlObject });
const HousingTextureImage = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), image: Image, filename: z.string(), width: z.number().int(), height: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const getApiPlatformCodeHousingMusicsBody = z.object({ platformCode: z.string(), filename: z.string(), music: z.string().optional() });
const Music = z.object({ url: z.string().nullable() });
const HousingMusic = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), uuid: z.string().uuid(), music: Music, filename: z.string(), createdAt: z.string(), updatedAt: z.string() });
const PresetFurnitureTextureMaterial = z.object({ id: z.number().int(), presetFurnitureId: z.number().int(), name: z.string(), createdAt: z.string(), updatedAt: z.string(), thumbnailUrl: z.string(), sampleUrl: z.string() });
const PresetFurnitureWithTextureMaterial = z.object({ id: z.number().int(), furnitureUuidId: z.number().int(), platformCode: z.string(), name: z.string(), path: z.string(), filename: z.string(), objectType: z.string().optional(), furnitureType: z.string(), setLink: z.boolean(), changeTexture: z.boolean(), setMusic: z.boolean(), order: z.number().int(), placeOnTop: z.boolean(), collisiondetection: z.boolean(), furnitureUseCategory: z.string(), generalNameJa: z.string(), generalNameEn: z.string(), polygonCount: z.number().int(), createdAt: z.string(), updatedAt: z.string(), uuid: z.string(), fileUrl: z.string(), thumbnailUrl: z.string(), presetFurnitureTextureMaterials: z.array(PresetFurnitureTextureMaterial) });
const Texture = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), image: Image, filename: z.string(), width: z.number().int(), height: z.number().int(), createdAt: z.unknown().nullable(), updatedAt: z.unknown().nullable() });
const Material = z.object({ id: z.number().int(), presetFurnitureId: z.number().int(), name: z.string(), createdAt: z.string(), updatedAt: z.string() });
const PresetFurniture = z.object({ id: z.number().int(), furnitureUuidId: z.number().int(), platformCode: z.string(), name: z.string(), path: z.string(), filename: z.string(), objectType: z.string().optional(), furnitureType: z.string(), setLink: z.boolean(), changeTexture: z.boolean(), setMusic: z.boolean(), screenShare: z.boolean(), order: z.number().int(), createdAt: z.string(), updatedAt: z.string(), uuid: z.string(), fileUrl: z.string(), thumbnailUrl: z.string() });
const PresetFurnitureChangeTexture = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), housingSceneId: z.number().int(), item: z.string(), materialId: z.number().int(), textureId: z.number().int(), createdAt: z.string(), updatedAt: z.string(), texture: Texture, material: Material, presetFurniture: PresetFurniture });
const PresetFurnitureChangeNftTexture = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), housingSceneId: z.number().int(), item: z.string(), materialId: z.number().int(), contractAddress: z.string(), tokenId: z.string(), createdAt: z.string(), updatedAt: z.string(), material: Material, presetFurniture: PresetFurniture });
const PresetFurnitureChangeLink = z.object({ id: z.number().int(), userId: z.number().int(), platformCode: z.string(), housingSceneId: z.number().int(), presetFurnitureId: z.number().int(), item: z.string(), link: z.string(), createdAt: z.string(), updatedAt: z.string(), presetFurniture: PresetFurniture });
const PresetHousingScene = z.object({ id: z.number().int(), presetHousingHomeId: z.number().int().nullable(), platformCode: z.string(), name: z.string(), sceneJson: z.string(), order: z.number().int(), thumbnail: z.object({ url: z.string() }), createdAt: z.string(), updatedAt: z.string() });
const PresetHousingHome = z.object({ id: z.number().int(), platformCode: z.string(), name: z.string(), path: z.string(), filename: z.string(), order: z.number().int(), tutorialUse: z.boolean(), createdAt: z.string(), updatedAt: z.string(), homeJson: z.string(), detailThumbnailUrl: z.string(), listThumbnailUrl: z.string(), tutorialThumbnailUrl: z.string(), tutorialDetailImageUrl: z.string(), tutorialBgImageUrl: z.string(), presetHousingScenes: z.array(PresetHousingScene).optional() });
const postInternalAchievementsBody = z.object({ platformCode: z.string(), label: z.string(), vketId: z.string() });
const postApiPlatformCodeEventsBody = z.object({ name: z.string(), "description ": z.string().optional(), "thumbnail ": z.string().optional(), startAt: z.string().datetime({ offset: true }).optional(), endAt: z.string().datetime({ offset: true }).optional(), worldSetId: z.number().int().optional(), worldName: z.string().optional(), worldUrl: z.string().optional(), worldType: z.string().optional(), availableParticipant: z.string().optional(), display: z.boolean().optional(), isPaid: z.boolean().optional(), entryFee: z.number().int().optional(), entryCount: z.number().int().optional(), eventCollaborators: z.array(z.string()).optional() });
const EventCollaborator = z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), icon: UrlObject, topPageAvatarId: z.number().int().nullable(), followStatus: z.boolean() });
const WorldObject = z.object({ worldId: z.string(), worldSetId: z.number().int(), name: z.string(), description: z.string().nullable(), thumbnail: UrlObject, platformCode: z.string() });
const EventDetail = z.object({ id: z.number().int(), userId: z.number().int(), user: z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: z.object({ url: z.string().nullable() }), topPageAvatarId: z.number().int().nullable(), createdAt: z.string(), updatedAt: z.string() }), name: z.string(), description: z.string(), thumbnail: z.string(), startAt: z.string().datetime({ offset: true }), endAt: z.string().datetime({ offset: true }), numberOfLikes: z.number(), numberOfParticipants: z.number(), worldSetId: z.number().int().nullable(), worldName: z.string().nullable(), worldUrl: z.string(), worldType: z.string(), positionChannelId: z.string(), availableParticipant: z.string(), display: z.boolean(), isPaid: z.boolean(), entryFee: z.number().int(), entryCount: z.number().int(), ticketItemId: z.number().int().nullable(), isOfficial: z.boolean(), isCanceled: z.boolean(), createdAt: z.string(), updatedAt: z.string(), liked: z.boolean().optional(), participated: z.boolean().optional(), achievements: z.array(z.object({ title: z.string(), description: z.string(), titleEn: z.string(), descriptionEn: z.string(), thumbnail: z.object({ url: z.string().nullable() }), ingameThumbnail: z.object({ url: z.string().nullable() }) })), eventCollaborators: z.array(EventCollaborator).optional(), world: WorldObject.nullish() });
const Event = z.object({ id: z.number().int(), userId: z.number().int(), user: z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: z.object({ url: z.string().nullable() }), topPageAvatarId: z.number().int().nullable(), createdAt: z.string(), updatedAt: z.string() }), name: z.string(), description: z.string(), thumbnail: z.string(), startAt: z.string().datetime({ offset: true }), endAt: z.string().datetime({ offset: true }), numberOfLikes: z.number(), numberOfParticipants: z.number(), worldSetId: z.number().int().nullable(), worldName: z.string().nullable(), worldUrl: z.string(), worldType: z.string(), availableParticipant: z.string(), display: z.boolean(), isPaid: z.boolean(), entryFee: z.number().int(), entryCount: z.number().int(), isOfficial: z.boolean(), isCanceled: z.boolean(), ticketItemId: z.number().int().nullable(), createdAt: z.string(), updatedAt: z.string(), achievements: z.array(z.object({ title: z.string(), description: z.string(), titleEn: z.string(), descriptionEn: z.string(), thumbnail: z.object({ url: z.string().nullable() }), ingameThumbnail: z.object({ url: z.string().nullable() }) })), liked: z.boolean().optional() });
const putApiPlatformCodeEventsEventIdBody = z.object({ name: z.string(), description: z.string(), thumbnail: z.string(), startAt: z.string().datetime({ offset: true }), endAt: z.string().datetime({ offset: true }), worldSetId: z.number().int(), worldUrl: z.string(), worldType: z.string(), availableParticipant: z.string(), display: z.boolean(), isCanceled: z.boolean(), isPaid: z.boolean(), entryFee: z.number().int(), entryCount: z.number().int(), eventCollaborators: z.array(z.string()) }).partial();
const participants = z.object({ userCount: z.number(), users: z.array(z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string().nullable() }), topPageAvatarId: z.number().nullable(), blockStatus: z.boolean().optional(), followStatus: z.boolean().optional() })) });
const EventParticipants = z.object({ eventCount: z.number(), events: z.array(Event) });
const postApiPlatformCodeUserTutorialCompleteBody = z.object({ topPageAvatarId: z.number().int(), presetHousingHomeId: z.number().int() }).partial();
const UnreadCount = z.object({ unreadCount: z.number().int() });
const AchievementContentsEvent = z.object({ id: z.number().int(), userId: z.number().int(), name: z.string(), description: z.string(), thumbnail: z.string(), startAt: z.string(), endAt: z.string(), worldName: z.string(), worldUrl: z.string(), availableParticipant: z.string(), display: z.boolean(), status: z.string(), platformCode: z.string(), isPaid: z.boolean(), entryFee: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const AchievementContents = z.object({ event: AchievementContentsEvent, totalAchievementCount: z.number().int(), accomplishedCount: z.number().int() });
const AdminHousingWorld = z.object({ id: z.number().int(), housingSceneId: z.number().int(), worldId: z.string(), worldSetId: z.number().int(), name: z.string(), description: z.string(), shortDescription: z.string(), isOfficial: z.boolean(), thumbnail: z.object({ url: z.string() }), platformCode: z.string(), creatorName: z.string(), createdAt: z.string(), updatedAt: z.string(), vketId: z.string() });
const RecommendedHousingWorld = z.object({ id: z.number().int(), worldSetId: z.number().int(), startAt: z.string(), endAt: z.string(), isPublished: z.boolean(), order: z.number().int(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), housingWorld: AdminHousingWorld });
const postApiPlatformCodeAdminRecommendedHousingWorldsBody = z.object({ worldSetId: z.number().int(), startAt: z.string().optional(), endAt: z.string().optional(), isPublished: z.boolean().optional(), order: z.number().int().optional() });
const AdminWorldPortal = z.object({ id: z.number().int(), worldId: z.string(), platformCode: z.string(), name: z.string(), description: z.string(), thumbnail: z.object({}).partial(), publishedAt: z.string(), creatorVketId: z.string(), creatorName: z.string(), creatorNameEn: z.string(), outGameUrl: z.string(), outGameName: z.string(), ingameUrl: z.string(), isPickup: z.boolean(), isOfficial: z.boolean(), createdAt: z.string(), updatedAt: z.string(), images: z.array(WorldImage).optional() });
const RecommendedWorld = z.object({ id: z.number().int(), worldSetId: z.number().int(), startAt: z.string(), endAt: z.string(), isPublished: z.boolean(), order: z.number().int(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), world: AdminWorldPortal });
const EventItmes = z.object({ cloudStoreId: z.number().int() }).partial();
const AdminEvent = z.object({ id: z.number().int(), userId: z.number().int(), name: z.string(), description: z.string(), thumbnail: z.string(), startAt: z.string().datetime({ offset: true }), endAt: z.string().datetime({ offset: true }), worldSetId: z.number().int().nullable(), worldName: z.string().nullable(), worldUrl: z.string(), worldType: z.string().nullable(), availableParticipant: z.string(), display: z.boolean(), status: z.string().nullable(), platformCode: z.string(), isPaid: z.boolean(), entryFee: z.number().int(), entryCount: z.number().int(), displayAt: z.string().nullable(), ticketItemId: z.number().int().nullable(), isOfficial: z.boolean(), createdAt: z.string(), updatedAt: z.string(), eventItems: z.array(EventItmes), eventTipItems: z.array(EventItmes) });
const postApiPlatformCodeAdminEventsBody = z.object({ name: z.string(), description: z.string().optional(), thumbnail: z.string().optional(), vketId: z.string().optional(), startAt: z.string().optional(), endAt: z.string().optional(), worldSetId: z.number().int().optional(), worldName: z.string().optional(), worldUrl: z.string().optional(), worldType: z.string().optional(), availableParticipant: z.string().optional(), display: z.boolean().optional(), isPaid: z.boolean().optional(), entryFee: z.number().int().optional(), entryCount: z.number().int().optional(), displayAt: z.string().optional(), isOfficial: z.boolean().optional(), eventCollaborators: z.array(z.string()).optional(), eventItems: z.array(z.number()).optional(), eventTipItems: z.array(z.number()).optional() });
const AdminEventDetail = z.object({ id: z.number().int(), userId: z.number().int(), name: z.string(), description: z.string(), thumbnail: z.string(), startAt: z.string().datetime({ offset: true }), endAt: z.string().datetime({ offset: true }), worldSetId: z.number().int().nullable(), worldName: z.string().nullable(), worldUrl: z.string(), worldType: z.string().nullable(), positionChannelId: z.string().nullable(), availableParticipant: z.string(), display: z.boolean(), status: z.string().nullable(), platformCode: z.string(), isPaid: z.boolean(), entryFee: z.number().int(), entryCount: z.number().int(), displayAt: z.string().nullable(), ticketItemId: z.number().int().nullable(), isOfficial: z.boolean(), createdAt: z.string(), updatedAt: z.string(), eventItems: z.array(EventItmes), eventTipItems: z.array(EventItmes), eventCollaborators: z.array(UserObject).optional(), user: UserObject });
const AdminAchievement = z.object({ id: z.number().int(), label: z.string(), platformCode: z.string(), title: z.string(), description: z.string(), condition: z.string(), titleEn: z.string(), descriptionEn: z.string(), conditionEn: z.string(), thumbnail: UrlObject, ingameThumbnail: UrlObject, startAt: z.string(), isPublished: z.boolean(), accomplishedUsersCount: z.number().int(), eventId: z.number().int().nullable(), eventName: z.string().nullable(), cloudStoreItemIds: z.array(z.number()), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminAchievementsBody = z.object({ label: z.string(), title: z.string(), description: z.string().optional(), condition: z.string().optional(), titleEn: z.string().optional(), descriptionEn: z.string().optional(), conditionEn: z.string().optional(), thumbnail: z.string().optional(), ingameThumbnail: z.string().optional(), eventId: z.number().int().optional(), cloudStoreItemIds: z.array(z.number()).optional(), startAt: z.string().optional(), isPublished: z.boolean().optional() });
const Help = z.object({ id: z.number().int(), description: z.string(), descriptionEn: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminHelpsBody = z.object({ description: z.string(), descriptionEn: z.string() });
const AdminUserProfile = z.object({ userId: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: UrlObject, topPageAvatarId: z.number().int().nullable(), showAchievements: z.boolean(), isCompleteTutorial: z.boolean(), status: z.string(), endAt: z.string().nullable(), createdAt: z.string(), updatedAt: z.string() });
const AdminUserProfileDetail = z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), icon: UrlObject, topPageAvatarId: z.number().int().nullable(), showAchievements: z.boolean(), isCompleteTutorial: z.boolean(), status: z.string(), endAt: z.string().nullable(), reason: z.string(), followCount: z.number(), followerCount: z.number(), createdAt: z.string(), updatedAt: z.string() });
const putApiPlatformCodeAdminUsersIdBody = z.object({ status: z.string(), endAt: z.string().optional(), reason: z.string().optional() });
const AdminUserAchievement = z.object({ achievementId: z.number().int(), label: z.string(), platformCode: z.string(), title: z.string(), description: z.string(), condition: z.string(), titleEn: z.string(), descriptionEn: z.string(), conditionEn: z.string(), thumbnail: UrlObject, ingameThumbnail: UrlObject, startAt: z.string(), isPublished: z.boolean(), cloudStoreItemIds: z.array(z.number()), accomplishedAt: z.string() });
const postApiPlatformCodeAdminUserAchievementsVketIdBody = z.object({ achievements: z.array(z.number()), createdAt: z.string() });
const deleteApiPlatformCodeAdminUserAchievementsVketIdBody = z.object({ achievements: z.array(z.number()) });
const AdminWorld = z.object({ id: z.number().int(), worldId: z.string(), platformCode: z.string(), name: z.string(), description: z.string(), thumbnail: UrlObject, publishedAt: z.string(), creatorVketId: z.string(), creatorName: z.string(), creatorNameEn: z.string(), outGameUrl: z.string(), outGameName: z.string(), ingameUrl: z.string(), isPickup: z.boolean(), isOfficial: z.boolean(), officialOrder: z.number().int(), createdAt: z.string(), updatedAt: z.string(), worldSetId: z.number().int() });
const AdminHousing = z.object({ id: z.number().int(), housingSceneId: z.number().int(), worldId: z.string(), worldSetId: z.number().int(), name: z.string(), description: z.string(), shortDescription: z.string(), isOfficial: z.boolean(), officialOrder: z.number().int(), thumbnail: z.object({ url: z.string() }), platformCode: z.string(), creatorName: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeUserPushTokensBody = z.object({ token: z.string(), deviceType: z.string() });
const PushSetting = z.object({ id: z.number().int(), userId: z.number().int(), notification: z.boolean(), news: z.boolean(), event: z.boolean(), like: z.boolean(), sns: z.boolean(), message: z.boolean(), room: z.boolean(), present: z.boolean(), store: z.boolean(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeUserPushSettingsBody = z.object({ notification: z.boolean(), news: z.boolean(), event: z.boolean(), like: z.boolean(), sns: z.boolean(), message: z.boolean(), room: z.boolean(), present: z.boolean(), store: z.boolean() });
const postInternalPushSendBody = z.object({ vketId: z.string(), title: z.string(), body: z.string(), link: z.string(), type: z.string(), platformCode: z.string(), target: z.string().optional(), pushIconUrl: z.string().optional() });
const postApiPlatformCodeAdminPushSendBody = z.object({ title: z.string(), body: z.string(), link: z.string(), image: z.string().optional() });
const PushNotificationHistory = z.object({ id: z.number().int(), title: z.string(), body: z.string(), link: z.string(), image: z.string().nullable(), type: z.string(), isManual: z.boolean(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminUserAchievementsBody = z.object({ achievementId: z.number().int(), vketIds: z.array(z.string()), createdAt: z.string() });
const deleteApiPlatformCodeAdminUserAchievementsBody = z.object({ achievementId: z.number().int(), vketIds: z.array(z.string()) });
const postApiPlatformCodeContactSendBody = z.object({ name: z.string(), email: z.string().optional(), contactEmail: z.string().optional(), vketId: z.string().nullish(), category: z.string(), body: z.string(), image1: z.string().nullish(), image2: z.string().nullish(), image3: z.string().nullish(), occurredAt: z.string().nullish(), device: z.string().nullish(), os: z.string().nullish(), browser: z.string().nullish(), occurredWorld: z.string().nullish(), occurredFrequency: z.string().nullish(), violatingUser: z.string().nullish(), victimizedUser: z.string().nullish() });
const HousingTemporarySceneChangeLink = z.object({ id: z.number().int(), housingTemporarySceneId: z.number().int(), presetFurnitureId: z.number().int(), item: z.string(), link: z.string(), createdAt: z.string(), updatedAt: z.string(), presetFurniture: PresetFurniture });
const HousingTemporarySceneChangeTexture = z.object({ id: z.number().int(), housingTemporarySceneId: z.number().int(), item: z.string(), materialId: z.number().int(), textureId: z.number().int(), createdAt: z.string(), updatedAt: z.string(), texture: Texture, material: Material, presetFurniture: PresetFurniture });
const HousingTemporarySceneChangeNftTexture = z.object({ id: z.number().int(), housingTemporarySceneId: z.number().int(), item: z.string(), materialId: z.number().int(), contractAddress: z.string(), tokenId: z.string(), createdAt: z.string(), updatedAt: z.string(), material: Material, presetFurniture: PresetFurniture });
const HousingTemporaryScene = z.object({ id: z.number().int(), presetHousingHomeId: z.number().int().nullable(), userId: z.number().int(), platformCode: z.string(), sceneJson: z.string(), thumbnail: UrlObject, edited: z.boolean(), createdAt: z.string(), updatedAt: z.string(), housingTemporarySceneChangeLinks: z.array(HousingTemporarySceneChangeLink), housingTemporarySceneChangeTextures: z.array(HousingTemporarySceneChangeTexture), housingTemporarySceneChangeNftTextures: z.array(HousingTemporarySceneChangeNftTexture) });
const postApiPlatformCodeHousingTemporaryScenesBody = z.object({ sceneJson: z.string(), presetHousingHomeId: z.string() });
const PresentStoreItem = z.object({ id: z.number().int(), storeItemId: z.number().int() });
const Present = z.object({ id: z.number().int(), userId: z.number().int(), subjectId: z.number().int(), subjectType: z.string(), openedAt: z.string().nullable(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), achievement: z.object({ id: z.number().int(), label: z.string(), title: z.string(), description: z.string(), condition: z.string(), titleEn: z.string(), descriptionEn: z.string(), conditionEn: z.string(), thumbnail: UrlObject, ingameThumbnail: UrlObject }).nullable(), presentStoreItem: PresentStoreItem.nullable() });
const InternalPresent = z.object({ id: z.number().int(), userId: z.number().int(), subjectId: z.number().int(), subjectType: z.string(), openedAt: z.string().nullable(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), presentStoreItem: PresentStoreItem.nullable() });
const AdminUser = z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), platformCode: z.string(), roles: z.array(z.string()), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminAdminUsersBody = z.object({ vketId: z.string(), roles: z.array(z.string()) });
const putApiPlatformCodeAdminAdminUsersVketIdBody = z.object({ roles: z.array(z.string()) });
const UserStatusHistory = z.object({ id: z.number().int(), userId: z.number().int(), status: z.string(), endAt: z.string().datetime({ offset: true }).nullable(), reason: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const Emote = z.object({ id: z.number().int(), label: z.string(), name: z.string(), nameEn: z.string(), isGeneral: z.boolean(), iconUrl: z.string(), jsonUrl: z.string().nullable(), order: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const InitialEmote = z.object({ id: z.number().int(), emoteId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const InitialWorldEmote = z.object({ id: z.number().int(), spatiumCode: z.string(), worldId: z.string(), emoteId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const FixedWorldEmote = z.object({ id: z.number().int(), spatiumCode: z.string(), worldId: z.string(), emoteId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const WorldEmoteBlacklist = z.object({ id: z.number().int(), spatiumCode: z.string(), worldId: z.string(), emoteId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const EmoteObject = z.object({ spatiumCode: z.string(), worldId: z.string() });
const AvatarTypeEmoteObject = z.object({ avatarType: z.string(), version: z.number().int() });
const postInternalEmotesBody = z.object({ label: z.string(), name: z.string(), nameEn: z.string(), isGeneral: z.boolean(), order: z.number().int(), emoteVersion: z.number().int(), initialEmotes: z.boolean(), initialWorldEmotes: z.array(EmoteObject), fixedWorldEmotes: z.array(EmoteObject), worldEmoteBlacklists: z.array(EmoteObject), avatarTypeEmotes: z.array(AvatarTypeEmoteObject) });
const UserEmotePallet = z.object({ id: z.number().int(), userId: z.number().int(), palletNum: z.number().int(), emoteId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const postInternalUsersVketIdEmotePalletsBody = z.object({ pallets: z.record(z.number().int().nullable()) });
const GuestEmotePallet = z.object({ id: z.number().int(), uuid: z.string(), palletNum: z.number().int(), emoteId: z.number().int(), createdAt: z.string(), updatedAt: z.string() });
const AvatarType = z.object({ id: z.number().int(), label: z.string(), name: z.string(), nameEn: z.string(), createdAt: z.string(), updatedAt: z.string() });
const IngameMessage = z.object({ id: z.number().int(), title: z.string().nullable(), message: z.string(), thumbnail: UrlObject, messageType: z.string(), noWorlds: z.boolean(), officialWorlds: z.boolean(), ugcWorlds: z.boolean(), rooms: z.boolean(), selectedWorlds: z.boolean(), selectedRooms: z.boolean(), noEvents: z.boolean(), officialEvents: z.boolean(), userEvents: z.boolean(), selectedEvents: z.boolean(), notificationType: z.string().nullable(), isImmediately: z.boolean(), startAt: z.string(), endAt: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const AvatarPart = z.object({ id: z.number().int(), uuid: z.string(), partType: z.enum(["head", "hair", "eye", "ear", "bottoms", "tops", "onePiece", "shoes", "accessory"]).nullable(), name: z.string(), description: z.string(), thumbnailUrl: z.string(), bundleUrl: z.string(), published: z.boolean().optional(), createdAt: z.string().datetime({ offset: true }), updatedAt: z.string().datetime({ offset: true }) });
const postApiPlatformCodeAdminAvatarPartsBody = z.object({ uuid: z.string(), name: z.string(), description: z.string().optional(), published: z.boolean().optional() });
const patchApiPlatformCodeAdminAvatarPartsBody = z.object({ avatarParts: z.array(z.object({ uuid: z.string(), name: z.string(), description: z.string().optional(), published: z.boolean().optional() })) });
const postInternalAvatarPartsListBody = z.object({ ids: z.array(z.number()), uuids: z.array(z.string()) }).partial();
const postApiPlatformCodeAdminIngameMessagesBody = z.object({ title: z.string().nullish(), message: z.string(), thumbnail: z.string().nullish(), messageType: z.string(), noWorlds: z.boolean(), officialWorlds: z.boolean(), ugcWorlds: z.boolean(), rooms: z.boolean(), selectedWorlds: z.boolean(), selectedWorldList: z.array(z.number()).optional(), selectedRooms: z.boolean(), selectedRoomList: z.array(z.number()).optional(), noEvents: z.boolean(), officialEvents: z.boolean(), userEvents: z.boolean(), selectedEvents: z.boolean(), selectedEventList: z.array(z.number()).optional(), notificationType: z.string().optional(), isImmediately: z.boolean(), startAt: z.string().nullable(), endAt: z.string().nullish() });
const SelectedWorld = z.object({ worldId: z.string(), worldSetId: z.number().int(), worldListableId: z.number().int(), worldListableType: z.string() });
const SelectedEvent = z.object({ eventId: z.number().int() });
const IngameMessageDetail = z.object({ id: z.number().int(), title: z.string().nullable(), message: z.string(), thumbnail: UrlObject, messageType: z.string(), noWorlds: z.boolean(), officialWorlds: z.boolean(), ugcWorlds: z.boolean(), rooms: z.boolean(), selectedWorlds: z.boolean(), selectedRooms: z.boolean(), noEvents: z.boolean(), officialEvents: z.boolean(), userEvents: z.boolean(), selectedEvents: z.boolean(), notificationType: z.string().nullable(), isImmediately: z.boolean(), startAt: z.string(), endAt: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), selectedWorldList: z.array(SelectedWorld), selectedEventList: z.array(SelectedEvent) });
const postApiPlatformCodeAdminWorldPortalsOfficialBody = z.object({ officialOrder: z.number().int().optional(), isOfficial: z.boolean() });
const MessageSetting = z.object({ id: z.number().int(), userId: z.number().int(), message: z.boolean(), target: z.string(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeUserMessageSettingsBody = z.object({ message: z.boolean(), target: z.string() });
const MessageTargetUser = z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string().url().nullable() }), topPageAvatarId: z.number().int().nullable(), followCount: z.number().int(), followerCount: z.number().int(), followStatus: z.boolean(), followerStatus: z.boolean(), blockStatus: z.boolean(), blockedStatus: z.boolean() });
const NgWord = z.object({ id: z.number().int(), word: z.string(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminNgWordsBody = z.object({ ngWords: z.array(z.string()) });
const AdminHousingScene = z.object({ id: z.number().int(), userId: z.number().int(), presetHousingHomeId: z.number().int(), worldId: z.string(), sceneJson: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), thumbnail: z.object({ url: z.string().nullable() }), published: z.boolean(), createdAt: z.string(), updatedAt: z.string(), user: UserObject });
const AdminHousingSceneDetail = z.object({ id: z.number().int(), userId: z.number().int(), presetHousingHomeId: z.number().int().nullable(), worldId: z.string(), sceneJson: z.string(), name: z.string(), introduction: z.string(), platformCode: z.string(), thumbnail: z.object({ url: z.string().nullable() }), published: z.boolean(), createdAt: z.string(), updatedAt: z.string(), user: UserObject, presetHousingHome: PresetHousingHomeData.nullable() });
const AdminPresetHousingHome = z.object({ id: z.number().int(), platformCode: z.string(), name: z.string(), path: z.string(), filename: z.string(), order: z.number().int(), tutorialUse: z.boolean(), createdAt: z.string(), updatedAt: z.string() });
const postApiPlatformCodeAdminPresetHousingsBody = z.object({ name: z.string(), path: z.string(), filename: z.string(), order: z.number().int(), tutorialUse: z.boolean(), presetHousingScenes: z.array(z.object({ thumbnail: z.string(), name: z.string(), sceneJson: z.string(), tutorialFurnitureUuids: z.string().nullable(), order: z.number().int() })) });
const AdminPresetHousingScene = z.object({ id: z.number().int(), presetHousingHomeId: z.number().int().nullable(), platformCode: z.string(), name: z.string(), sceneJson: z.string(), tutorialFurnitureUuids: z.string().nullable(), order: z.number().int(), thumbnail: z.object({ url: z.string() }), createdAt: z.string(), updatedAt: z.string() });
const AdminPresetHousingHomeDetail = z.object({ id: z.number().int(), platformCode: z.string(), name: z.string(), path: z.string(), filename: z.string(), order: z.number().int(), tutorialUse: z.boolean(), createdAt: z.string(), updatedAt: z.string(), presetHousingScenes: z.array(AdminPresetHousingScene).optional() });
const putApiPlatformCodeAdminPresetHousingsIdBody = z.object({ name: z.string(), path: z.string(), filename: z.string(), order: z.number().int(), tutorialUse: z.boolean(), presetHousingScenes: z.array(z.object({ id: z.string(), thumbnail: z.string(), name: z.string(), sceneJson: z.string(), tutorialFurnitureUuids: z.string(), " order": z.number().int() })) });
const putApiPlatformCodeAdminWorldPortalsEventUseBody = z.object({ ids: z.array(z.number()) });
const AchievementObject = z.object({ id: z.number().int(), title: z.string(), description: z.string(), titleEn: z.string(), descriptionEn: z.string(), thumbnail: z.object({ url: z.string().nullable() }), ingameThumbnail: z.object({ url: z.string().nullable() }), createdAt: z.string(), updatedAt: z.string() });
const AchievementGrantLabel = z.object({ id: z.number().int(), label: z.string(), achievementId: z.number().int(), platformCode: z.string(), createdAt: z.string(), updatedAt: z.string(), startAt: z.string().nullable(), endAt: z.string().nullable(), achievement: AchievementObject });
const postApiPlatformCodeAdminAchievementGrantLabelsBody = z.object({ label: z.string(), achievementId: z.number().int(), startAt: z.string().nullable(), endAt: z.string().nullable() });

// フロントエンドで使うzod定義（model）
export const generalworldSchema = GeneralWorld ;
export type generalworldType = z.infer<typeof generalworldSchema>;
export const postApiPlatformCodeAdminGeneralWorldsBodySchema = postApiPlatformCodeAdminGeneralWorldsBody ;
export type postApiPlatformCodeAdminGeneralWorldsBodyType = z.infer<typeof postApiPlatformCodeAdminGeneralWorldsBodySchema>;
export const messageSchema = Message ;
export type messageType = z.infer<typeof messageSchema>;
export const urlobjectSchema = UrlObject ;
export type urlobjectType = z.infer<typeof urlobjectSchema>;
export const housingworlduserSchema = HousingWorldUser ;
export type housingworlduserType = z.infer<typeof housingworlduserSchema>;
export const housingworldSchema = HousingWorld ;
export type housingworldType = z.infer<typeof housingworldSchema>;
export const postApiPlatformCodeHousingWorldsListBodySchema = postApiPlatformCodeHousingWorldsListBody ;
export type postApiPlatformCodeHousingWorldsListBodyType = z.infer<typeof postApiPlatformCodeHousingWorldsListBodySchema>;
export const patchApiPlatformCodeHousingWorldsWorldIdBodySchema = patchApiPlatformCodeHousingWorldsWorldIdBody ;
export type patchApiPlatformCodeHousingWorldsWorldIdBodyType = z.infer<typeof patchApiPlatformCodeHousingWorldsWorldIdBodySchema>;
export const postApiPlatformCodeUserProfilesBodySchema = postApiPlatformCodeUserProfilesBody ;
export type postApiPlatformCodeUserProfilesBodyType = z.infer<typeof postApiPlatformCodeUserProfilesBodySchema>;
export const achievementSchema = Achievement ;
export type achievementType = z.infer<typeof achievementSchema>;
export const userSchema = User ;
export type userType = z.infer<typeof userSchema>;
export const profileSchema = Profile ;
export type profileType = z.infer<typeof profileSchema>;
export const postApiPlatformCodeUserProfilesListBodySchema = postApiPlatformCodeUserProfilesListBody ;
export type postApiPlatformCodeUserProfilesListBodyType = z.infer<typeof postApiPlatformCodeUserProfilesListBodySchema>;
export const profiledetailsSchema = ProfileDetails ;
export type profiledetailsType = z.infer<typeof profiledetailsSchema>;
export const relationshipuserSchema = RelationshipUser ;
export type relationshipuserType = z.infer<typeof relationshipuserSchema>;
export const mutualrelationshipuserSchema = MutualRelationshipUser ;
export type mutualrelationshipuserType = z.infer<typeof mutualrelationshipuserSchema>;
export const newsSchema = News ;
export type newsType = z.infer<typeof newsSchema>;
export const postApiPlatformCodeAdminNewsBodySchema = postApiPlatformCodeAdminNewsBody ;
export type postApiPlatformCodeAdminNewsBodyType = z.infer<typeof postApiPlatformCodeAdminNewsBodySchema>;
export const putApiPlatformCodeAdminNewsBodySchema = putApiPlatformCodeAdminNewsBody ;
export type putApiPlatformCodeAdminNewsBodyType = z.infer<typeof putApiPlatformCodeAdminNewsBodySchema>;
export const blockuserSchema = BlockUser ;
export type blockuserType = z.infer<typeof blockuserSchema>;
export const postApiPlatformCodeUserReportsBodySchema = postApiPlatformCodeUserReportsBody ;
export type postApiPlatformCodeUserReportsBodyType = z.infer<typeof postApiPlatformCodeUserReportsBodySchema>;
export const userreportSchema = UserReport ;
export type userreportType = z.infer<typeof userreportSchema>;
export const postApiPlatformCodeWorldReportsBodySchema = postApiPlatformCodeWorldReportsBody ;
export type postApiPlatformCodeWorldReportsBodyType = z.infer<typeof postApiPlatformCodeWorldReportsBodySchema>;
export const worldreportSchema = WorldReport ;
export type worldreportType = z.infer<typeof worldreportSchema>;
export const postApiPlatformCodeReportsBodySchema = postApiPlatformCodeReportsBody ;
export type postApiPlatformCodeReportsBodyType = z.infer<typeof postApiPlatformCodeReportsBodySchema>;
export const reportSchema = Report ;
export type reportType = z.infer<typeof reportSchema>;
export const userachievementSchema = UserAchievement ;
export type userachievementType = z.infer<typeof userachievementSchema>;
export const achievementrewardSchema = AchievementReward ;
export type achievementrewardType = z.infer<typeof achievementrewardSchema>;
export const achievementdetailSchema = AchievementDetail ;
export type achievementdetailType = z.infer<typeof achievementdetailSchema>;
export const checkroomSchema = CheckRoom ;
export type checkroomType = z.infer<typeof checkroomSchema>;
export const postApiPlatformCodeUserDirectMessagesBodySchema = postApiPlatformCodeUserDirectMessagesBody ;
export type postApiPlatformCodeUserDirectMessagesBodyType = z.infer<typeof postApiPlatformCodeUserDirectMessagesBodySchema>;
export const roomSchema = Room ;
export type roomType = z.infer<typeof roomSchema>;
export const housingsceneSchema = HousingScene ;
export type housingsceneType = z.infer<typeof housingsceneSchema>;
export const presethousinghomedataSchema = PresetHousingHomeData ;
export type presethousinghomedataType = z.infer<typeof presethousinghomedataSchema>;
export const housingscenedetailSchema = HousingSceneDetail ;
export type housingscenedetailType = z.infer<typeof housingscenedetailSchema>;
export const iconSchema = Icon ;
export type iconType = z.infer<typeof iconSchema>;
export const userobjectSchema = UserObject ;
export type userobjectType = z.infer<typeof userobjectSchema>;
export const worldSchema = World ;
export type worldType = z.infer<typeof worldSchema>;
export const worldimageSchema = WorldImage ;
export type worldimageType = z.infer<typeof worldimageSchema>;
export const worldpcloadingimageSchema = WorldPcLoadingImage ;
export type worldpcloadingimageType = z.infer<typeof worldpcloadingimageSchema>;
export const worldmbloadingimageSchema = WorldMbLoadingImage ;
export type worldmbloadingimageType = z.infer<typeof worldmbloadingimageSchema>;
export const worldportalSchema = WorldPortal ;
export type worldportalType = z.infer<typeof worldportalSchema>;
export const internalworldportalSchema = InternalWorldPortal ;
export type internalworldportalType = z.infer<typeof internalworldportalSchema>;
export const postInternalWorldPortalsBodySchema = postInternalWorldPortalsBody ;
export type postInternalWorldPortalsBodyType = z.infer<typeof postInternalWorldPortalsBodySchema>;
export const postApiPlatformCodeUserGuestProfilesBodySchema = postApiPlatformCodeUserGuestProfilesBody ;
export type postApiPlatformCodeUserGuestProfilesBodyType = z.infer<typeof postApiPlatformCodeUserGuestProfilesBodySchema>;
export const guestprofileSchema = GuestProfile ;
export type guestprofileType = z.infer<typeof guestprofileSchema>;
export const putApiPlatformCodeUserGuestProfilesUuidBodySchema = putApiPlatformCodeUserGuestProfilesUuidBody ;
export type putApiPlatformCodeUserGuestProfilesUuidBodyType = z.infer<typeof putApiPlatformCodeUserGuestProfilesUuidBodySchema>;
export const postApiPlatformCodeHousingScenesWorldIdChangeTextureBodySchema = postApiPlatformCodeHousingScenesWorldIdChangeTextureBody ;
export type postApiPlatformCodeHousingScenesWorldIdChangeTextureBodyType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeTextureBodySchema>;
export const postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBodySchema = postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBody ;
export type postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBodyType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBodySchema>;
export const postApiPlatformCodeHousingScenesWorldIdChangeLinkBodySchema = postApiPlatformCodeHousingScenesWorldIdChangeLinkBody ;
export type postApiPlatformCodeHousingScenesWorldIdChangeLinkBodyType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeLinkBodySchema>;
export const postApiTemporaryUploadsTextureBodySchema = postApiTemporaryUploadsTextureBody ;
export type postApiTemporaryUploadsTextureBodyType = z.infer<typeof postApiTemporaryUploadsTextureBodySchema>;
export const temporaryuploadSchema = TemporaryUpload ;
export type temporaryuploadType = z.infer<typeof temporaryuploadSchema>;
export const postApiTemporaryUploadsNoClipBodySchema = postApiTemporaryUploadsNoClipBody ;
export type postApiTemporaryUploadsNoClipBodyType = z.infer<typeof postApiTemporaryUploadsNoClipBodySchema>;
export const getApiPlatformCodeHousingTextureImagesBodySchema = getApiPlatformCodeHousingTextureImagesBody ;
export type getApiPlatformCodeHousingTextureImagesBodyType = z.infer<typeof getApiPlatformCodeHousingTextureImagesBodySchema>;
export const imageSchema = Image ;
export type imageType = z.infer<typeof imageSchema>;
export const housingtextureimageSchema = HousingTextureImage ;
export type housingtextureimageType = z.infer<typeof housingtextureimageSchema>;
export const getApiPlatformCodeHousingMusicsBodySchema = getApiPlatformCodeHousingMusicsBody ;
export type getApiPlatformCodeHousingMusicsBodyType = z.infer<typeof getApiPlatformCodeHousingMusicsBodySchema>;
export const musicSchema = Music ;
export type musicType = z.infer<typeof musicSchema>;
export const housingmusicSchema = HousingMusic ;
export type housingmusicType = z.infer<typeof housingmusicSchema>;
export const presetfurnituretexturematerialSchema = PresetFurnitureTextureMaterial ;
export type presetfurnituretexturematerialType = z.infer<typeof presetfurnituretexturematerialSchema>;
export const presetfurniturewithtexturematerialSchema = PresetFurnitureWithTextureMaterial ;
export type presetfurniturewithtexturematerialType = z.infer<typeof presetfurniturewithtexturematerialSchema>;
export const textureSchema = Texture ;
export type textureType = z.infer<typeof textureSchema>;
export const materialSchema = Material ;
export type materialType = z.infer<typeof materialSchema>;
export const presetfurnitureSchema = PresetFurniture ;
export type presetfurnitureType = z.infer<typeof presetfurnitureSchema>;
export const presetfurniturechangetextureSchema = PresetFurnitureChangeTexture ;
export type presetfurniturechangetextureType = z.infer<typeof presetfurniturechangetextureSchema>;
export const presetfurniturechangenfttextureSchema = PresetFurnitureChangeNftTexture ;
export type presetfurniturechangenfttextureType = z.infer<typeof presetfurniturechangenfttextureSchema>;
export const presetfurniturechangelinkSchema = PresetFurnitureChangeLink ;
export type presetfurniturechangelinkType = z.infer<typeof presetfurniturechangelinkSchema>;
export const presethousingsceneSchema = PresetHousingScene ;
export type presethousingsceneType = z.infer<typeof presethousingsceneSchema>;
export const presethousinghomeSchema = PresetHousingHome ;
export type presethousinghomeType = z.infer<typeof presethousinghomeSchema>;
export const postInternalAchievementsBodySchema = postInternalAchievementsBody ;
export type postInternalAchievementsBodyType = z.infer<typeof postInternalAchievementsBodySchema>;
export const postApiPlatformCodeEventsBodySchema = postApiPlatformCodeEventsBody ;
export type postApiPlatformCodeEventsBodyType = z.infer<typeof postApiPlatformCodeEventsBodySchema>;
export const eventcollaboratorSchema = EventCollaborator ;
export type eventcollaboratorType = z.infer<typeof eventcollaboratorSchema>;
export const worldobjectSchema = WorldObject ;
export type worldobjectType = z.infer<typeof worldobjectSchema>;
export const eventdetailSchema = EventDetail ;
export type eventdetailType = z.infer<typeof eventdetailSchema>;
export const eventSchema = Event ;
export type eventType = z.infer<typeof eventSchema>;
export const putApiPlatformCodeEventsEventIdBodySchema = putApiPlatformCodeEventsEventIdBody ;
export type putApiPlatformCodeEventsEventIdBodyType = z.infer<typeof putApiPlatformCodeEventsEventIdBodySchema>;
export const participantsSchema = participants ;
export type participantsType = z.infer<typeof participantsSchema>;
export const eventparticipantsSchema = EventParticipants ;
export type eventparticipantsType = z.infer<typeof eventparticipantsSchema>;
export const postApiPlatformCodeUserTutorialCompleteBodySchema = postApiPlatformCodeUserTutorialCompleteBody ;
export type postApiPlatformCodeUserTutorialCompleteBodyType = z.infer<typeof postApiPlatformCodeUserTutorialCompleteBodySchema>;
export const unreadcountSchema = UnreadCount ;
export type unreadcountType = z.infer<typeof unreadcountSchema>;
export const achievementcontentseventSchema = AchievementContentsEvent ;
export type achievementcontentseventType = z.infer<typeof achievementcontentseventSchema>;
export const achievementcontentsSchema = AchievementContents ;
export type achievementcontentsType = z.infer<typeof achievementcontentsSchema>;
export const adminhousingworldSchema = AdminHousingWorld ;
export type adminhousingworldType = z.infer<typeof adminhousingworldSchema>;
export const recommendedhousingworldSchema = RecommendedHousingWorld ;
export type recommendedhousingworldType = z.infer<typeof recommendedhousingworldSchema>;
export const postApiPlatformCodeAdminRecommendedHousingWorldsBodySchema = postApiPlatformCodeAdminRecommendedHousingWorldsBody ;
export type postApiPlatformCodeAdminRecommendedHousingWorldsBodyType = z.infer<typeof postApiPlatformCodeAdminRecommendedHousingWorldsBodySchema>;
export const adminworldportalSchema = AdminWorldPortal ;
export type adminworldportalType = z.infer<typeof adminworldportalSchema>;
export const recommendedworldSchema = RecommendedWorld ;
export type recommendedworldType = z.infer<typeof recommendedworldSchema>;
export const eventitmesSchema = EventItmes ;
export type eventitmesType = z.infer<typeof eventitmesSchema>;
export const admineventSchema = AdminEvent ;
export type admineventType = z.infer<typeof admineventSchema>;
export const postApiPlatformCodeAdminEventsBodySchema = postApiPlatformCodeAdminEventsBody ;
export type postApiPlatformCodeAdminEventsBodyType = z.infer<typeof postApiPlatformCodeAdminEventsBodySchema>;
export const admineventdetailSchema = AdminEventDetail ;
export type admineventdetailType = z.infer<typeof admineventdetailSchema>;
export const adminachievementSchema = AdminAchievement ;
export type adminachievementType = z.infer<typeof adminachievementSchema>;
export const postApiPlatformCodeAdminAchievementsBodySchema = postApiPlatformCodeAdminAchievementsBody ;
export type postApiPlatformCodeAdminAchievementsBodyType = z.infer<typeof postApiPlatformCodeAdminAchievementsBodySchema>;
export const helpSchema = Help ;
export type helpType = z.infer<typeof helpSchema>;
export const postApiPlatformCodeAdminHelpsBodySchema = postApiPlatformCodeAdminHelpsBody ;
export type postApiPlatformCodeAdminHelpsBodyType = z.infer<typeof postApiPlatformCodeAdminHelpsBodySchema>;
export const adminuserprofileSchema = AdminUserProfile ;
export type adminuserprofileType = z.infer<typeof adminuserprofileSchema>;
export const adminuserprofiledetailSchema = AdminUserProfileDetail ;
export type adminuserprofiledetailType = z.infer<typeof adminuserprofiledetailSchema>;
export const putApiPlatformCodeAdminUsersIdBodySchema = putApiPlatformCodeAdminUsersIdBody ;
export type putApiPlatformCodeAdminUsersIdBodyType = z.infer<typeof putApiPlatformCodeAdminUsersIdBodySchema>;
export const adminuserachievementSchema = AdminUserAchievement ;
export type adminuserachievementType = z.infer<typeof adminuserachievementSchema>;
export const postApiPlatformCodeAdminUserAchievementsVketIdBodySchema = postApiPlatformCodeAdminUserAchievementsVketIdBody ;
export type postApiPlatformCodeAdminUserAchievementsVketIdBodyType = z.infer<typeof postApiPlatformCodeAdminUserAchievementsVketIdBodySchema>;
export const deleteApiPlatformCodeAdminUserAchievementsVketIdBodySchema = deleteApiPlatformCodeAdminUserAchievementsVketIdBody ;
export type deleteApiPlatformCodeAdminUserAchievementsVketIdBodyType = z.infer<typeof deleteApiPlatformCodeAdminUserAchievementsVketIdBodySchema>;
export const adminworldSchema = AdminWorld ;
export type adminworldType = z.infer<typeof adminworldSchema>;
export const adminhousingSchema = AdminHousing ;
export type adminhousingType = z.infer<typeof adminhousingSchema>;
export const postApiPlatformCodeUserPushTokensBodySchema = postApiPlatformCodeUserPushTokensBody ;
export type postApiPlatformCodeUserPushTokensBodyType = z.infer<typeof postApiPlatformCodeUserPushTokensBodySchema>;
export const pushsettingSchema = PushSetting ;
export type pushsettingType = z.infer<typeof pushsettingSchema>;
export const postApiPlatformCodeUserPushSettingsBodySchema = postApiPlatformCodeUserPushSettingsBody ;
export type postApiPlatformCodeUserPushSettingsBodyType = z.infer<typeof postApiPlatformCodeUserPushSettingsBodySchema>;
export const postInternalPushSendBodySchema = postInternalPushSendBody ;
export type postInternalPushSendBodyType = z.infer<typeof postInternalPushSendBodySchema>;
export const postApiPlatformCodeAdminPushSendBodySchema = postApiPlatformCodeAdminPushSendBody ;
export type postApiPlatformCodeAdminPushSendBodyType = z.infer<typeof postApiPlatformCodeAdminPushSendBodySchema>;
export const pushnotificationhistorySchema = PushNotificationHistory ;
export type pushnotificationhistoryType = z.infer<typeof pushnotificationhistorySchema>;
export const postApiPlatformCodeAdminUserAchievementsBodySchema = postApiPlatformCodeAdminUserAchievementsBody ;
export type postApiPlatformCodeAdminUserAchievementsBodyType = z.infer<typeof postApiPlatformCodeAdminUserAchievementsBodySchema>;
export const deleteApiPlatformCodeAdminUserAchievementsBodySchema = deleteApiPlatformCodeAdminUserAchievementsBody ;
export type deleteApiPlatformCodeAdminUserAchievementsBodyType = z.infer<typeof deleteApiPlatformCodeAdminUserAchievementsBodySchema>;
export const postApiPlatformCodeContactSendBodySchema = postApiPlatformCodeContactSendBody ;
export type postApiPlatformCodeContactSendBodyType = z.infer<typeof postApiPlatformCodeContactSendBodySchema>;
export const housingtemporaryscenechangelinkSchema = HousingTemporarySceneChangeLink ;
export type housingtemporaryscenechangelinkType = z.infer<typeof housingtemporaryscenechangelinkSchema>;
export const housingtemporaryscenechangetextureSchema = HousingTemporarySceneChangeTexture ;
export type housingtemporaryscenechangetextureType = z.infer<typeof housingtemporaryscenechangetextureSchema>;
export const housingtemporaryscenechangenfttextureSchema = HousingTemporarySceneChangeNftTexture ;
export type housingtemporaryscenechangenfttextureType = z.infer<typeof housingtemporaryscenechangenfttextureSchema>;
export const housingtemporarysceneSchema = HousingTemporaryScene ;
export type housingtemporarysceneType = z.infer<typeof housingtemporarysceneSchema>;
export const postApiPlatformCodeHousingTemporaryScenesBodySchema = postApiPlatformCodeHousingTemporaryScenesBody ;
export type postApiPlatformCodeHousingTemporaryScenesBodyType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesBodySchema>;
export const presentstoreitemSchema = PresentStoreItem ;
export type presentstoreitemType = z.infer<typeof presentstoreitemSchema>;
export const presentSchema = Present ;
export type presentType = z.infer<typeof presentSchema>;
export const internalpresentSchema = InternalPresent ;
export type internalpresentType = z.infer<typeof internalpresentSchema>;
export const adminuserSchema = AdminUser ;
export type adminuserType = z.infer<typeof adminuserSchema>;
export const postApiPlatformCodeAdminAdminUsersBodySchema = postApiPlatformCodeAdminAdminUsersBody ;
export type postApiPlatformCodeAdminAdminUsersBodyType = z.infer<typeof postApiPlatformCodeAdminAdminUsersBodySchema>;
export const putApiPlatformCodeAdminAdminUsersVketIdBodySchema = putApiPlatformCodeAdminAdminUsersVketIdBody ;
export type putApiPlatformCodeAdminAdminUsersVketIdBodyType = z.infer<typeof putApiPlatformCodeAdminAdminUsersVketIdBodySchema>;
export const userstatushistorySchema = UserStatusHistory ;
export type userstatushistoryType = z.infer<typeof userstatushistorySchema>;
export const emoteSchema = Emote ;
export type emoteType = z.infer<typeof emoteSchema>;
export const initialemoteSchema = InitialEmote ;
export type initialemoteType = z.infer<typeof initialemoteSchema>;
export const initialworldemoteSchema = InitialWorldEmote ;
export type initialworldemoteType = z.infer<typeof initialworldemoteSchema>;
export const fixedworldemoteSchema = FixedWorldEmote ;
export type fixedworldemoteType = z.infer<typeof fixedworldemoteSchema>;
export const worldemoteblacklistSchema = WorldEmoteBlacklist ;
export type worldemoteblacklistType = z.infer<typeof worldemoteblacklistSchema>;
export const emoteobjectSchema = EmoteObject ;
export type emoteobjectType = z.infer<typeof emoteobjectSchema>;
export const avatartypeemoteobjectSchema = AvatarTypeEmoteObject ;
export type avatartypeemoteobjectType = z.infer<typeof avatartypeemoteobjectSchema>;
export const postInternalEmotesBodySchema = postInternalEmotesBody ;
export type postInternalEmotesBodyType = z.infer<typeof postInternalEmotesBodySchema>;
export const useremotepalletSchema = UserEmotePallet ;
export type useremotepalletType = z.infer<typeof useremotepalletSchema>;
export const postInternalUsersVketIdEmotePalletsBodySchema = postInternalUsersVketIdEmotePalletsBody ;
export type postInternalUsersVketIdEmotePalletsBodyType = z.infer<typeof postInternalUsersVketIdEmotePalletsBodySchema>;
export const guestemotepalletSchema = GuestEmotePallet ;
export type guestemotepalletType = z.infer<typeof guestemotepalletSchema>;
export const avatartypeSchema = AvatarType ;
export type avatartypeType = z.infer<typeof avatartypeSchema>;
export const ingamemessageSchema = IngameMessage ;
export type ingamemessageType = z.infer<typeof ingamemessageSchema>;
export const avatarpartSchema = AvatarPart ;
export type avatarpartType = z.infer<typeof avatarpartSchema>;
export const postApiPlatformCodeAdminAvatarPartsBodySchema = postApiPlatformCodeAdminAvatarPartsBody ;
export type postApiPlatformCodeAdminAvatarPartsBodyType = z.infer<typeof postApiPlatformCodeAdminAvatarPartsBodySchema>;
export const patchApiPlatformCodeAdminAvatarPartsBodySchema = patchApiPlatformCodeAdminAvatarPartsBody ;
export type patchApiPlatformCodeAdminAvatarPartsBodyType = z.infer<typeof patchApiPlatformCodeAdminAvatarPartsBodySchema>;
export const postInternalAvatarPartsListBodySchema = postInternalAvatarPartsListBody ;
export type postInternalAvatarPartsListBodyType = z.infer<typeof postInternalAvatarPartsListBodySchema>;
export const postApiPlatformCodeAdminIngameMessagesBodySchema = postApiPlatformCodeAdminIngameMessagesBody ;
export type postApiPlatformCodeAdminIngameMessagesBodyType = z.infer<typeof postApiPlatformCodeAdminIngameMessagesBodySchema>;
export const selectedworldSchema = SelectedWorld ;
export type selectedworldType = z.infer<typeof selectedworldSchema>;
export const selectedeventSchema = SelectedEvent ;
export type selectedeventType = z.infer<typeof selectedeventSchema>;
export const ingamemessagedetailSchema = IngameMessageDetail ;
export type ingamemessagedetailType = z.infer<typeof ingamemessagedetailSchema>;
export const postApiPlatformCodeAdminWorldPortalsOfficialBodySchema = postApiPlatformCodeAdminWorldPortalsOfficialBody ;
export type postApiPlatformCodeAdminWorldPortalsOfficialBodyType = z.infer<typeof postApiPlatformCodeAdminWorldPortalsOfficialBodySchema>;
export const messagesettingSchema = MessageSetting ;
export type messagesettingType = z.infer<typeof messagesettingSchema>;
export const postApiPlatformCodeUserMessageSettingsBodySchema = postApiPlatformCodeUserMessageSettingsBody ;
export type postApiPlatformCodeUserMessageSettingsBodyType = z.infer<typeof postApiPlatformCodeUserMessageSettingsBodySchema>;
export const messagetargetuserSchema = MessageTargetUser ;
export type messagetargetuserType = z.infer<typeof messagetargetuserSchema>;
export const ngwordSchema = NgWord ;
export type ngwordType = z.infer<typeof ngwordSchema>;
export const postApiPlatformCodeAdminNgWordsBodySchema = postApiPlatformCodeAdminNgWordsBody ;
export type postApiPlatformCodeAdminNgWordsBodyType = z.infer<typeof postApiPlatformCodeAdminNgWordsBodySchema>;
export const adminhousingsceneSchema = AdminHousingScene ;
export type adminhousingsceneType = z.infer<typeof adminhousingsceneSchema>;
export const adminhousingscenedetailSchema = AdminHousingSceneDetail ;
export type adminhousingscenedetailType = z.infer<typeof adminhousingscenedetailSchema>;
export const adminpresethousinghomeSchema = AdminPresetHousingHome ;
export type adminpresethousinghomeType = z.infer<typeof adminpresethousinghomeSchema>;
export const postApiPlatformCodeAdminPresetHousingsBodySchema = postApiPlatformCodeAdminPresetHousingsBody ;
export type postApiPlatformCodeAdminPresetHousingsBodyType = z.infer<typeof postApiPlatformCodeAdminPresetHousingsBodySchema>;
export const adminpresethousingsceneSchema = AdminPresetHousingScene ;
export type adminpresethousingsceneType = z.infer<typeof adminpresethousingsceneSchema>;
export const adminpresethousinghomedetailSchema = AdminPresetHousingHomeDetail ;
export type adminpresethousinghomedetailType = z.infer<typeof adminpresethousinghomedetailSchema>;
export const putApiPlatformCodeAdminPresetHousingsIdBodySchema = putApiPlatformCodeAdminPresetHousingsIdBody ;
export type putApiPlatformCodeAdminPresetHousingsIdBodyType = z.infer<typeof putApiPlatformCodeAdminPresetHousingsIdBodySchema>;
export const putApiPlatformCodeAdminWorldPortalsEventUseBodySchema = putApiPlatformCodeAdminWorldPortalsEventUseBody ;
export type putApiPlatformCodeAdminWorldPortalsEventUseBodyType = z.infer<typeof putApiPlatformCodeAdminWorldPortalsEventUseBodySchema>;
export const achievementobjectSchema = AchievementObject ;
export type achievementobjectType = z.infer<typeof achievementobjectSchema>;
export const achievementgrantlabelSchema = AchievementGrantLabel ;
export type achievementgrantlabelType = z.infer<typeof achievementgrantlabelSchema>;
export const postApiPlatformCodeAdminAchievementGrantLabelsBodySchema = postApiPlatformCodeAdminAchievementGrantLabelsBody ;
export type postApiPlatformCodeAdminAchievementGrantLabelsBodyType = z.infer<typeof postApiPlatformCodeAdminAchievementGrantLabelsBodySchema>;


// フロントエンドで使うzod定義（api request and response）
export const getApiPlatformCodeAchievementsLabelRequestSchema = z.object({
    platformCode: z.string(),
    label: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAchievementsLabelRequestType = z.infer<typeof getApiPlatformCodeAchievementsLabelRequestSchema>;
export const getApiPlatformCodeAchievementsLabelResponseSchema = z.object({ achievement: Achievement });
export type getApiPlatformCodeAchievementsLabelResponseType = z.infer<typeof getApiPlatformCodeAchievementsLabelResponseSchema>;

export const postApiPlatformCodeAchievementsGrantLabelRequestSchema = z.object({
    platformCode: z.string(),
    label: z.string(),
})
export type postApiPlatformCodeAchievementsGrantLabelRequestType = z.infer<typeof postApiPlatformCodeAchievementsGrantLabelRequestSchema>;
export const postApiPlatformCodeAchievementsGrantLabelResponseSchema = z.object({ achievement: Achievement });
export type postApiPlatformCodeAchievementsGrantLabelResponseType = z.infer<typeof postApiPlatformCodeAchievementsGrantLabelResponseSchema>;

export const getApiPlatformCodeAdminAchievementGrantLabelsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    label: z.string().optional(),
    achievement: z.string().optional(),
})
export type getApiPlatformCodeAdminAchievementGrantLabelsRequestType = z.infer<typeof getApiPlatformCodeAdminAchievementGrantLabelsRequestSchema>;
export const getApiPlatformCodeAdminAchievementGrantLabelsResponseSchema = z.object({ labelCount: z.number().int(), achievementGrantLabels: z.array(AchievementGrantLabel) });
export type getApiPlatformCodeAdminAchievementGrantLabelsResponseType = z.infer<typeof getApiPlatformCodeAdminAchievementGrantLabelsResponseSchema>;

export const postApiPlatformCodeAdminAchievementGrantLabelsRequestSchema = z.object({
    body: postApiPlatformCodeAdminAchievementGrantLabelsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminAchievementGrantLabelsRequestType = z.infer<typeof postApiPlatformCodeAdminAchievementGrantLabelsRequestSchema>;
export const postApiPlatformCodeAdminAchievementGrantLabelsResponseSchema = z.object({ achievementGrantLabel: AchievementGrantLabel }).partial();
export type postApiPlatformCodeAdminAchievementGrantLabelsResponseType = z.infer<typeof postApiPlatformCodeAdminAchievementGrantLabelsResponseSchema>;

export const getApiPlatformCodeAdminAchievementGrantLabelsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminAchievementGrantLabelsIdRequestType = z.infer<typeof getApiPlatformCodeAdminAchievementGrantLabelsIdRequestSchema>;
export const getApiPlatformCodeAdminAchievementGrantLabelsIdResponseSchema = z.object({ achievementGrantLabel: AchievementGrantLabel }).partial();
export type getApiPlatformCodeAdminAchievementGrantLabelsIdResponseType = z.infer<typeof getApiPlatformCodeAdminAchievementGrantLabelsIdResponseSchema>;

export const putApiPlatformCodeAdminAchievementGrantLabelsIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminAchievementGrantLabelsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminAchievementGrantLabelsIdRequestType = z.infer<typeof putApiPlatformCodeAdminAchievementGrantLabelsIdRequestSchema>;
export const putApiPlatformCodeAdminAchievementGrantLabelsIdResponseSchema = z.object({ achievementGrantLabel: AchievementGrantLabel }).partial();
export type putApiPlatformCodeAdminAchievementGrantLabelsIdResponseType = z.infer<typeof putApiPlatformCodeAdminAchievementGrantLabelsIdResponseSchema>;

export const deleteApiPlatformCodeAdminAchievementGrantLabelsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminAchievementGrantLabelsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminAchievementGrantLabelsIdRequestSchema>;
export const deleteApiPlatformCodeAdminAchievementGrantLabelsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminAchievementGrantLabelsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminAchievementGrantLabelsIdResponseSchema>;

export const getApiPlatformCodeAdminAchievementsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    id: z.string().optional(),
    title: z.string().optional(),
    itemId: z.string().optional(),
    eventId: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeAdminAchievementsRequestType = z.infer<typeof getApiPlatformCodeAdminAchievementsRequestSchema>;
export const getApiPlatformCodeAdminAchievementsResponseSchema = z.object({ achievementCount: z.number().int(), achievements: z.array(AdminAchievement) });
export type getApiPlatformCodeAdminAchievementsResponseType = z.infer<typeof getApiPlatformCodeAdminAchievementsResponseSchema>;

export const postApiPlatformCodeAdminAchievementsRequestSchema = z.object({
    body: postApiPlatformCodeAdminAchievementsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminAchievementsRequestType = z.infer<typeof postApiPlatformCodeAdminAchievementsRequestSchema>;
export const postApiPlatformCodeAdminAchievementsResponseSchema = z.object({ achievement: AdminAchievement });
export type postApiPlatformCodeAdminAchievementsResponseType = z.infer<typeof postApiPlatformCodeAdminAchievementsResponseSchema>;

export const getApiPlatformCodeAdminAchievementsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminAchievementsIdRequestType = z.infer<typeof getApiPlatformCodeAdminAchievementsIdRequestSchema>;
export const getApiPlatformCodeAdminAchievementsIdResponseSchema = z.object({ achievement: AdminAchievement });
export type getApiPlatformCodeAdminAchievementsIdResponseType = z.infer<typeof getApiPlatformCodeAdminAchievementsIdResponseSchema>;

export const putApiPlatformCodeAdminAchievementsIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminAchievementsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminAchievementsIdRequestType = z.infer<typeof putApiPlatformCodeAdminAchievementsIdRequestSchema>;
export const putApiPlatformCodeAdminAchievementsIdResponseSchema = z.object({ achievement: AdminAchievement });
export type putApiPlatformCodeAdminAchievementsIdResponseType = z.infer<typeof putApiPlatformCodeAdminAchievementsIdResponseSchema>;

export const deleteApiPlatformCodeAdminAchievementsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminAchievementsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminAchievementsIdRequestSchema>;
export const deleteApiPlatformCodeAdminAchievementsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminAchievementsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminAchievementsIdResponseSchema>;

export const getApiPlatformCodeAdminAdminUsersRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminAdminUsersRequestType = z.infer<typeof getApiPlatformCodeAdminAdminUsersRequestSchema>;
export const getApiPlatformCodeAdminAdminUsersResponseSchema = z.object({ userCount: z.number().int(), users: z.array(AdminUser) });
export type getApiPlatformCodeAdminAdminUsersResponseType = z.infer<typeof getApiPlatformCodeAdminAdminUsersResponseSchema>;

export const postApiPlatformCodeAdminAdminUsersRequestSchema = z.object({
    body: postApiPlatformCodeAdminAdminUsersBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminAdminUsersRequestType = z.infer<typeof postApiPlatformCodeAdminAdminUsersRequestSchema>;
export const postApiPlatformCodeAdminAdminUsersResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminAdminUsersResponseType = z.infer<typeof postApiPlatformCodeAdminAdminUsersResponseSchema>;

export const getApiPlatformCodeAdminAdminUsersVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminAdminUsersVketIdRequestType = z.infer<typeof getApiPlatformCodeAdminAdminUsersVketIdRequestSchema>;
export const getApiPlatformCodeAdminAdminUsersVketIdResponseSchema = z.object({ user: AdminUser });
export type getApiPlatformCodeAdminAdminUsersVketIdResponseType = z.infer<typeof getApiPlatformCodeAdminAdminUsersVketIdResponseSchema>;

export const deleteApiPlatformCodeAdminAdminUsersVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminAdminUsersVketIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminAdminUsersVketIdRequestSchema>;
export const deleteApiPlatformCodeAdminAdminUsersVketIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminAdminUsersVketIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminAdminUsersVketIdResponseSchema>;

export const putApiPlatformCodeAdminAdminUsersVketIdRequestSchema = z.object({
    body: putApiPlatformCodeAdminAdminUsersVketIdBody,
    platformCode: z.string(),
    vketId: z.string(),
})
export type putApiPlatformCodeAdminAdminUsersVketIdRequestType = z.infer<typeof putApiPlatformCodeAdminAdminUsersVketIdRequestSchema>;
export const putApiPlatformCodeAdminAdminUsersVketIdResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminAdminUsersVketIdResponseType = z.infer<typeof putApiPlatformCodeAdminAdminUsersVketIdResponseSchema>;

export const getApiPlatformCodeAdminAvatarPartsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    uuids: z.array(z.string()).optional(),
    avatarPartIds: z.array(z.string()).optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeAdminAvatarPartsRequestType = z.infer<typeof getApiPlatformCodeAdminAvatarPartsRequestSchema>;
export const getApiPlatformCodeAdminAvatarPartsResponseSchema = z.object({ avatarParts: z.array(AvatarPart), avatarPartsCount: z.number().int() });
export type getApiPlatformCodeAdminAvatarPartsResponseType = z.infer<typeof getApiPlatformCodeAdminAvatarPartsResponseSchema>;

export const postApiPlatformCodeAdminAvatarPartsRequestSchema = z.object({
    body: postApiPlatformCodeAdminAvatarPartsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminAvatarPartsRequestType = z.infer<typeof postApiPlatformCodeAdminAvatarPartsRequestSchema>;
export const postApiPlatformCodeAdminAvatarPartsResponseSchema = z.object({ avatarPart: AvatarPart });
export type postApiPlatformCodeAdminAvatarPartsResponseType = z.infer<typeof postApiPlatformCodeAdminAvatarPartsResponseSchema>;

export const patchApiPlatformCodeAdminAvatarPartsRequestSchema = z.object({
    body: patchApiPlatformCodeAdminAvatarPartsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeAdminAvatarPartsRequestType = z.infer<typeof patchApiPlatformCodeAdminAvatarPartsRequestSchema>;
export const patchApiPlatformCodeAdminAvatarPartsResponseSchema = z.object({ avatarParts: z.array(AvatarPart), avatarPartsCount: z.number().int() });
export type patchApiPlatformCodeAdminAvatarPartsResponseType = z.infer<typeof patchApiPlatformCodeAdminAvatarPartsResponseSchema>;

export const getApiPlatformCodeAdminAvatarPartsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminAvatarPartsIdRequestType = z.infer<typeof getApiPlatformCodeAdminAvatarPartsIdRequestSchema>;
export const getApiPlatformCodeAdminAvatarPartsIdResponseSchema = z.object({ avatarPart: AvatarPart });
export type getApiPlatformCodeAdminAvatarPartsIdResponseType = z.infer<typeof getApiPlatformCodeAdminAvatarPartsIdResponseSchema>;

export const patchApiPlatformCodeAdminAvatarPartsIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminAvatarPartsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeAdminAvatarPartsIdRequestType = z.infer<typeof patchApiPlatformCodeAdminAvatarPartsIdRequestSchema>;
export const patchApiPlatformCodeAdminAvatarPartsIdResponseSchema = z.object({ avatarPart: AvatarPart });
export type patchApiPlatformCodeAdminAvatarPartsIdResponseType = z.infer<typeof patchApiPlatformCodeAdminAvatarPartsIdResponseSchema>;

export const getApiPlatformCodeAdminEventsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    id: z.string().optional(),
    title: z.string().optional(),
    worldName: z.string().optional(),
    status: z.string().optional(),
    startFrom: z.string().optional(),
    startTo: z.string().optional(),
    sort: z.string().optional(),
    worldUrl: z.string().optional(),
})
export type getApiPlatformCodeAdminEventsRequestType = z.infer<typeof getApiPlatformCodeAdminEventsRequestSchema>;
export const getApiPlatformCodeAdminEventsResponseSchema = z.object({ eventCount: z.number().int(), events: z.array(AdminEvent) });
export type getApiPlatformCodeAdminEventsResponseType = z.infer<typeof getApiPlatformCodeAdminEventsResponseSchema>;

export const postApiPlatformCodeAdminEventsRequestSchema = z.object({
    body: postApiPlatformCodeAdminEventsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminEventsRequestType = z.infer<typeof postApiPlatformCodeAdminEventsRequestSchema>;
export const postApiPlatformCodeAdminEventsResponseSchema = z.object({ event: AdminEventDetail });
export type postApiPlatformCodeAdminEventsResponseType = z.infer<typeof postApiPlatformCodeAdminEventsResponseSchema>;

export const getApiPlatformCodeAdminEventIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminEventIdRequestType = z.infer<typeof getApiPlatformCodeAdminEventIdRequestSchema>;
export const getApiPlatformCodeAdminEventIdResponseSchema = z.object({ event: AdminEventDetail });
export type getApiPlatformCodeAdminEventIdResponseType = z.infer<typeof getApiPlatformCodeAdminEventIdResponseSchema>;

export const putApiPlatformCodeAdminEventIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminEventsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminEventIdRequestType = z.infer<typeof putApiPlatformCodeAdminEventIdRequestSchema>;
export const putApiPlatformCodeAdminEventIdResponseSchema = z.object({ event: AdminEventDetail });
export type putApiPlatformCodeAdminEventIdResponseType = z.infer<typeof putApiPlatformCodeAdminEventIdResponseSchema>;

export const deleteApiPlatformCodeAdminEventIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminEventIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminEventIdRequestSchema>;
export const deleteApiPlatformCodeAdminEventIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminEventIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminEventIdResponseSchema>;

export const getApiPlatformCodeAdminGeneralWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminGeneralWorldsRequestType = z.infer<typeof getApiPlatformCodeAdminGeneralWorldsRequestSchema>;
export const getApiPlatformCodeAdminGeneralWorldsResponseSchema = z.object({ generalWorlds: z.array(GeneralWorld) }).partial();
export type getApiPlatformCodeAdminGeneralWorldsResponseType = z.infer<typeof getApiPlatformCodeAdminGeneralWorldsResponseSchema>;

export const postApiPlatformCodeAdminGeneralWorldsRequestSchema = z.object({
    body: postApiPlatformCodeAdminGeneralWorldsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminGeneralWorldsRequestType = z.infer<typeof postApiPlatformCodeAdminGeneralWorldsRequestSchema>;
export const postApiPlatformCodeAdminGeneralWorldsResponseSchema = z.object({ generalWorlds: GeneralWorld }).partial();
export type postApiPlatformCodeAdminGeneralWorldsResponseType = z.infer<typeof postApiPlatformCodeAdminGeneralWorldsResponseSchema>;

export const getApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestSchema = z.object({
    platformCode: z.string(),
    worldSetId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestType = z.infer<typeof getApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestSchema>;
export const getApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseSchema = z.object({ generalWorlds: GeneralWorld }).partial();
export type getApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseType = z.infer<typeof getApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseSchema>;

export const patchApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminGeneralWorldsBody,
    platformCode: z.string(),
    worldSetId: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestType = z.infer<typeof patchApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestSchema>;
export const patchApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseSchema = z.object({ generalWorlds: GeneralWorld }).partial();
export type patchApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseType = z.infer<typeof patchApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseSchema>;

export const deleteApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestSchema = z.object({
    platformCode: z.string(),
    worldSetId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminGeneralWorldsWorldSetIdRequestSchema>;
export const deleteApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminGeneralWorldsWorldSetIdResponseSchema>;

export const getApiPlatformCodeAdminHelpsRequestSchema = z.object({
    platformCode: z.string(),
})
export type getApiPlatformCodeAdminHelpsRequestType = z.infer<typeof getApiPlatformCodeAdminHelpsRequestSchema>;
export const getApiPlatformCodeAdminHelpsResponseSchema = z.object({ help: Help });
export type getApiPlatformCodeAdminHelpsResponseType = z.infer<typeof getApiPlatformCodeAdminHelpsResponseSchema>;

export const postApiPlatformCodeAdminHelpsRequestSchema = z.object({
    body: postApiPlatformCodeAdminHelpsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminHelpsRequestType = z.infer<typeof postApiPlatformCodeAdminHelpsRequestSchema>;
export const postApiPlatformCodeAdminHelpsResponseSchema = z.object({ help: Help });
export type postApiPlatformCodeAdminHelpsResponseType = z.infer<typeof postApiPlatformCodeAdminHelpsResponseSchema>;

export const postApiPlatformCodeAdminHousingSceneResetRequestSchema = z.object({
    body: z.object({ worldId: z.string() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminHousingSceneResetRequestType = z.infer<typeof postApiPlatformCodeAdminHousingSceneResetRequestSchema>;
export const postApiPlatformCodeAdminHousingSceneResetResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminHousingSceneResetResponseType = z.infer<typeof postApiPlatformCodeAdminHousingSceneResetResponseSchema>;

export const getApiPlatformCodeAdminHousingScenesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    id: z.string().optional(),
    vketId: z.string().optional(),
    worldId: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeAdminHousingScenesRequestType = z.infer<typeof getApiPlatformCodeAdminHousingScenesRequestSchema>;
export const getApiPlatformCodeAdminHousingScenesResponseSchema = z.object({ housingSceneCount: z.number().int(), housingScenes: z.array(AdminHousingScene) });
export type getApiPlatformCodeAdminHousingScenesResponseType = z.infer<typeof getApiPlatformCodeAdminHousingScenesResponseSchema>;

export const getApiPlatformCodeAdminHousingScenesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminHousingScenesIdRequestType = z.infer<typeof getApiPlatformCodeAdminHousingScenesIdRequestSchema>;
export const getApiPlatformCodeAdminHousingScenesIdResponseSchema = z.object({ housingScene: AdminHousingSceneDetail });
export type getApiPlatformCodeAdminHousingScenesIdResponseType = z.infer<typeof getApiPlatformCodeAdminHousingScenesIdResponseSchema>;

export const getApiPlatformCodeAdminHousingWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    keyword: z.string().optional(),
    worldId: z.string().optional(),
    name: z.string().optional(),
    sort: z.string().optional(),
    isOfficial: z.string().optional(),
})
export type getApiPlatformCodeAdminHousingWorldsRequestType = z.infer<typeof getApiPlatformCodeAdminHousingWorldsRequestSchema>;
export const getApiPlatformCodeAdminHousingWorldsResponseSchema = z.object({ housingWorldCount: z.number().int(), housingWorlds: z.array(AdminHousing) });
export type getApiPlatformCodeAdminHousingWorldsResponseType = z.infer<typeof getApiPlatformCodeAdminHousingWorldsResponseSchema>;

export const putApiPlatformCodeAdminHousingWorldsRequestSchema = z.object({
    body: putApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminHousingWorldsRequestType = z.infer<typeof putApiPlatformCodeAdminHousingWorldsRequestSchema>;
export const putApiPlatformCodeAdminHousingWorldsResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminHousingWorldsResponseType = z.infer<typeof putApiPlatformCodeAdminHousingWorldsResponseSchema>;

export const postApiPlatformCodeAdminHousingWorldsWorldIdIsOfficialRequestSchema = z.object({
    body: postApiPlatformCodeAdminWorldPortalsOfficialBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminHousingWorldsWorldIdIsOfficialRequestType = z.infer<typeof postApiPlatformCodeAdminHousingWorldsWorldIdIsOfficialRequestSchema>;
export const postApiPlatformCodeAdminHousingWorldsWorldIdIsOfficialResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminHousingWorldsWorldIdIsOfficialResponseType = z.infer<typeof postApiPlatformCodeAdminHousingWorldsWorldIdIsOfficialResponseSchema>;

export const getApiPlatformCodeAdminIngameMessagesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminIngameMessagesRequestType = z.infer<typeof getApiPlatformCodeAdminIngameMessagesRequestSchema>;
export const getApiPlatformCodeAdminIngameMessagesResponseSchema = z.object({ ingameMessageCount: z.number().int(), ingameMessages: z.array(IngameMessage) });
export type getApiPlatformCodeAdminIngameMessagesResponseType = z.infer<typeof getApiPlatformCodeAdminIngameMessagesResponseSchema>;

export const postApiPlatformCodeAdminIngameMessagesRequestSchema = z.object({
    body: postApiPlatformCodeAdminIngameMessagesBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminIngameMessagesRequestType = z.infer<typeof postApiPlatformCodeAdminIngameMessagesRequestSchema>;
export const postApiPlatformCodeAdminIngameMessagesResponseSchema = z.object({ ingameMessage: IngameMessageDetail });
export type postApiPlatformCodeAdminIngameMessagesResponseType = z.infer<typeof postApiPlatformCodeAdminIngameMessagesResponseSchema>;

export const getApiPlatformCodeAdminIngameMessagesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminIngameMessagesIdRequestType = z.infer<typeof getApiPlatformCodeAdminIngameMessagesIdRequestSchema>;
export const getApiPlatformCodeAdminIngameMessagesIdResponseSchema = z.object({ ingameMessage: IngameMessageDetail });
export type getApiPlatformCodeAdminIngameMessagesIdResponseType = z.infer<typeof getApiPlatformCodeAdminIngameMessagesIdResponseSchema>;

export const putApiPlatformCodeAdminIngameMessagesIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminIngameMessagesBody,
    platformCode: z.string(),
    id: z.string(),
})
export type putApiPlatformCodeAdminIngameMessagesIdRequestType = z.infer<typeof putApiPlatformCodeAdminIngameMessagesIdRequestSchema>;
export const putApiPlatformCodeAdminIngameMessagesIdResponseSchema = z.object({ ingameMessage: IngameMessageDetail });
export type putApiPlatformCodeAdminIngameMessagesIdResponseType = z.infer<typeof putApiPlatformCodeAdminIngameMessagesIdResponseSchema>;

export const deleteApiPlatformCodeAdminIngameMessagesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminIngameMessagesIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminIngameMessagesIdRequestSchema>;
export const deleteApiPlatformCodeAdminIngameMessagesIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminIngameMessagesIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminIngameMessagesIdResponseSchema>;

export const postApiPlatformCodeAdminNewsRequestSchema = z.object({
    body: postApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminNewsRequestType = z.infer<typeof postApiPlatformCodeAdminNewsRequestSchema>;
export const postApiPlatformCodeAdminNewsResponseSchema = z.object({ news: News });
export type postApiPlatformCodeAdminNewsResponseType = z.infer<typeof postApiPlatformCodeAdminNewsResponseSchema>;

export const getApiPlatformCodeAdminNewsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    category: z.string().optional(),
    id: z.string().optional(),
    title: z.string().optional(),
    status: z.string().optional(),
    startFrom: z.string().optional(),
    startTo: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeAdminNewsRequestType = z.infer<typeof getApiPlatformCodeAdminNewsRequestSchema>;
export const getApiPlatformCodeAdminNewsResponseSchema = z.object({ newsCount: z.number().int(), news: z.array(News) });
export type getApiPlatformCodeAdminNewsResponseType = z.infer<typeof getApiPlatformCodeAdminNewsResponseSchema>;

export const putApiPlatformCodeAdminNewsRequestSchema = z.object({
    body: putApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminNewsRequestType = z.infer<typeof putApiPlatformCodeAdminNewsRequestSchema>;
export const putApiPlatformCodeAdminNewsResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminNewsResponseType = z.infer<typeof putApiPlatformCodeAdminNewsResponseSchema>;

export const getApiPlatformCodeAdminNewsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminNewsIdRequestType = z.infer<typeof getApiPlatformCodeAdminNewsIdRequestSchema>;
export const getApiPlatformCodeAdminNewsIdResponseSchema = z.object({ news: News });
export type getApiPlatformCodeAdminNewsIdResponseType = z.infer<typeof getApiPlatformCodeAdminNewsIdResponseSchema>;

export const putApiPlatformCodeAdminNewsIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminNewsIdRequestType = z.infer<typeof putApiPlatformCodeAdminNewsIdRequestSchema>;
export const putApiPlatformCodeAdminNewsIdResponseSchema = z.object({ news: News });
export type putApiPlatformCodeAdminNewsIdResponseType = z.infer<typeof putApiPlatformCodeAdminNewsIdResponseSchema>;

export const deleteApiPlatformCodeAdminNewsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminNewsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminNewsIdRequestSchema>;
export const deleteApiPlatformCodeAdminNewsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminNewsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminNewsIdResponseSchema>;

export const getApiPlatformCodeAdminNgWordsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    word: z.string().optional(),
})
export type getApiPlatformCodeAdminNgWordsRequestType = z.infer<typeof getApiPlatformCodeAdminNgWordsRequestSchema>;
export const getApiPlatformCodeAdminNgWordsResponseSchema = z.object({ ngWordCount: z.number().int(), ngWords: z.array(NgWord) });
export type getApiPlatformCodeAdminNgWordsResponseType = z.infer<typeof getApiPlatformCodeAdminNgWordsResponseSchema>;

export const postApiPlatformCodeAdminNgWordsRequestSchema = z.object({
    body: postApiPlatformCodeAdminNgWordsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminNgWordsRequestType = z.infer<typeof postApiPlatformCodeAdminNgWordsRequestSchema>;
export const postApiPlatformCodeAdminNgWordsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminNgWordsResponseType = z.infer<typeof postApiPlatformCodeAdminNgWordsResponseSchema>;

export const deleteApiPlatformCodeAdminNgWordsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminNgWordsRequestType = z.infer<typeof deleteApiPlatformCodeAdminNgWordsRequestSchema>;
export const deleteApiPlatformCodeAdminNgWordsResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminNgWordsResponseType = z.infer<typeof deleteApiPlatformCodeAdminNgWordsResponseSchema>;

export const deleteApiPlatformCodeAdminNgWordsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminNgWordsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminNgWordsIdRequestSchema>;
export const deleteApiPlatformCodeAdminNgWordsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminNgWordsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminNgWordsIdResponseSchema>;

export const getApiPlatformCodeAdminPresetHousingHomesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    id: z.string().optional(),
    name: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeAdminPresetHousingHomesRequestType = z.infer<typeof getApiPlatformCodeAdminPresetHousingHomesRequestSchema>;
export const getApiPlatformCodeAdminPresetHousingHomesResponseSchema = z.object({ presetHousingHomeCount: z.number().int(), presetHousingHomes: z.array(AdminPresetHousingHome) });
export type getApiPlatformCodeAdminPresetHousingHomesResponseType = z.infer<typeof getApiPlatformCodeAdminPresetHousingHomesResponseSchema>;

export const postApiPlatformCodeAdminPresetHousingsRequestSchema = z.object({
    body: postApiPlatformCodeAdminPresetHousingsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminPresetHousingsRequestType = z.infer<typeof postApiPlatformCodeAdminPresetHousingsRequestSchema>;
export const postApiPlatformCodeAdminPresetHousingsResponseSchema = z.object({ presetHousingHome: AdminPresetHousingHomeDetail });
export type postApiPlatformCodeAdminPresetHousingsResponseType = z.infer<typeof postApiPlatformCodeAdminPresetHousingsResponseSchema>;

export const putApiPlatformCodeAdminPresetHousingsRequestSchema = z.object({
    body: putApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminPresetHousingsRequestType = z.infer<typeof putApiPlatformCodeAdminPresetHousingsRequestSchema>;
export const putApiPlatformCodeAdminPresetHousingsResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminPresetHousingsResponseType = z.infer<typeof putApiPlatformCodeAdminPresetHousingsResponseSchema>;

export const getApiPlatformCodeAdminPresetHousingsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminPresetHousingsIdRequestType = z.infer<typeof getApiPlatformCodeAdminPresetHousingsIdRequestSchema>;
export const getApiPlatformCodeAdminPresetHousingsIdResponseSchema = z.object({ presetHousingHome: AdminPresetHousingHomeDetail });
export type getApiPlatformCodeAdminPresetHousingsIdResponseType = z.infer<typeof getApiPlatformCodeAdminPresetHousingsIdResponseSchema>;

export const putApiPlatformCodeAdminPresetHousingsIdRequestSchema = z.object({
    body: putApiPlatformCodeAdminPresetHousingsIdBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminPresetHousingsIdRequestType = z.infer<typeof putApiPlatformCodeAdminPresetHousingsIdRequestSchema>;
export const putApiPlatformCodeAdminPresetHousingsIdResponseSchema = z.object({ presetHousingHome: AdminPresetHousingHomeDetail });
export type putApiPlatformCodeAdminPresetHousingsIdResponseType = z.infer<typeof putApiPlatformCodeAdminPresetHousingsIdResponseSchema>;

export const deleteApiPlatformCodeAdminPresetHousingsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminPresetHousingsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminPresetHousingsIdRequestSchema>;
export const deleteApiPlatformCodeAdminPresetHousingsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminPresetHousingsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminPresetHousingsIdResponseSchema>;

export const getApiPlatformCodeAdminPushListRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminPushListRequestType = z.infer<typeof getApiPlatformCodeAdminPushListRequestSchema>;
export const getApiPlatformCodeAdminPushListResponseSchema = z.object({ pushNotificationHistoryCount: z.number().int(), pushNotificationHistories: z.array(PushNotificationHistory) });
export type getApiPlatformCodeAdminPushListResponseType = z.infer<typeof getApiPlatformCodeAdminPushListResponseSchema>;

export const postApiPlatformCodeAdminPushSendRequestSchema = z.object({
    body: postApiPlatformCodeAdminPushSendBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminPushSendRequestType = z.infer<typeof postApiPlatformCodeAdminPushSendRequestSchema>;
export const postApiPlatformCodeAdminPushSendResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminPushSendResponseType = z.infer<typeof postApiPlatformCodeAdminPushSendResponseSchema>;

export const getApiPlatformCodeAdminRecommendedHousingWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    housingWorldId: z.string().optional(),
    name: z.string().optional(),
    sort: z.string().optional(),
    status: z.string().optional(),
    worldId: z.string().optional(),
})
export type getApiPlatformCodeAdminRecommendedHousingWorldsRequestType = z.infer<typeof getApiPlatformCodeAdminRecommendedHousingWorldsRequestSchema>;
export const getApiPlatformCodeAdminRecommendedHousingWorldsResponseSchema = z.object({ housingWorldCount: z.number().int(), recommendedHousingWorlds: z.array(RecommendedHousingWorld) });
export type getApiPlatformCodeAdminRecommendedHousingWorldsResponseType = z.infer<typeof getApiPlatformCodeAdminRecommendedHousingWorldsResponseSchema>;

export const postApiPlatformCodeAdminRecommendedHousingWorldsRequestSchema = z.object({
    body: postApiPlatformCodeAdminRecommendedHousingWorldsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminRecommendedHousingWorldsRequestType = z.infer<typeof postApiPlatformCodeAdminRecommendedHousingWorldsRequestSchema>;
export const postApiPlatformCodeAdminRecommendedHousingWorldsResponseSchema = z.object({ recommendedHousingWorld: RecommendedHousingWorld });
export type postApiPlatformCodeAdminRecommendedHousingWorldsResponseType = z.infer<typeof postApiPlatformCodeAdminRecommendedHousingWorldsResponseSchema>;

export const putApiPlatformCodeAdminRecommendedHousingWorldsRequestSchema = z.object({
    body: putApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminRecommendedHousingWorldsRequestType = z.infer<typeof putApiPlatformCodeAdminRecommendedHousingWorldsRequestSchema>;
export const putApiPlatformCodeAdminRecommendedHousingWorldsResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminRecommendedHousingWorldsResponseType = z.infer<typeof putApiPlatformCodeAdminRecommendedHousingWorldsResponseSchema>;

export const getApiPlatformCodeAdminRecommendedHousingWorldsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminRecommendedHousingWorldsIdRequestType = z.infer<typeof getApiPlatformCodeAdminRecommendedHousingWorldsIdRequestSchema>;
export const getApiPlatformCodeAdminRecommendedHousingWorldsIdResponseSchema = z.object({ recommendedHousingWorld: RecommendedHousingWorld });
export type getApiPlatformCodeAdminRecommendedHousingWorldsIdResponseType = z.infer<typeof getApiPlatformCodeAdminRecommendedHousingWorldsIdResponseSchema>;

export const putApiPlatformCodeAdminRecommendedHousingWorldsIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminRecommendedHousingWorldsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminRecommendedHousingWorldsIdRequestType = z.infer<typeof putApiPlatformCodeAdminRecommendedHousingWorldsIdRequestSchema>;
export const putApiPlatformCodeAdminRecommendedHousingWorldsIdResponseSchema = z.object({ recommendedHousingWorld: RecommendedHousingWorld });
export type putApiPlatformCodeAdminRecommendedHousingWorldsIdResponseType = z.infer<typeof putApiPlatformCodeAdminRecommendedHousingWorldsIdResponseSchema>;

export const deleteApiPlatformCodeAdminRecommendedHousingWorldsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminRecommendedHousingWorldsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminRecommendedHousingWorldsIdRequestSchema>;
export const deleteApiPlatformCodeAdminRecommendedHousingWorldsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminRecommendedHousingWorldsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminRecommendedHousingWorldsIdResponseSchema>;

export const getApiPlatformCodeAdminRecommendedWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    worldPortalId: z.string().optional(),
    name: z.string().optional(),
    sort: z.string().optional(),
    status: z.string().optional(),
    worldId: z.string().optional(),
})
export type getApiPlatformCodeAdminRecommendedWorldsRequestType = z.infer<typeof getApiPlatformCodeAdminRecommendedWorldsRequestSchema>;
export const getApiPlatformCodeAdminRecommendedWorldsResponseSchema = z.object({ worldCount: z.number().int(), recommendedWorlds: z.array(RecommendedWorld) });
export type getApiPlatformCodeAdminRecommendedWorldsResponseType = z.infer<typeof getApiPlatformCodeAdminRecommendedWorldsResponseSchema>;

export const postApiPlatformCodeAdminRecommendedWorldsRequestSchema = z.object({
    body: postApiPlatformCodeAdminRecommendedHousingWorldsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminRecommendedWorldsRequestType = z.infer<typeof postApiPlatformCodeAdminRecommendedWorldsRequestSchema>;
export const postApiPlatformCodeAdminRecommendedWorldsResponseSchema = z.object({ recommendedWorld: RecommendedWorld });
export type postApiPlatformCodeAdminRecommendedWorldsResponseType = z.infer<typeof postApiPlatformCodeAdminRecommendedWorldsResponseSchema>;

export const putApiPlatformCodeAdminRecommendedWorldsRequestSchema = z.object({
    body: putApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminRecommendedWorldsRequestType = z.infer<typeof putApiPlatformCodeAdminRecommendedWorldsRequestSchema>;
export const putApiPlatformCodeAdminRecommendedWorldsResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminRecommendedWorldsResponseType = z.infer<typeof putApiPlatformCodeAdminRecommendedWorldsResponseSchema>;

export const getApiPlatformCodeAdminRecommendedWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminRecommendedWorldIdRequestType = z.infer<typeof getApiPlatformCodeAdminRecommendedWorldIdRequestSchema>;
export const getApiPlatformCodeAdminRecommendedWorldIdResponseSchema = z.object({ recommendedWorld: RecommendedWorld });
export type getApiPlatformCodeAdminRecommendedWorldIdResponseType = z.infer<typeof getApiPlatformCodeAdminRecommendedWorldIdResponseSchema>;

export const putApiPlatformCodeAdminRecommendedWorldIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminRecommendedHousingWorldsBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminRecommendedWorldIdRequestType = z.infer<typeof putApiPlatformCodeAdminRecommendedWorldIdRequestSchema>;
export const putApiPlatformCodeAdminRecommendedWorldIdResponseSchema = z.object({ recommendedWorld: RecommendedWorld });
export type putApiPlatformCodeAdminRecommendedWorldIdResponseType = z.infer<typeof putApiPlatformCodeAdminRecommendedWorldIdResponseSchema>;

export const deleteApiPlatformCodeAdminRecommendedWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminRecommendedWorldIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminRecommendedWorldIdRequestSchema>;
export const deleteApiPlatformCodeAdminRecommendedWorldIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminRecommendedWorldIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminRecommendedWorldIdResponseSchema>;

export const getApiPlatformCodeAdminReportsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    reportStatus: z.string().optional(),
    reportReason: z.string().optional(),
})
export type getApiPlatformCodeAdminReportsRequestType = z.infer<typeof getApiPlatformCodeAdminReportsRequestSchema>;
export const getApiPlatformCodeAdminReportsResponseSchema = z.object({ reports: z.array(Report) });
export type getApiPlatformCodeAdminReportsResponseType = z.infer<typeof getApiPlatformCodeAdminReportsResponseSchema>;

export const getApiPlatformCodeAdminReportsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminReportsIdRequestType = z.infer<typeof getApiPlatformCodeAdminReportsIdRequestSchema>;
export const getApiPlatformCodeAdminReportsIdResponseSchema = z.object({ report: Report });
export type getApiPlatformCodeAdminReportsIdResponseType = z.infer<typeof getApiPlatformCodeAdminReportsIdResponseSchema>;

export const patchApiPlatformCodeAdminReportsIdRequestSchema = z.object({
    body: z.object({ reportStatus: z.string() }).partial(),
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeAdminReportsIdRequestType = z.infer<typeof patchApiPlatformCodeAdminReportsIdRequestSchema>;
export const patchApiPlatformCodeAdminReportsIdResponseSchema = z.object({ report: Report });
export type patchApiPlatformCodeAdminReportsIdResponseType = z.infer<typeof patchApiPlatformCodeAdminReportsIdResponseSchema>;

export const deleteApiPlatformCodeAdminReportsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminReportsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminReportsIdRequestSchema>;
export const deleteApiPlatformCodeAdminReportsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminReportsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminReportsIdResponseSchema>;

export const getApiPlatformCodeAdminRolesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminRolesRequestType = z.infer<typeof getApiPlatformCodeAdminRolesRequestSchema>;
export const getApiPlatformCodeAdminRolesResponseSchema = z.object({ roles: z.array(z.string()) });
export type getApiPlatformCodeAdminRolesResponseType = z.infer<typeof getApiPlatformCodeAdminRolesResponseSchema>;

export const postApiPlatformCodeAdminUserAchievementsRequestSchema = z.object({
    body: postApiPlatformCodeAdminUserAchievementsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminUserAchievementsRequestType = z.infer<typeof postApiPlatformCodeAdminUserAchievementsRequestSchema>;
export const postApiPlatformCodeAdminUserAchievementsResponseSchema = z.object({ successCount: z.number().int(), failedIds: z.array(z.string()) });
export type postApiPlatformCodeAdminUserAchievementsResponseType = z.infer<typeof postApiPlatformCodeAdminUserAchievementsResponseSchema>;

export const deleteApiPlatformCodeAdminUserAchievementsRequestSchema = z.object({
    body: deleteApiPlatformCodeAdminUserAchievementsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminUserAchievementsRequestType = z.infer<typeof deleteApiPlatformCodeAdminUserAchievementsRequestSchema>;
export const deleteApiPlatformCodeAdminUserAchievementsResponseSchema = z.object({ successCount: z.number().int(), failedIds: z.array(z.string()) });
export type deleteApiPlatformCodeAdminUserAchievementsResponseType = z.infer<typeof deleteApiPlatformCodeAdminUserAchievementsResponseSchema>;

export const postApiPlatformCodeAdminUserAchievementsVketIdRequestSchema = z.object({
    body: postApiPlatformCodeAdminUserAchievementsVketIdBody,
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminUserAchievementsVketIdRequestType = z.infer<typeof postApiPlatformCodeAdminUserAchievementsVketIdRequestSchema>;
export const postApiPlatformCodeAdminUserAchievementsVketIdResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminUserAchievementsVketIdResponseType = z.infer<typeof postApiPlatformCodeAdminUserAchievementsVketIdResponseSchema>;

export const deleteApiPlatformCodeAdminUserAchievementsVketIdRequestSchema = z.object({
    body: deleteApiPlatformCodeAdminUserAchievementsVketIdBody,
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminUserAchievementsVketIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminUserAchievementsVketIdRequestSchema>;
export const deleteApiPlatformCodeAdminUserAchievementsVketIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminUserAchievementsVketIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminUserAchievementsVketIdResponseSchema>;

export const getApiPlatformCodeAdminUserAchievementsVketIdListRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeAdminUserAchievementsVketIdListRequestType = z.infer<typeof getApiPlatformCodeAdminUserAchievementsVketIdListRequestSchema>;
export const getApiPlatformCodeAdminUserAchievementsVketIdListResponseSchema = z.object({ achievementCount: z.number().int(), achievements: z.array(AdminUserAchievement) });
export type getApiPlatformCodeAdminUserAchievementsVketIdListResponseType = z.infer<typeof getApiPlatformCodeAdminUserAchievementsVketIdListResponseSchema>;

export const getApiPlatformCodeAdminUserReportsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    reportReason: z.string().optional(),
    reportStatus: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminUserReportsRequestType = z.infer<typeof getApiPlatformCodeAdminUserReportsRequestSchema>;
export const getApiPlatformCodeAdminUserReportsResponseSchema = z.object({ userReports: z.array(UserReport) });
export type getApiPlatformCodeAdminUserReportsResponseType = z.infer<typeof getApiPlatformCodeAdminUserReportsResponseSchema>;

export const getApiPlatformCodeAdminUserReportsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminUserReportsIdRequestType = z.infer<typeof getApiPlatformCodeAdminUserReportsIdRequestSchema>;
export const getApiPlatformCodeAdminUserReportsIdResponseSchema = z.object({ userReport: UserReport });
export type getApiPlatformCodeAdminUserReportsIdResponseType = z.infer<typeof getApiPlatformCodeAdminUserReportsIdResponseSchema>;

export const patchApiPlatformCodeAdminUserReportsIdRequestSchema = z.object({
    body: z.object({ reportStatus: z.string() }).partial(),
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeAdminUserReportsIdRequestType = z.infer<typeof patchApiPlatformCodeAdminUserReportsIdRequestSchema>;
export const patchApiPlatformCodeAdminUserReportsIdResponseSchema = z.object({ userReport: UserReport });
export type patchApiPlatformCodeAdminUserReportsIdResponseType = z.infer<typeof patchApiPlatformCodeAdminUserReportsIdResponseSchema>;

export const deleteRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteRequestType = z.infer<typeof deleteRequestSchema>;
export const deleteResponseSchema = z.object({ success: Message });
export type deleteResponseType = z.infer<typeof deleteResponseSchema>;

export const getApiPlatformCodeAdminUsersRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    vketId: z.string().optional(),
    name: z.string().optional(),
    sort: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminUsersRequestType = z.infer<typeof getApiPlatformCodeAdminUsersRequestSchema>;
export const getApiPlatformCodeAdminUsersResponseSchema = z.object({ userCount: z.number().int(), users: z.array(AdminUserProfile) });
export type getApiPlatformCodeAdminUsersResponseType = z.infer<typeof getApiPlatformCodeAdminUsersResponseSchema>;

export const getApiPlatformCodeAdminUsersIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminUsersIdRequestType = z.infer<typeof getApiPlatformCodeAdminUsersIdRequestSchema>;
export const getApiPlatformCodeAdminUsersIdResponseSchema = z.object({ user: AdminUserProfileDetail });
export type getApiPlatformCodeAdminUsersIdResponseType = z.infer<typeof getApiPlatformCodeAdminUsersIdResponseSchema>;

export const putApiPlatformCodeAdminUsersIdRequestSchema = z.object({
    body: putApiPlatformCodeAdminUsersIdBody,
    platformCode: z.string(),
    id: z.string(),
})
export type putApiPlatformCodeAdminUsersIdRequestType = z.infer<typeof putApiPlatformCodeAdminUsersIdRequestSchema>;
export const putApiPlatformCodeAdminUsersIdResponseSchema = z.object({ user: AdminUserProfileDetail });
export type putApiPlatformCodeAdminUsersIdResponseType = z.infer<typeof putApiPlatformCodeAdminUsersIdResponseSchema>;

export const postApiPlatformCodeAdminUsersResetRequestSchema = z.object({
    body: z.object({ vketId: z.string() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminUsersResetRequestType = z.infer<typeof postApiPlatformCodeAdminUsersResetRequestSchema>;
export const postApiPlatformCodeAdminUsersResetResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminUsersResetResponseType = z.infer<typeof postApiPlatformCodeAdminUsersResetResponseSchema>;

export const getApiPlatformCodeAdminUsersBansIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeAdminUsersBansIdRequestType = z.infer<typeof getApiPlatformCodeAdminUsersBansIdRequestSchema>;
export const getApiPlatformCodeAdminUsersBansIdResponseSchema = z.object({ statusHistoryCount: z.number().int(), userStatusHistories: z.array(UserStatusHistory) });
export type getApiPlatformCodeAdminUsersBansIdResponseType = z.infer<typeof getApiPlatformCodeAdminUsersBansIdResponseSchema>;

export const getApiPlatformCodeAdminWorldPortalsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    keyword: z.string().optional(),
    worldId: z.string().optional(),
    name: z.string().optional(),
    isOffcial: z.string().optional(),
    sort: z.string().optional(),
    eventUse: z.string().optional(),
})
export type getApiPlatformCodeAdminWorldPortalsRequestType = z.infer<typeof getApiPlatformCodeAdminWorldPortalsRequestSchema>;
export const getApiPlatformCodeAdminWorldPortalsResponseSchema = z.object({ worldPortalCount: z.number().int(), worldPortals: z.array(AdminWorld) });
export type getApiPlatformCodeAdminWorldPortalsResponseType = z.infer<typeof getApiPlatformCodeAdminWorldPortalsResponseSchema>;

export const putApiPlatformCodeAdminWorldPortalsRequestSchema = z.object({
    body: putApiPlatformCodeAdminNewsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminWorldPortalsRequestType = z.infer<typeof putApiPlatformCodeAdminWorldPortalsRequestSchema>;
export const putApiPlatformCodeAdminWorldPortalsResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminWorldPortalsResponseType = z.infer<typeof putApiPlatformCodeAdminWorldPortalsResponseSchema>;

export const postApiPlatformCodeAdminWorldPortalsWorldIdEventUseRequestSchema = z.object({
    body: z.object({ eventUse: z.boolean() }),
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminWorldPortalsWorldIdEventUseRequestType = z.infer<typeof postApiPlatformCodeAdminWorldPortalsWorldIdEventUseRequestSchema>;
export const postApiPlatformCodeAdminWorldPortalsWorldIdEventUseResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminWorldPortalsWorldIdEventUseResponseType = z.infer<typeof postApiPlatformCodeAdminWorldPortalsWorldIdEventUseResponseSchema>;

export const postApiPlatformCodeAdminWorldPortalsOfficialRequestSchema = z.object({
    body: postApiPlatformCodeAdminWorldPortalsOfficialBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeAdminWorldPortalsOfficialRequestType = z.infer<typeof postApiPlatformCodeAdminWorldPortalsOfficialRequestSchema>;
export const postApiPlatformCodeAdminWorldPortalsOfficialResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeAdminWorldPortalsOfficialResponseType = z.infer<typeof postApiPlatformCodeAdminWorldPortalsOfficialResponseSchema>;

export const putApiPlatformCodeAdminWorldPortalsEventUseRequestSchema = z.object({
    body: putApiPlatformCodeAdminWorldPortalsEventUseBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeAdminWorldPortalsEventUseRequestType = z.infer<typeof putApiPlatformCodeAdminWorldPortalsEventUseRequestSchema>;
export const putApiPlatformCodeAdminWorldPortalsEventUseResponseSchema = z.object({ success: Message });
export type putApiPlatformCodeAdminWorldPortalsEventUseResponseType = z.infer<typeof putApiPlatformCodeAdminWorldPortalsEventUseResponseSchema>;

export const getApiPlatformCodeAdminWorldReportsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    reportStatus: z.string().optional(),
    reportCategory: z.string().optional(),
})
export type getApiPlatformCodeAdminWorldReportsRequestType = z.infer<typeof getApiPlatformCodeAdminWorldReportsRequestSchema>;
export const getApiPlatformCodeAdminWorldReportsResponseSchema = z.object({ worldReports: z.array(WorldReport) });
export type getApiPlatformCodeAdminWorldReportsResponseType = z.infer<typeof getApiPlatformCodeAdminWorldReportsResponseSchema>;

export const getApiPlatformCodeAdminWorldReportsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeAdminWorldReportsIdRequestType = z.infer<typeof getApiPlatformCodeAdminWorldReportsIdRequestSchema>;
export const getApiPlatformCodeAdminWorldReportsIdResponseSchema = z.object({ worldReport: WorldReport });
export type getApiPlatformCodeAdminWorldReportsIdResponseType = z.infer<typeof getApiPlatformCodeAdminWorldReportsIdResponseSchema>;

export const patchApiPlatformCodeAdminWorldReportsIdRequestSchema = z.object({
    body: z.object({ reportStatus: z.string() }).partial(),
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeAdminWorldReportsIdRequestType = z.infer<typeof patchApiPlatformCodeAdminWorldReportsIdRequestSchema>;
export const patchApiPlatformCodeAdminWorldReportsIdResponseSchema = z.object({ worldReport: WorldReport });
export type patchApiPlatformCodeAdminWorldReportsIdResponseType = z.infer<typeof patchApiPlatformCodeAdminWorldReportsIdResponseSchema>;

export const deleteApiPlatformCodeAdminWorldReportsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeAdminWorldReportsIdRequestType = z.infer<typeof deleteApiPlatformCodeAdminWorldReportsIdRequestSchema>;
export const deleteApiPlatformCodeAdminWorldReportsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeAdminWorldReportsIdResponseType = z.infer<typeof deleteApiPlatformCodeAdminWorldReportsIdResponseSchema>;

export const postApiPlatformCodeBoothBuilderWorldIdChangeTextureRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeTextureBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeBoothBuilderWorldIdChangeTextureRequestType = z.infer<typeof postApiPlatformCodeBoothBuilderWorldIdChangeTextureRequestSchema>;
export const postApiPlatformCodeBoothBuilderWorldIdChangeTextureResponseSchema = z.object({ jsonUrl: z.string().url() });
export type postApiPlatformCodeBoothBuilderWorldIdChangeTextureResponseType = z.infer<typeof postApiPlatformCodeBoothBuilderWorldIdChangeTextureResponseSchema>;

export const postApiPlatformCodeContactSendRequestSchema = z.object({
    body: postApiPlatformCodeContactSendBody,
    platformCode: z.string(),
})
export type postApiPlatformCodeContactSendRequestType = z.infer<typeof postApiPlatformCodeContactSendRequestSchema>;
export const postApiPlatformCodeContactSendResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeContactSendResponseType = z.infer<typeof postApiPlatformCodeContactSendResponseSchema>;

export const postApiPlatformCodeEventLikesRequestSchema = z.object({
    body: z.object({ eventId: z.number() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeEventLikesRequestType = z.infer<typeof postApiPlatformCodeEventLikesRequestSchema>;
export const postApiPlatformCodeEventLikesResponseSchema = z.object({ event: EventDetail });
export type postApiPlatformCodeEventLikesResponseType = z.infer<typeof postApiPlatformCodeEventLikesResponseSchema>;

export const deleteApiPlatformCodeEventLikesEventIdRequestSchema = z.object({
    platformCode: z.string(),
    eventId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeEventLikesEventIdRequestType = z.infer<typeof deleteApiPlatformCodeEventLikesEventIdRequestSchema>;
export const deleteApiPlatformCodeEventLikesEventIdResponseSchema = z.object({ event: EventDetail });
export type deleteApiPlatformCodeEventLikesEventIdResponseType = z.infer<typeof deleteApiPlatformCodeEventLikesEventIdResponseSchema>;

export const postApiPlatformCodeEventsRequestSchema = z.object({
    body: postApiPlatformCodeEventsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeEventsRequestType = z.infer<typeof postApiPlatformCodeEventsRequestSchema>;
export const postApiPlatformCodeEventsResponseSchema = z.object({ event: EventDetail });
export type postApiPlatformCodeEventsResponseType = z.infer<typeof postApiPlatformCodeEventsResponseSchema>;

export const getApiPlatformCodeEventsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    targetMonth: z.string().optional(),
    filter: z.string().optional(),
    startFrom: z.string().optional(),
    startTo: z.string().optional(),
    vketId: z.string().optional(),
    keyword: z.string().optional(),
    isPaid: z.string().optional(),
    sort: z.string().optional(),
    finished: z.string().optional(),
    liked: z.string().optional(),
    endFrom: z.string().optional(),
    endTo: z.string().optional(),
    onlyFinished: z.string().optional(),
    opened: z.string().optional(),
})
export type getApiPlatformCodeEventsRequestType = z.infer<typeof getApiPlatformCodeEventsRequestSchema>;
export const getApiPlatformCodeEventsResponseSchema = z.object({ eventCount: z.number(), events: z.array(Event) });
export type getApiPlatformCodeEventsResponseType = z.infer<typeof getApiPlatformCodeEventsResponseSchema>;

export const getApiPlatformCodeEventsEventIdRequestSchema = z.object({
    platformCode: z.string(),
    eventId: z.number(),
    Authorization: z.string(),
})
export type getApiPlatformCodeEventsEventIdRequestType = z.infer<typeof getApiPlatformCodeEventsEventIdRequestSchema>;
export const getApiPlatformCodeEventsEventIdResponseSchema = z.object({ event: EventDetail });
export type getApiPlatformCodeEventsEventIdResponseType = z.infer<typeof getApiPlatformCodeEventsEventIdResponseSchema>;

export const putApiPlatformCodeEventsEventIdRequestSchema = z.object({
    body: putApiPlatformCodeEventsEventIdBody,
    platformCode: z.string(),
    eventId: z.number(),
    Authorization: z.string(),
})
export type putApiPlatformCodeEventsEventIdRequestType = z.infer<typeof putApiPlatformCodeEventsEventIdRequestSchema>;
export const putApiPlatformCodeEventsEventIdResponseSchema = z.object({ event: EventDetail });
export type putApiPlatformCodeEventsEventIdResponseType = z.infer<typeof putApiPlatformCodeEventsEventIdResponseSchema>;

export const patchApiPlatformCodeEventsEventIdRequestSchema = z.object({
    body: putApiPlatformCodeEventsEventIdBody,
    platformCode: z.string(),
    eventId: z.number(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeEventsEventIdRequestType = z.infer<typeof patchApiPlatformCodeEventsEventIdRequestSchema>;
export const patchApiPlatformCodeEventsEventIdResponseSchema = z.object({ event: EventDetail });
export type patchApiPlatformCodeEventsEventIdResponseType = z.infer<typeof patchApiPlatformCodeEventsEventIdResponseSchema>;

export const deleteApiPlatformCodeEventsEventIdRequestSchema = z.object({
    platformCode: z.string(),
    eventId: z.number(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeEventsEventIdRequestType = z.infer<typeof deleteApiPlatformCodeEventsEventIdRequestSchema>;
export const deleteApiPlatformCodeEventsEventIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeEventsEventIdResponseType = z.infer<typeof deleteApiPlatformCodeEventsEventIdResponseSchema>;

export const postApiPlatformCodeEventsEventIdParticipantsRequestSchema = z.object({
    platformCode: z.string(),
    eventId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeEventsEventIdParticipantsRequestType = z.infer<typeof postApiPlatformCodeEventsEventIdParticipantsRequestSchema>;
export const postApiPlatformCodeEventsEventIdParticipantsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeEventsEventIdParticipantsResponseType = z.infer<typeof postApiPlatformCodeEventsEventIdParticipantsResponseSchema>;

export const getApiPlatformCodeEventsEventIdParticipantsRequestSchema = z.object({
    platformCode: z.string(),
    eventId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeEventsEventIdParticipantsRequestType = z.infer<typeof getApiPlatformCodeEventsEventIdParticipantsRequestSchema>;
export const getApiPlatformCodeEventsEventIdParticipantsResponseSchema = participants;
export type getApiPlatformCodeEventsEventIdParticipantsResponseType = z.infer<typeof getApiPlatformCodeEventsEventIdParticipantsResponseSchema>;

export const deleteApiPlatformCodeEventsEventIdParticipantsRequestSchema = z.object({
    platformCode: z.string(),
    eventId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeEventsEventIdParticipantsRequestType = z.infer<typeof deleteApiPlatformCodeEventsEventIdParticipantsRequestSchema>;
export const deleteApiPlatformCodeEventsEventIdParticipantsResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeEventsEventIdParticipantsResponseType = z.infer<typeof deleteApiPlatformCodeEventsEventIdParticipantsResponseSchema>;

export const getApiPlatformCodeGeneralWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeGeneralWorldsRequestType = z.infer<typeof getApiPlatformCodeGeneralWorldsRequestSchema>;
export const getApiPlatformCodeGeneralWorldsResponseSchema = z.object({ generalWorlds: z.array(GeneralWorld) }).partial();
export type getApiPlatformCodeGeneralWorldsResponseType = z.infer<typeof getApiPlatformCodeGeneralWorldsResponseSchema>;

export const getApiPlatformCodeGeneralWorldsWorldSetIdRequestSchema = z.object({
    platformCode: z.string(),
    worldSetId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeGeneralWorldsWorldSetIdRequestType = z.infer<typeof getApiPlatformCodeGeneralWorldsWorldSetIdRequestSchema>;
export const getApiPlatformCodeGeneralWorldsWorldSetIdResponseSchema = z.object({ generalWorlds: GeneralWorld }).partial();
export type getApiPlatformCodeGeneralWorldsWorldSetIdResponseType = z.infer<typeof getApiPlatformCodeGeneralWorldsWorldSetIdResponseSchema>;

export const getApiPlatformCodeHelpsRequestSchema = z.object({
    platformCode: z.string(),
})
export type getApiPlatformCodeHelpsRequestType = z.infer<typeof getApiPlatformCodeHelpsRequestSchema>;
export const getApiPlatformCodeHelpsResponseSchema = z.object({ help: Help });
export type getApiPlatformCodeHelpsResponseType = z.infer<typeof getApiPlatformCodeHelpsResponseSchema>;

export const getApiPlatformCodeHousingMusicsRequestSchema = z.object({
    body: getApiPlatformCodeHousingMusicsBody,
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeHousingMusicsRequestType = z.infer<typeof getApiPlatformCodeHousingMusicsRequestSchema>;
export const getApiPlatformCodeHousingMusicsResponseSchema = z.object({ housingMusics: z.array(HousingMusic), housingMusicCount: z.number().int() });
export type getApiPlatformCodeHousingMusicsResponseType = z.infer<typeof getApiPlatformCodeHousingMusicsResponseSchema>;

export const postApiPlatformCodeHousingMusicsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingMusicsRequestType = z.infer<typeof postApiPlatformCodeHousingMusicsRequestSchema>;
export const postApiPlatformCodeHousingMusicsResponseSchema = z.object({ housingMusic: HousingMusic });
export type postApiPlatformCodeHousingMusicsResponseType = z.infer<typeof postApiPlatformCodeHousingMusicsResponseSchema>;

export const deleteApiPlatformCodeHousingMusicsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeHousingMusicsIdRequestType = z.infer<typeof deleteApiPlatformCodeHousingMusicsIdRequestSchema>;
export const deleteApiPlatformCodeHousingMusicsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeHousingMusicsIdResponseType = z.infer<typeof deleteApiPlatformCodeHousingMusicsIdResponseSchema>;

export const getApiPlatformCodeHousingMusicsSearchByUuidUuidRequestSchema = z.object({
    platformCode: z.string(),
    uuid: z.string(),
})
export type getApiPlatformCodeHousingMusicsSearchByUuidUuidRequestType = z.infer<typeof getApiPlatformCodeHousingMusicsSearchByUuidUuidRequestSchema>;
export const getApiPlatformCodeHousingMusicsSearchByUuidUuidResponseSchema = z.object({ housingMusic: HousingMusic });
export type getApiPlatformCodeHousingMusicsSearchByUuidUuidResponseType = z.infer<typeof getApiPlatformCodeHousingMusicsSearchByUuidUuidResponseSchema>;

export const getApiPlatformCodeHousingScenesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingScenesRequestType = z.infer<typeof getApiPlatformCodeHousingScenesRequestSchema>;
export const getApiPlatformCodeHousingScenesResponseSchema = z.object({ housingScenes: z.array(HousingScene) });
export type getApiPlatformCodeHousingScenesResponseType = z.infer<typeof getApiPlatformCodeHousingScenesResponseSchema>;

export const postApiPlatformCodeHousingScenesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingScenesRequestType = z.infer<typeof postApiPlatformCodeHousingScenesRequestSchema>;
export const postApiPlatformCodeHousingScenesResponseSchema = z.object({ housingScene: HousingScene });
export type postApiPlatformCodeHousingScenesResponseType = z.infer<typeof postApiPlatformCodeHousingScenesResponseSchema>;

export const getApiPlatformCodeHousingScenesWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingScenesWorldIdRequestType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdRequestSchema>;
export const getApiPlatformCodeHousingScenesWorldIdResponseSchema = z.object({ housingScene: HousingSceneDetail });
export type getApiPlatformCodeHousingScenesWorldIdResponseType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdResponseSchema>;

export const patchApiPlatformCodeHousingScenesWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeHousingScenesWorldIdRequestType = z.infer<typeof patchApiPlatformCodeHousingScenesWorldIdRequestSchema>;
export const patchApiPlatformCodeHousingScenesWorldIdResponseSchema = z.object({ housingScene: HousingScene });
export type patchApiPlatformCodeHousingScenesWorldIdResponseType = z.infer<typeof patchApiPlatformCodeHousingScenesWorldIdResponseSchema>;

export const postApiPlatformCodeHousingScenesWorldIdChangeLinkRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeLinkBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingScenesWorldIdChangeLinkRequestType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeLinkRequestSchema>;
export const postApiPlatformCodeHousingScenesWorldIdChangeLinkResponseSchema = z.void();
export type postApiPlatformCodeHousingScenesWorldIdChangeLinkResponseType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeLinkResponseSchema>;

export const postApiPlatformCodeHousingScenesWorldIdChangeNftTextureRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingScenesWorldIdChangeNftTextureRequestType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeNftTextureRequestSchema>;
export const postApiPlatformCodeHousingScenesWorldIdChangeNftTextureResponseSchema = z.void();
export type postApiPlatformCodeHousingScenesWorldIdChangeNftTextureResponseType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeNftTextureResponseSchema>;

export const postApiPlatformCodeHousingScenesWorldIdChangeTextureRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeTextureBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingScenesWorldIdChangeTextureRequestType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeTextureRequestSchema>;
export const postApiPlatformCodeHousingScenesWorldIdChangeTextureResponseSchema = z.void();
export type postApiPlatformCodeHousingScenesWorldIdChangeTextureResponseType = z.infer<typeof postApiPlatformCodeHousingScenesWorldIdChangeTextureResponseSchema>;

export const getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeLinksRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeLinksRequestType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeLinksRequestSchema>;
export const getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeLinksResponseSchema = z.object({ presetFurnitureChangeLinks: z.array(PresetFurnitureChangeLink) });
export type getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeLinksResponseType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeLinksResponseSchema>;

export const getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeNftTexturesRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    item: z.string().optional(),
})
export type getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeNftTexturesRequestType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeNftTexturesRequestSchema>;
export const getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeNftTexturesResponseSchema = z.object({ presetFurnitureChangeNftTextures: z.array(PresetFurnitureChangeNftTexture) });
export type getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeNftTexturesResponseType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeNftTexturesResponseSchema>;

export const getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeTexturesRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    item: z.string().optional(),
})
export type getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeTexturesRequestType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeTexturesRequestSchema>;
export const getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeTexturesResponseSchema = z.object({ presetFurnitureChangeTextures: z.array(PresetFurnitureChangeTexture) });
export type getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeTexturesResponseType = z.infer<typeof getApiPlatformCodeHousingScenesWorldIdPresetFurnitureChangeTexturesResponseSchema>;

export const getApiPlatformCodeHousingScenesPublishedRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    userId: z.string(),
})
export type getApiPlatformCodeHousingScenesPublishedRequestType = z.infer<typeof getApiPlatformCodeHousingScenesPublishedRequestSchema>;
export const getApiPlatformCodeHousingScenesPublishedResponseSchema = z.object({ housingScenes: z.array(HousingScene) });
export type getApiPlatformCodeHousingScenesPublishedResponseType = z.infer<typeof getApiPlatformCodeHousingScenesPublishedResponseSchema>;

export const getApiPlatformCodeHousingScenesPublishedWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingScenesPublishedWorldIdRequestType = z.infer<typeof getApiPlatformCodeHousingScenesPublishedWorldIdRequestSchema>;
export const getApiPlatformCodeHousingScenesPublishedWorldIdResponseSchema = z.object({ housingScene: HousingSceneDetail });
export type getApiPlatformCodeHousingScenesPublishedWorldIdResponseType = z.infer<typeof getApiPlatformCodeHousingScenesPublishedWorldIdResponseSchema>;

export const getApiPlatformCodeHousingTemporaryScenesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingTemporaryScenesRequestType = z.infer<typeof getApiPlatformCodeHousingTemporaryScenesRequestSchema>;
export const getApiPlatformCodeHousingTemporaryScenesResponseSchema = z.object({ housingTemporaryScenes: z.array(HousingTemporaryScene) });
export type getApiPlatformCodeHousingTemporaryScenesResponseType = z.infer<typeof getApiPlatformCodeHousingTemporaryScenesResponseSchema>;

export const postApiPlatformCodeHousingTemporaryScenesRequestSchema = z.object({
    body: postApiPlatformCodeHousingTemporaryScenesBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingTemporaryScenesRequestType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesRequestSchema>;
export const postApiPlatformCodeHousingTemporaryScenesResponseSchema = z.object({ housingTemporaryScene: HousingTemporaryScene });
export type postApiPlatformCodeHousingTemporaryScenesResponseType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesResponseSchema>;

export const getApiPlatformCodeHousingTemporaryScenesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingTemporaryScenesIdRequestType = z.infer<typeof getApiPlatformCodeHousingTemporaryScenesIdRequestSchema>;
export const getApiPlatformCodeHousingTemporaryScenesIdResponseSchema = z.object({ housingTemporaryScene: HousingTemporaryScene });
export type getApiPlatformCodeHousingTemporaryScenesIdResponseType = z.infer<typeof getApiPlatformCodeHousingTemporaryScenesIdResponseSchema>;

export const patchApiPlatformCodeHousingTemporaryScenesIdRequestSchema = z.object({
    body: postApiPlatformCodeHousingTemporaryScenesBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeHousingTemporaryScenesIdRequestType = z.infer<typeof patchApiPlatformCodeHousingTemporaryScenesIdRequestSchema>;
export const patchApiPlatformCodeHousingTemporaryScenesIdResponseSchema = z.object({ housingTemporaryScene: HousingTemporaryScene });
export type patchApiPlatformCodeHousingTemporaryScenesIdResponseType = z.infer<typeof patchApiPlatformCodeHousingTemporaryScenesIdResponseSchema>;

export const deleteApiPlatformCodeHousingTemporaryScenesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeHousingTemporaryScenesIdRequestType = z.infer<typeof deleteApiPlatformCodeHousingTemporaryScenesIdRequestSchema>;
export const deleteApiPlatformCodeHousingTemporaryScenesIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeHousingTemporaryScenesIdResponseType = z.infer<typeof deleteApiPlatformCodeHousingTemporaryScenesIdResponseSchema>;

export const postApiPlatformCodeHousingTemporaryScenesIdChangeLinkRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeLinkBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingTemporaryScenesIdChangeLinkRequestType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesIdChangeLinkRequestSchema>;
export const postApiPlatformCodeHousingTemporaryScenesIdChangeLinkResponseSchema = z.void();
export type postApiPlatformCodeHousingTemporaryScenesIdChangeLinkResponseType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesIdChangeLinkResponseSchema>;

export const postApiPlatformCodeHousingTemporaryScenesIdChangeNftTextureRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeNftTextureBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingTemporaryScenesIdChangeNftTextureRequestType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesIdChangeNftTextureRequestSchema>;
export const postApiPlatformCodeHousingTemporaryScenesIdChangeNftTextureResponseSchema = z.void();
export type postApiPlatformCodeHousingTemporaryScenesIdChangeNftTextureResponseType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesIdChangeNftTextureResponseSchema>;

export const postApiPlatformCodeHousingTemporaryScenesIdChangeTextureRequestSchema = z.object({
    body: postApiPlatformCodeHousingScenesWorldIdChangeTextureBody,
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingTemporaryScenesIdChangeTextureRequestType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesIdChangeTextureRequestSchema>;
export const postApiPlatformCodeHousingTemporaryScenesIdChangeTextureResponseSchema = z.void();
export type postApiPlatformCodeHousingTemporaryScenesIdChangeTextureResponseType = z.infer<typeof postApiPlatformCodeHousingTemporaryScenesIdChangeTextureResponseSchema>;

export const getApiPlatformCodeHousingTextureImagesRequestSchema = z.object({
    body: getApiPlatformCodeHousingTextureImagesBody,
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeHousingTextureImagesRequestType = z.infer<typeof getApiPlatformCodeHousingTextureImagesRequestSchema>;
export const getApiPlatformCodeHousingTextureImagesResponseSchema = z.object({ housingTextureImages: z.array(HousingTextureImage), housingTextureImageCount: z.number().int() });
export type getApiPlatformCodeHousingTextureImagesResponseType = z.infer<typeof getApiPlatformCodeHousingTextureImagesResponseSchema>;

export const postApiPlatformCodeHousingTextureImagesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeHousingTextureImagesRequestType = z.infer<typeof postApiPlatformCodeHousingTextureImagesRequestSchema>;
export const postApiPlatformCodeHousingTextureImagesResponseSchema = z.object({ housingTextureImage: HousingTextureImage });
export type postApiPlatformCodeHousingTextureImagesResponseType = z.infer<typeof postApiPlatformCodeHousingTextureImagesResponseSchema>;

export const deleteApiPlatformCodeHousingTextureImagesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeHousingTextureImagesIdRequestType = z.infer<typeof deleteApiPlatformCodeHousingTextureImagesIdRequestSchema>;
export const deleteApiPlatformCodeHousingTextureImagesIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeHousingTextureImagesIdResponseType = z.infer<typeof deleteApiPlatformCodeHousingTextureImagesIdResponseSchema>;

export const getApiPlatformCodeHousingWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    filter: z.string().optional(),
    search: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeHousingWorldsRequestType = z.infer<typeof getApiPlatformCodeHousingWorldsRequestSchema>;
export const getApiPlatformCodeHousingWorldsResponseSchema = z.object({ housingWorlds: z.array(HousingWorld) });
export type getApiPlatformCodeHousingWorldsResponseType = z.infer<typeof getApiPlatformCodeHousingWorldsResponseSchema>;

export const getApiPlatformCodeHousingWorldsWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeHousingWorldsWorldIdRequestType = z.infer<typeof getApiPlatformCodeHousingWorldsWorldIdRequestSchema>;
export const getApiPlatformCodeHousingWorldsWorldIdResponseSchema = z.object({ housingWorld: HousingWorld });
export type getApiPlatformCodeHousingWorldsWorldIdResponseType = z.infer<typeof getApiPlatformCodeHousingWorldsWorldIdResponseSchema>;

export const patchApiPlatformCodeHousingWorldsWorldIdRequestSchema = z.object({
    body: patchApiPlatformCodeHousingWorldsWorldIdBody,
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeHousingWorldsWorldIdRequestType = z.infer<typeof patchApiPlatformCodeHousingWorldsWorldIdRequestSchema>;
export const patchApiPlatformCodeHousingWorldsWorldIdResponseSchema = z.object({ housingWorld: HousingWorld });
export type patchApiPlatformCodeHousingWorldsWorldIdResponseType = z.infer<typeof patchApiPlatformCodeHousingWorldsWorldIdResponseSchema>;

export const deleteApiPlatformCodeHousingWorldsWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeHousingWorldsWorldIdRequestType = z.infer<typeof deleteApiPlatformCodeHousingWorldsWorldIdRequestSchema>;
export const deleteApiPlatformCodeHousingWorldsWorldIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeHousingWorldsWorldIdResponseType = z.infer<typeof deleteApiPlatformCodeHousingWorldsWorldIdResponseSchema>;

export const postApiPlatformCodeHousingWorldsWorldIdPasswordAuthenticationRequestSchema = z.object({
    body: z.object({ passward: z.string() }),
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string().optional(),
})
export type postApiPlatformCodeHousingWorldsWorldIdPasswordAuthenticationRequestType = z.infer<typeof postApiPlatformCodeHousingWorldsWorldIdPasswordAuthenticationRequestSchema>;
export const postApiPlatformCodeHousingWorldsWorldIdPasswordAuthenticationResponseSchema = z.object({ result: z.boolean() });
export type postApiPlatformCodeHousingWorldsWorldIdPasswordAuthenticationResponseType = z.infer<typeof postApiPlatformCodeHousingWorldsWorldIdPasswordAuthenticationResponseSchema>;

export const postApiPlatformCodeHousingWorldsListRequestSchema = z.object({
    body: postApiPlatformCodeHousingWorldsListBody,
    platformCode: z.string(),
})
export type postApiPlatformCodeHousingWorldsListRequestType = z.infer<typeof postApiPlatformCodeHousingWorldsListRequestSchema>;
export const postApiPlatformCodeHousingWorldsListResponseSchema = z.object({ housingWorlds: z.array(HousingWorld) });
export type postApiPlatformCodeHousingWorldsListResponseType = z.infer<typeof postApiPlatformCodeHousingWorldsListResponseSchema>;

export const getApiPlatformCodeIngameMessagesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    worldId: z.string().optional(),
    positionChannelId: z.string().optional(),
})
export type getApiPlatformCodeIngameMessagesRequestType = z.infer<typeof getApiPlatformCodeIngameMessagesRequestSchema>;
export const getApiPlatformCodeIngameMessagesResponseSchema = z.object({ ingameMessages: z.array(IngameMessage) });
export type getApiPlatformCodeIngameMessagesResponseType = z.infer<typeof getApiPlatformCodeIngameMessagesResponseSchema>;

export const getApiPlatformCodeNewsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    category: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeNewsRequestType = z.infer<typeof getApiPlatformCodeNewsRequestSchema>;
export const getApiPlatformCodeNewsResponseSchema = z.object({ newsCount: z.number().int(), news: z.array(News) });
export type getApiPlatformCodeNewsResponseType = z.infer<typeof getApiPlatformCodeNewsResponseSchema>;

export const getApiPlatformCodeNewsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeNewsIdRequestType = z.infer<typeof getApiPlatformCodeNewsIdRequestSchema>;
export const getApiPlatformCodeNewsIdResponseSchema = z.object({ news: News });
export type getApiPlatformCodeNewsIdResponseType = z.infer<typeof getApiPlatformCodeNewsIdResponseSchema>;

export const getApiPlatformCodeNotificationsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    subjectType: z.string().optional(),
    unread: z.string().optional(),
    vketId: z.string().optional(),
    roomId: z.number().int().optional(),
    messageId: z.number().int().optional(),
    worldSetId: z.number().int().optional(),
    avatarId: z.number().int().optional(),
})
export type getApiPlatformCodeNotificationsRequestType = z.infer<typeof getApiPlatformCodeNotificationsRequestSchema>;
export const getApiPlatformCodeNotificationsResponseSchema = z.object({ notificationCount: z.number().int(), notifications: z.array(z.object({ id: z.number().int(), subjectId: z.number().int(), userId: z.number().int(), isRead: z.boolean(), createdAt: z.string(), updatedAt: z.string(), followUser: z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string() }), topPageAvatarId: z.number().int() }).nullish(), worldLike: z.object({ id: z.number().int(), userId: z.number().int(), worldSetId: z.number().int(), name: z.string(), worldId: z.string(), createdAt: z.string(), updatedAt: z.string(), user: UserObject }).nullish(), directMessage: z.object({ id: z.number().int(), userId: z.number().int(), roomId: z.number().int(), message: z.string(), name: z.string(), createdAt: z.string(), updatedAt: z.string(), user: UserObject }).nullish(), event: z.object({ id: z.number().int(), userId: z.number().int(), name: z.string(), description: z.string(), thumbnail: UrlObject, worldName: z.string().nullable(), worldUrl: z.string(), startAt: z.string(), endAt: z.string(), user: UserObject }).nullish(), housingScene: z.object({ id: z.number().int(), userId: z.number().int(), worldId: z.string(), name: z.string(), introduction: z.string(), thumbnail: UrlObject, user: UserObject }).nullish(), eventLike: z.object({ id: z.number().int(), userId: z.number().int(), eventId: z.number().int(), user: UserObject }).nullish(), pushNotification: z.object({ id: z.number().int(), title: z.string(), body: z.string(), link: z.string() }).nullish() })) });
export type getApiPlatformCodeNotificationsResponseType = z.infer<typeof getApiPlatformCodeNotificationsResponseSchema>;

export const patchApiPlatformCodeNotificationsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeNotificationsIdRequestType = z.infer<typeof patchApiPlatformCodeNotificationsIdRequestSchema>;
export const patchApiPlatformCodeNotificationsIdResponseSchema = z.object({ success: Message });
export type patchApiPlatformCodeNotificationsIdResponseType = z.infer<typeof patchApiPlatformCodeNotificationsIdResponseSchema>;

export const patchApiPlatformCodeNotificationsReadRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeNotificationsReadRequestType = z.infer<typeof patchApiPlatformCodeNotificationsReadRequestSchema>;
export const patchApiPlatformCodeNotificationsReadResponseSchema = z.object({ success: Message });
export type patchApiPlatformCodeNotificationsReadResponseType = z.infer<typeof patchApiPlatformCodeNotificationsReadResponseSchema>;

export const getApiPlatformCodeNotificationsSummaryRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeNotificationsSummaryRequestType = z.infer<typeof getApiPlatformCodeNotificationsSummaryRequestSchema>;
export const getApiPlatformCodeNotificationsSummaryResponseSchema = z.object({ notification: z.object({ followUnreadCount: z.number().int(), likeUnreadCount: z.number().int(), messageUnreadCount: z.number().int(), eventUnreadCount: z.number().int(), roomUnreadCount: z.number().int(), eventLikeUnreadCount: z.number().int(), followCount: z.number().int(), likeCount: z.number().int(), messageCount: z.number().int(), eventCount: z.number().int(), roomCount: z.number().int(), eventLikeCount: z.number().int() }) });
export type getApiPlatformCodeNotificationsSummaryResponseType = z.infer<typeof getApiPlatformCodeNotificationsSummaryResponseSchema>;

export const getApiPlatformCodePresentsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    isOpened: z.string().optional(),
})
export type getApiPlatformCodePresentsRequestType = z.infer<typeof getApiPlatformCodePresentsRequestSchema>;
export const getApiPlatformCodePresentsResponseSchema = z.object({ presentCount: z.number().int(), presents: z.array(Present).optional() });
export type getApiPlatformCodePresentsResponseType = z.infer<typeof getApiPlatformCodePresentsResponseSchema>;

export const patchApiPlatformCodePresentsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodePresentsIdRequestType = z.infer<typeof patchApiPlatformCodePresentsIdRequestSchema>;
export const patchApiPlatformCodePresentsIdResponseSchema = z.object({ success: Message });
export type patchApiPlatformCodePresentsIdResponseType = z.infer<typeof patchApiPlatformCodePresentsIdResponseSchema>;

export const getApiPlatformCodePresetFurnitureTextureMaterialsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodePresetFurnitureTextureMaterialsIdRequestType = z.infer<typeof getApiPlatformCodePresetFurnitureTextureMaterialsIdRequestSchema>;
export const getApiPlatformCodePresetFurnitureTextureMaterialsIdResponseSchema = z.object({ thumbnailUrl: z.string().url(), sampleUrl: z.string().url(), saveAs: z.string() });
export type getApiPlatformCodePresetFurnitureTextureMaterialsIdResponseType = z.infer<typeof getApiPlatformCodePresetFurnitureTextureMaterialsIdResponseSchema>;

export const getApiPlatformCodePresetFurnituresRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    type: z.enum(["floor", "ceiling", "wall"]).optional(),
    objectType: z.enum(["object", "activity"]).optional(),
    useCategory: z.enum(["other", "shelf", "bed", "chair", "table", "rug", "generalGoods", "illumination", "wallDecoration"]).optional(),
    functionFilter: z.enum(["all", "texture", "music", "placeOnTop", "screenShare", "link"]).optional(),
})
export type getApiPlatformCodePresetFurnituresRequestType = z.infer<typeof getApiPlatformCodePresetFurnituresRequestSchema>;
export const getApiPlatformCodePresetFurnituresResponseSchema = z.object({ presetFurnitureCount: z.number().int(), presetFurnitures: z.array(PresetFurnitureWithTextureMaterial) });
export type getApiPlatformCodePresetFurnituresResponseType = z.infer<typeof getApiPlatformCodePresetFurnituresResponseSchema>;

export const postApiPlatformCodePresetFurnituresCheckUuidCategoryRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodePresetFurnituresCheckUuidCategoryRequestType = z.infer<typeof postApiPlatformCodePresetFurnituresCheckUuidCategoryRequestSchema>;
export const postApiPlatformCodePresetFurnituresCheckUuidCategoryResponseSchema = z.object({ furnitureCategories: z.array(z.object({ uuid: z.string().uuid(), furnitureCategory: z.union([z.literal(0), z.literal(1)]) })) });
export type postApiPlatformCodePresetFurnituresCheckUuidCategoryResponseType = z.infer<typeof postApiPlatformCodePresetFurnituresCheckUuidCategoryResponseSchema>;

export const getApiPlatformCodePresetHousingHomesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    tutorialUse: z.string().optional(),
})
export type getApiPlatformCodePresetHousingHomesRequestType = z.infer<typeof getApiPlatformCodePresetHousingHomesRequestSchema>;
export const getApiPlatformCodePresetHousingHomesResponseSchema = z.object({ presetHousingHomes: z.array(PresetHousingHome) });
export type getApiPlatformCodePresetHousingHomesResponseType = z.infer<typeof getApiPlatformCodePresetHousingHomesResponseSchema>;

export const getApiPlatformCodePresetHousingHomesIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodePresetHousingHomesIdRequestType = z.infer<typeof getApiPlatformCodePresetHousingHomesIdRequestSchema>;
export const getApiPlatformCodePresetHousingHomesIdResponseSchema = z.object({ presetHousingHome: PresetHousingHome });
export type getApiPlatformCodePresetHousingHomesIdResponseType = z.infer<typeof getApiPlatformCodePresetHousingHomesIdResponseSchema>;

export const getApiPlatformCodePresetHousingScenesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodePresetHousingScenesRequestType = z.infer<typeof getApiPlatformCodePresetHousingScenesRequestSchema>;
export const getApiPlatformCodePresetHousingScenesResponseSchema = z.object({ presetHousingScenes: z.array(PresetHousingScene) });
export type getApiPlatformCodePresetHousingScenesResponseType = z.infer<typeof getApiPlatformCodePresetHousingScenesResponseSchema>;

export const getApiPlatformCodeRecommendedHousingWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string().optional(),
})
export type getApiPlatformCodeRecommendedHousingWorldsRequestType = z.infer<typeof getApiPlatformCodeRecommendedHousingWorldsRequestSchema>;
export const getApiPlatformCodeRecommendedHousingWorldsResponseSchema = z.object({ housingWorlds: z.array(HousingWorld) });
export type getApiPlatformCodeRecommendedHousingWorldsResponseType = z.infer<typeof getApiPlatformCodeRecommendedHousingWorldsResponseSchema>;

export const getApiPlatformCodeRecommendedWorldsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string().optional(),
})
export type getApiPlatformCodeRecommendedWorldsRequestType = z.infer<typeof getApiPlatformCodeRecommendedWorldsRequestSchema>;
export const getApiPlatformCodeRecommendedWorldsResponseSchema = z.object({ worldPortals: z.array(WorldPortal) });
export type getApiPlatformCodeRecommendedWorldsResponseType = z.infer<typeof getApiPlatformCodeRecommendedWorldsResponseSchema>;

export const postApiPlatformCodeReportsRequestSchema = z.object({
    body: postApiPlatformCodeReportsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeReportsRequestType = z.infer<typeof postApiPlatformCodeReportsRequestSchema>;
export const postApiPlatformCodeReportsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeReportsResponseType = z.infer<typeof postApiPlatformCodeReportsResponseSchema>;

export const postApiPlatformCodeUserReportsRequestSchema = z.object({
    body: postApiPlatformCodeUserReportsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserReportsRequestType = z.infer<typeof postApiPlatformCodeUserReportsRequestSchema>;
export const postApiPlatformCodeUserReportsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeUserReportsResponseType = z.infer<typeof postApiPlatformCodeUserReportsResponseSchema>;

export const getApiPlatformCodeUserAchievementsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    type: z.string().optional(),
    eventId: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeUserAchievementsRequestType = z.infer<typeof getApiPlatformCodeUserAchievementsRequestSchema>;
export const getApiPlatformCodeUserAchievementsResponseSchema = z.object({ achievements: z.array(UserAchievement), achievementCount: z.number().int(), totalAchievementCount: z.number().int() });
export type getApiPlatformCodeUserAchievementsResponseType = z.infer<typeof getApiPlatformCodeUserAchievementsResponseSchema>;

export const getApiPlatformCodeUserAchievementsLabelRequestSchema = z.object({
    platformCode: z.string(),
    label: z.string(),
    Authorization: z.string(),
    accomplishedUsersLimit: z.number().int().optional(),
    accomplishedUsersOffset: z.number().int().optional(),
})
export type getApiPlatformCodeUserAchievementsLabelRequestType = z.infer<typeof getApiPlatformCodeUserAchievementsLabelRequestSchema>;
export const getApiPlatformCodeUserAchievementsLabelResponseSchema = z.object({ achievement: AchievementDetail });
export type getApiPlatformCodeUserAchievementsLabelResponseType = z.infer<typeof getApiPlatformCodeUserAchievementsLabelResponseSchema>;

export const patchApiPlatformCodeUserAchievementsLabelRequestSchema = z.object({
    body: z.object({ showProfile: z.boolean() }).partial(),
    platformCode: z.string(),
    label: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeUserAchievementsLabelRequestType = z.infer<typeof patchApiPlatformCodeUserAchievementsLabelRequestSchema>;
export const patchApiPlatformCodeUserAchievementsLabelResponseSchema = z.object({ success: Message });
export type patchApiPlatformCodeUserAchievementsLabelResponseType = z.infer<typeof patchApiPlatformCodeUserAchievementsLabelResponseSchema>;

export const getApiPlatformCodeUserAchievementsVketIdListRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserAchievementsVketIdListRequestType = z.infer<typeof getApiPlatformCodeUserAchievementsVketIdListRequestSchema>;
export const getApiPlatformCodeUserAchievementsVketIdListResponseSchema = z.object({ achievements: z.array(UserAchievement), achievementCount: z.number().int(), totalAchievementCount: z.number().int() });
export type getApiPlatformCodeUserAchievementsVketIdListResponseType = z.infer<typeof getApiPlatformCodeUserAchievementsVketIdListResponseSchema>;

export const getApiPlatformCodeUserAchievementsVketIdDetailLabelRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    label: z.string(),
    Authorization: z.string(),
    accomplishedUsersLimit: z.number().int().optional(),
    accomplishedUsersOffset: z.number().int().optional(),
})
export type getApiPlatformCodeUserAchievementsVketIdDetailLabelRequestType = z.infer<typeof getApiPlatformCodeUserAchievementsVketIdDetailLabelRequestSchema>;
export const getApiPlatformCodeUserAchievementsVketIdDetailLabelResponseSchema = z.object({ achievement: AchievementDetail });
export type getApiPlatformCodeUserAchievementsVketIdDetailLabelResponseType = z.infer<typeof getApiPlatformCodeUserAchievementsVketIdDetailLabelResponseSchema>;

export const getApiPlatformCodeUserAchievementsContentsListRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    keyword: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeUserAchievementsContentsListRequestType = z.infer<typeof getApiPlatformCodeUserAchievementsContentsListRequestSchema>;
export const getApiPlatformCodeUserAchievementsContentsListResponseSchema = z.object({ contentCount: z.number().int(), contents: z.array(AchievementContents) });
export type getApiPlatformCodeUserAchievementsContentsListResponseType = z.infer<typeof getApiPlatformCodeUserAchievementsContentsListResponseSchema>;

export const getApiPlatformCodeUserAchievementsProfileRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserAchievementsProfileRequestType = z.infer<typeof getApiPlatformCodeUserAchievementsProfileRequestSchema>;
export const getApiPlatformCodeUserAchievementsProfileResponseSchema = z.object({ achievement: UserAchievement });
export type getApiPlatformCodeUserAchievementsProfileResponseType = z.infer<typeof getApiPlatformCodeUserAchievementsProfileResponseSchema>;

export const postApiPlatformCodeUserBlocksRequestSchema = z.object({
    body: z.object({ vketId: z.string() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserBlocksRequestType = z.infer<typeof postApiPlatformCodeUserBlocksRequestSchema>;
export const postApiPlatformCodeUserBlocksResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeUserBlocksResponseType = z.infer<typeof postApiPlatformCodeUserBlocksResponseSchema>;

export const getApiPlatformCodeUserBlocksRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserBlocksRequestType = z.infer<typeof getApiPlatformCodeUserBlocksRequestSchema>;
export const getApiPlatformCodeUserBlocksResponseSchema = z.object({ blocks: z.array(BlockUser) });
export type getApiPlatformCodeUserBlocksResponseType = z.infer<typeof getApiPlatformCodeUserBlocksResponseSchema>;

export const deleteApiPlatformCodeUserBlocksVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeUserBlocksVketIdRequestType = z.infer<typeof deleteApiPlatformCodeUserBlocksVketIdRequestSchema>;
export const deleteApiPlatformCodeUserBlocksVketIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeUserBlocksVketIdResponseType = z.infer<typeof deleteApiPlatformCodeUserBlocksVketIdResponseSchema>;

export const postApiPlatformCodeUserBlocksCheckRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesListBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserBlocksCheckRequestType = z.infer<typeof postApiPlatformCodeUserBlocksCheckRequestSchema>;
export const postApiPlatformCodeUserBlocksCheckResponseSchema = z.object({ blockVketIds: z.array(z.string()) }).partial();
export type postApiPlatformCodeUserBlocksCheckResponseType = z.infer<typeof postApiPlatformCodeUserBlocksCheckResponseSchema>;

export const postApiPlatformCodeUserDirectMessagesRequestSchema = z.object({
    body: postApiPlatformCodeUserDirectMessagesBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserDirectMessagesRequestType = z.infer<typeof postApiPlatformCodeUserDirectMessagesRequestSchema>;
export const postApiPlatformCodeUserDirectMessagesResponseSchema = z.object({ directMessage: z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), roomId: z.number().int(), message: z.string(), isRead: z.boolean(), isHidden: z.boolean(), createdAt: z.string(), updatedAt: z.string() }) });
export type postApiPlatformCodeUserDirectMessagesResponseType = z.infer<typeof postApiPlatformCodeUserDirectMessagesResponseSchema>;

export const getApiPlatformCodeUserDirectMessagesReadRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    roomId: z.number().int(),
})
export type getApiPlatformCodeUserDirectMessagesReadRequestType = z.infer<typeof getApiPlatformCodeUserDirectMessagesReadRequestSchema>;
export const getApiPlatformCodeUserDirectMessagesReadResponseSchema = z.object({ directMessages: z.array(z.object({ id: z.number().int(), userId: z.number().int(), vketId: z.string(), roomId: z.number().int(), message: z.string(), name: z.string(), icon: z.object({ url: z.string() }), isRead: z.boolean(), isHidden: z.boolean().optional(), createdAt: z.string(), updatedAt: z.string() })) });
export type getApiPlatformCodeUserDirectMessagesReadResponseType = z.infer<typeof getApiPlatformCodeUserDirectMessagesReadResponseSchema>;

export const getApiPlatformCodeUserDirectMessagesUnreadCountRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserDirectMessagesUnreadCountRequestType = z.infer<typeof getApiPlatformCodeUserDirectMessagesUnreadCountRequestSchema>;
export const getApiPlatformCodeUserDirectMessagesUnreadCountResponseSchema = z.object({ unreadCount: z.number().int() });
export type getApiPlatformCodeUserDirectMessagesUnreadCountResponseType = z.infer<typeof getApiPlatformCodeUserDirectMessagesUnreadCountResponseSchema>;

export const getApiPlatformCodeUserEventParticipantsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    targetMonth: z.string().optional(),
    filter: z.string().optional(),
    startFrom: z.string().optional(),
    startTo: z.string().optional(),
    vketId: z.string().optional(),
    keyword: z.string().optional(),
    isPaid: z.string().optional(),
    sort: z.string().optional(),
    finished: z.string().optional(),
    endFrom: z.string().optional(),
    endTo: z.string().optional(),
    onlyFinished: z.string().optional(),
    opened: z.string().optional(),
})
export type getApiPlatformCodeUserEventParticipantsRequestType = z.infer<typeof getApiPlatformCodeUserEventParticipantsRequestSchema>;
export const getApiPlatformCodeUserEventParticipantsResponseSchema = EventParticipants;
export type getApiPlatformCodeUserEventParticipantsResponseType = z.infer<typeof getApiPlatformCodeUserEventParticipantsResponseSchema>;

export const postApiPlatformCodeUserGuestProfilesRequestSchema = z.object({
    body: postApiPlatformCodeUserGuestProfilesBody,
    platformCode: z.string(),
})
export type postApiPlatformCodeUserGuestProfilesRequestType = z.infer<typeof postApiPlatformCodeUserGuestProfilesRequestSchema>;
export const postApiPlatformCodeUserGuestProfilesResponseSchema = z.object({ guestProfile: GuestProfile });
export type postApiPlatformCodeUserGuestProfilesResponseType = z.infer<typeof postApiPlatformCodeUserGuestProfilesResponseSchema>;

export const getApiPlatformCodeUserGuestProfilesUuidRequestSchema = z.object({
    platformCode: z.string(),
    uuid: z.string(),
})
export type getApiPlatformCodeUserGuestProfilesUuidRequestType = z.infer<typeof getApiPlatformCodeUserGuestProfilesUuidRequestSchema>;
export const getApiPlatformCodeUserGuestProfilesUuidResponseSchema = z.object({ guestProfile: GuestProfile });
export type getApiPlatformCodeUserGuestProfilesUuidResponseType = z.infer<typeof getApiPlatformCodeUserGuestProfilesUuidResponseSchema>;

export const putApiPlatformCodeUserGuestProfilesUuidRequestSchema = z.object({
    body: putApiPlatformCodeUserGuestProfilesUuidBody,
    platformCode: z.string(),
    uuid: z.string(),
})
export type putApiPlatformCodeUserGuestProfilesUuidRequestType = z.infer<typeof putApiPlatformCodeUserGuestProfilesUuidRequestSchema>;
export const putApiPlatformCodeUserGuestProfilesUuidResponseSchema = z.object({ guestProfile: GuestProfile });
export type putApiPlatformCodeUserGuestProfilesUuidResponseType = z.infer<typeof putApiPlatformCodeUserGuestProfilesUuidResponseSchema>;

export const patchApiPlatformCodeUserGuestProfilesUuidRequestSchema = z.object({
    body: putApiPlatformCodeUserGuestProfilesUuidBody,
    platformCode: z.string(),
    uuid: z.string(),
})
export type patchApiPlatformCodeUserGuestProfilesUuidRequestType = z.infer<typeof patchApiPlatformCodeUserGuestProfilesUuidRequestSchema>;
export const patchApiPlatformCodeUserGuestProfilesUuidResponseSchema = z.object({ guestProfile: GuestProfile });
export type patchApiPlatformCodeUserGuestProfilesUuidResponseType = z.infer<typeof patchApiPlatformCodeUserGuestProfilesUuidResponseSchema>;

export const postApiPlatformCodeUserGuestProfilesListRequestSchema = z.object({
    body: z.object({ uuids: z.array(z.unknown()) }),
    platformCode: z.string(),
})
export type postApiPlatformCodeUserGuestProfilesListRequestType = z.infer<typeof postApiPlatformCodeUserGuestProfilesListRequestSchema>;
export const postApiPlatformCodeUserGuestProfilesListResponseSchema = z.object({ guestProfiles: z.array(GuestProfile) }).partial();
export type postApiPlatformCodeUserGuestProfilesListResponseType = z.infer<typeof postApiPlatformCodeUserGuestProfilesListResponseSchema>;

export const getApiPlatformCodeUserLikesHousingWorldRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    search: z.string().optional(),
    sort: z.string().optional(),
})
export type getApiPlatformCodeUserLikesHousingWorldRequestType = z.infer<typeof getApiPlatformCodeUserLikesHousingWorldRequestSchema>;
export const getApiPlatformCodeUserLikesHousingWorldResponseSchema = z.object({ likedHousingWorldCount: z.number().int(), likedHousingWorlds: z.array(HousingWorld) });
export type getApiPlatformCodeUserLikesHousingWorldResponseType = z.infer<typeof getApiPlatformCodeUserLikesHousingWorldResponseSchema>;

export const getApiPlatformCodeUserLikesWorldPortalRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserLikesWorldPortalRequestType = z.infer<typeof getApiPlatformCodeUserLikesWorldPortalRequestSchema>;
export const getApiPlatformCodeUserLikesWorldPortalResponseSchema = z.object({ likedWorldPortalCount: z.number().int(), likedWorldPortals: z.array(WorldPortal) });
export type getApiPlatformCodeUserLikesWorldPortalResponseType = z.infer<typeof getApiPlatformCodeUserLikesWorldPortalResponseSchema>;

export const getApiPlatformCodeUserMessageSettingsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserMessageSettingsRequestType = z.infer<typeof getApiPlatformCodeUserMessageSettingsRequestSchema>;
export const getApiPlatformCodeUserMessageSettingsResponseSchema = z.object({ messageSetting: MessageSetting });
export type getApiPlatformCodeUserMessageSettingsResponseType = z.infer<typeof getApiPlatformCodeUserMessageSettingsResponseSchema>;

export const postApiPlatformCodeUserMessageSettingsRequestSchema = z.object({
    body: postApiPlatformCodeUserMessageSettingsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserMessageSettingsRequestType = z.infer<typeof postApiPlatformCodeUserMessageSettingsRequestSchema>;
export const postApiPlatformCodeUserMessageSettingsResponseSchema = z.object({ messageSetting: MessageSetting });
export type postApiPlatformCodeUserMessageSettingsResponseType = z.infer<typeof postApiPlatformCodeUserMessageSettingsResponseSchema>;

export const getApiPlatformCodeUserMessageUsersRequestSchema = z.object({
    platformCode: z.string(),
    limit: z.number().int().optional(),
    Authorization: z.string(),
    offset: z.number().int().optional(),
    following: z.string().optional(),
    followed: z.string().optional(),
    mutualFollowing: z.string().optional(),
    name: z.string().optional(),
})
export type getApiPlatformCodeUserMessageUsersRequestType = z.infer<typeof getApiPlatformCodeUserMessageUsersRequestSchema>;
export const getApiPlatformCodeUserMessageUsersResponseSchema = z.object({ userCount: z.number().int(), users: z.array(MessageTargetUser) });
export type getApiPlatformCodeUserMessageUsersResponseType = z.infer<typeof getApiPlatformCodeUserMessageUsersResponseSchema>;

export const postApiPlatformCodeUserProfilesRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserProfilesRequestType = z.infer<typeof postApiPlatformCodeUserProfilesRequestSchema>;
export const postApiPlatformCodeUserProfilesResponseSchema = z.object({ profile: Profile });
export type postApiPlatformCodeUserProfilesResponseType = z.infer<typeof postApiPlatformCodeUserProfilesResponseSchema>;

export const getApiPlatformCodeUserProfilesRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserProfilesRequestType = z.infer<typeof getApiPlatformCodeUserProfilesRequestSchema>;
export const getApiPlatformCodeUserProfilesResponseSchema = z.object({ profile: Profile });
export type getApiPlatformCodeUserProfilesResponseType = z.infer<typeof getApiPlatformCodeUserProfilesResponseSchema>;

export const putApiPlatformCodeUserProfilesRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type putApiPlatformCodeUserProfilesRequestType = z.infer<typeof putApiPlatformCodeUserProfilesRequestSchema>;
export const putApiPlatformCodeUserProfilesResponseSchema = z.object({ profile: Profile });
export type putApiPlatformCodeUserProfilesResponseType = z.infer<typeof putApiPlatformCodeUserProfilesResponseSchema>;

export const patchApiPlatformCodeUserProfilesRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type patchApiPlatformCodeUserProfilesRequestType = z.infer<typeof patchApiPlatformCodeUserProfilesRequestSchema>;
export const patchApiPlatformCodeUserProfilesResponseSchema = z.object({ profile: Profile });
export type patchApiPlatformCodeUserProfilesResponseType = z.infer<typeof patchApiPlatformCodeUserProfilesResponseSchema>;

export const getApiPlatformCodeUserProfilesVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserProfilesVketIdRequestType = z.infer<typeof getApiPlatformCodeUserProfilesVketIdRequestSchema>;
export const getApiPlatformCodeUserProfilesVketIdResponseSchema = z.object({ profile: ProfileDetails });
export type getApiPlatformCodeUserProfilesVketIdResponseType = z.infer<typeof getApiPlatformCodeUserProfilesVketIdResponseSchema>;

export const postApiPlatformCodeUserProfilesListRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesListBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserProfilesListRequestType = z.infer<typeof postApiPlatformCodeUserProfilesListRequestSchema>;
export const postApiPlatformCodeUserProfilesListResponseSchema = z.object({ profiles: z.array(Profile) });
export type postApiPlatformCodeUserProfilesListResponseType = z.infer<typeof postApiPlatformCodeUserProfilesListResponseSchema>;

export const getApiPlatformCodeUserPushSettingsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserPushSettingsRequestType = z.infer<typeof getApiPlatformCodeUserPushSettingsRequestSchema>;
export const getApiPlatformCodeUserPushSettingsResponseSchema = z.object({ pushSetting: PushSetting }).partial();
export type getApiPlatformCodeUserPushSettingsResponseType = z.infer<typeof getApiPlatformCodeUserPushSettingsResponseSchema>;

export const postApiPlatformCodeUserPushSettingsRequestSchema = z.object({
    body: postApiPlatformCodeUserPushSettingsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserPushSettingsRequestType = z.infer<typeof postApiPlatformCodeUserPushSettingsRequestSchema>;
export const postApiPlatformCodeUserPushSettingsResponseSchema = z.object({ pushSetting: PushSetting }).partial();
export type postApiPlatformCodeUserPushSettingsResponseType = z.infer<typeof postApiPlatformCodeUserPushSettingsResponseSchema>;

export const postApiPlatformCodeUserPushTokensRequestSchema = z.object({
    body: postApiPlatformCodeUserPushTokensBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserPushTokensRequestType = z.infer<typeof postApiPlatformCodeUserPushTokensRequestSchema>;
export const postApiPlatformCodeUserPushTokensResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeUserPushTokensResponseType = z.infer<typeof postApiPlatformCodeUserPushTokensResponseSchema>;

export const postApiPlatformCodeUserRelationshipsRequestSchema = z.object({
    body: z.object({ vketId: z.string() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserRelationshipsRequestType = z.infer<typeof postApiPlatformCodeUserRelationshipsRequestSchema>;
export const postApiPlatformCodeUserRelationshipsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeUserRelationshipsResponseType = z.infer<typeof postApiPlatformCodeUserRelationshipsResponseSchema>;

export const deleteApiPlatformCodeUserRelationshipsVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeUserRelationshipsVketIdRequestType = z.infer<typeof deleteApiPlatformCodeUserRelationshipsVketIdRequestSchema>;
export const deleteApiPlatformCodeUserRelationshipsVketIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeUserRelationshipsVketIdResponseType = z.infer<typeof deleteApiPlatformCodeUserRelationshipsVketIdResponseSchema>;

export const getApiPlatformCodeUserRelationshipsVketIdCommonFollowingsRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserRelationshipsVketIdCommonFollowingsRequestType = z.infer<typeof getApiPlatformCodeUserRelationshipsVketIdCommonFollowingsRequestSchema>;
export const getApiPlatformCodeUserRelationshipsVketIdCommonFollowingsResponseSchema = z.object({ userCount: z.number().int(), users: z.array(RelationshipUser) });
export type getApiPlatformCodeUserRelationshipsVketIdCommonFollowingsResponseType = z.infer<typeof getApiPlatformCodeUserRelationshipsVketIdCommonFollowingsResponseSchema>;

export const getApiPlatformCodeUserRelationshipsVketIdFollowersRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    name: z.string().optional(),
})
export type getApiPlatformCodeUserRelationshipsVketIdFollowersRequestType = z.infer<typeof getApiPlatformCodeUserRelationshipsVketIdFollowersRequestSchema>;
export const getApiPlatformCodeUserRelationshipsVketIdFollowersResponseSchema = z.object({ userCount: z.number().int(), users: z.array(RelationshipUser) });
export type getApiPlatformCodeUserRelationshipsVketIdFollowersResponseType = z.infer<typeof getApiPlatformCodeUserRelationshipsVketIdFollowersResponseSchema>;

export const getApiPlatformCodeUserRelationshipsVketIdFollowingsRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    name: z.string().optional(),
})
export type getApiPlatformCodeUserRelationshipsVketIdFollowingsRequestType = z.infer<typeof getApiPlatformCodeUserRelationshipsVketIdFollowingsRequestSchema>;
export const getApiPlatformCodeUserRelationshipsVketIdFollowingsResponseSchema = z.object({ userCount: z.number().int(), users: z.array(RelationshipUser) });
export type getApiPlatformCodeUserRelationshipsVketIdFollowingsResponseType = z.infer<typeof getApiPlatformCodeUserRelationshipsVketIdFollowingsResponseSchema>;

export const getApiPlatformCodeUserRelationshipsMutualFollowingsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    name: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserRelationshipsMutualFollowingsRequestType = z.infer<typeof getApiPlatformCodeUserRelationshipsMutualFollowingsRequestSchema>;
export const getApiPlatformCodeUserRelationshipsMutualFollowingsResponseSchema = z.object({ userCount: z.number().int(), users: z.array(MutualRelationshipUser) });
export type getApiPlatformCodeUserRelationshipsMutualFollowingsResponseType = z.infer<typeof getApiPlatformCodeUserRelationshipsMutualFollowingsResponseSchema>;

export const getApiPlatformCodeUserRoomsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserRoomsRequestType = z.infer<typeof getApiPlatformCodeUserRoomsRequestSchema>;
export const getApiPlatformCodeUserRoomsResponseSchema = z.object({ roomCount: z.number().int(), rooms: z.array(Room) });
export type getApiPlatformCodeUserRoomsResponseType = z.infer<typeof getApiPlatformCodeUserRoomsResponseSchema>;

export const getApiPlatformCodeUserRoomsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    limitId: z.number().int().optional(),
    offsetId: z.number().int().optional(),
    lastMessageLimit: z.number().int().optional(),
})
export type getApiPlatformCodeUserRoomsIdRequestType = z.infer<typeof getApiPlatformCodeUserRoomsIdRequestSchema>;
export const getApiPlatformCodeUserRoomsIdResponseSchema = z.object({ directMessages: z.array(z.object({ id: z.number().int(), userId: z.number().int(), roomId: z.number().int(), message: z.string(), isRead: z.boolean(), isHidden: z.boolean(), user: z.object({ id: z.number().int(), vketId: z.string(), name: z.string(), icon: z.object({ url: z.string() }) }), createdAt: z.string(), updatedAt: z.string() })), blockStatus: z.boolean(), blockedStatus: z.boolean(), mutualFollowStatus: z.boolean() });
export type getApiPlatformCodeUserRoomsIdResponseType = z.infer<typeof getApiPlatformCodeUserRoomsIdResponseSchema>;

export const deleteApiPlatformCodeUserRoomsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeUserRoomsIdRequestType = z.infer<typeof deleteApiPlatformCodeUserRoomsIdRequestSchema>;
export const deleteApiPlatformCodeUserRoomsIdResponseSchema = z.object({ success: Message });
export type deleteApiPlatformCodeUserRoomsIdResponseType = z.infer<typeof deleteApiPlatformCodeUserRoomsIdResponseSchema>;

export const getApiPlatformCodeUserRoomsCheckRoomRoomIdRequestSchema = z.object({
    platformCode: z.string(),
    roomId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserRoomsCheckRoomRoomIdRequestType = z.infer<typeof getApiPlatformCodeUserRoomsCheckRoomRoomIdRequestSchema>;
export const getApiPlatformCodeUserRoomsCheckRoomRoomIdResponseSchema = z.object({ room: CheckRoom });
export type getApiPlatformCodeUserRoomsCheckRoomRoomIdResponseType = z.infer<typeof getApiPlatformCodeUserRoomsCheckRoomRoomIdResponseSchema>;

export const getApiPlatformCodeUserRoomsCheckVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeUserRoomsCheckVketIdRequestType = z.infer<typeof getApiPlatformCodeUserRoomsCheckVketIdRequestSchema>;
export const getApiPlatformCodeUserRoomsCheckVketIdResponseSchema = z.object({ room: CheckRoom });
export type getApiPlatformCodeUserRoomsCheckVketIdResponseType = z.infer<typeof getApiPlatformCodeUserRoomsCheckVketIdResponseSchema>;

export const getApiPlatformCodeUserRoomsSearchRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    word: z.string(),
})
export type getApiPlatformCodeUserRoomsSearchRequestType = z.infer<typeof getApiPlatformCodeUserRoomsSearchRequestSchema>;
export const getApiPlatformCodeUserRoomsSearchResponseSchema = z.object({ rooms: z.array(z.object({ id: z.number().int(), name: z.string(), icon: z.object({ url: z.string() }), message: z.string(), messageAt: z.string(), createdAt: z.string(), updatedAt: z.string() })) });
export type getApiPlatformCodeUserRoomsSearchResponseType = z.infer<typeof getApiPlatformCodeUserRoomsSearchResponseSchema>;

export const postApiPlatformCodeUserTutorialCompleteRequestSchema = z.object({
    body: postApiPlatformCodeUserTutorialCompleteBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeUserTutorialCompleteRequestType = z.infer<typeof postApiPlatformCodeUserTutorialCompleteRequestSchema>;
export const postApiPlatformCodeUserTutorialCompleteResponseSchema = z.object({ profile: Profile });
export type postApiPlatformCodeUserTutorialCompleteResponseType = z.infer<typeof postApiPlatformCodeUserTutorialCompleteResponseSchema>;

export const getApiPlatformCodeUserWorldVisitsFootprintRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    Authorization: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserWorldVisitsFootprintRequestType = z.infer<typeof getApiPlatformCodeUserWorldVisitsFootprintRequestSchema>;
export const getApiPlatformCodeUserWorldVisitsFootprintResponseSchema = z.object({ visitCount: z.number().int(), worldVisits: z.array(z.object({ id: z.number().int(), worldSetId: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), user: z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string() }), topPageAvatarId: z.number().int(), followStatus: z.boolean() }) })) });
export type getApiPlatformCodeUserWorldVisitsFootprintResponseType = z.infer<typeof getApiPlatformCodeUserWorldVisitsFootprintResponseSchema>;

export const getApiPlatformCodeUserWorldVisitsFootprintedWorldSetIdRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getApiPlatformCodeUserWorldVisitsFootprintedWorldSetIdRequestType = z.infer<typeof getApiPlatformCodeUserWorldVisitsFootprintedWorldSetIdRequestSchema>;
export const getApiPlatformCodeUserWorldVisitsFootprintedWorldSetIdResponseSchema = z.object({ visitCount: z.number().int(), worldVisits: z.array(z.object({ id: z.number().int(), worldSetId: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), user: z.object({ id: z.number().int(), vketId: z.string(), createdAt: z.string(), updatedAt: z.string(), name: z.string(), introduction: z.string(), icon: z.object({ url: z.string() }), topPageAvatarId: z.number().int(), followStatus: z.boolean() }) })) });
export type getApiPlatformCodeUserWorldVisitsFootprintedWorldSetIdResponseType = z.infer<typeof getApiPlatformCodeUserWorldVisitsFootprintedWorldSetIdResponseSchema>;

export const getApiPlatformCodeVioramaTexturesWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
})
export type getApiPlatformCodeVioramaTexturesWorldIdRequestType = z.infer<typeof getApiPlatformCodeVioramaTexturesWorldIdRequestSchema>;
export const getApiPlatformCodeVioramaTexturesWorldIdResponseSchema = z.object({ textures: z.array(z.object({ vketId: z.string(), worldId: z.string(), item: z.string(), materialName: z.string(), image: z.string().url() })) }).partial();
export type getApiPlatformCodeVioramaTexturesWorldIdResponseType = z.infer<typeof getApiPlatformCodeVioramaTexturesWorldIdResponseSchema>;

export const postApiPlatformCodeWorldLikesRequestSchema = z.object({
    body: z.object({ worldSetId: z.number().int() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeWorldLikesRequestType = z.infer<typeof postApiPlatformCodeWorldLikesRequestSchema>;
export const postApiPlatformCodeWorldLikesResponseSchema = z.object({ world: World });
export type postApiPlatformCodeWorldLikesResponseType = z.infer<typeof postApiPlatformCodeWorldLikesResponseSchema>;

export const deleteApiPlatformCodeWorldLikesWorldSetIdRequestSchema = z.object({
    platformCode: z.string(),
    worldSetId: z.string(),
    Authorization: z.string(),
})
export type deleteApiPlatformCodeWorldLikesWorldSetIdRequestType = z.infer<typeof deleteApiPlatformCodeWorldLikesWorldSetIdRequestSchema>;
export const deleteApiPlatformCodeWorldLikesWorldSetIdResponseSchema = z.object({ world: World });
export type deleteApiPlatformCodeWorldLikesWorldSetIdResponseType = z.infer<typeof deleteApiPlatformCodeWorldLikesWorldSetIdResponseSchema>;

export const getApiPlatformCodeWorldPortalsRequestSchema = z.object({
    platformCode: z.string(),
    Authorization: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    filter: z.string().optional(),
    keyword: z.string().optional(),
    sort: z.string().optional(),
    creatorVketId: z.string().optional(),
    eventUse: z.string().optional(),
    canHostPaidEvent: z.string().optional(),
})
export type getApiPlatformCodeWorldPortalsRequestType = z.infer<typeof getApiPlatformCodeWorldPortalsRequestSchema>;
export const getApiPlatformCodeWorldPortalsResponseSchema = z.object({ worldPortalCount: z.number().int(), worldPortals: z.array(WorldPortal) });
export type getApiPlatformCodeWorldPortalsResponseType = z.infer<typeof getApiPlatformCodeWorldPortalsResponseSchema>;

export const getApiPlatformCodeWorldPortalsIdRequestSchema = z.object({
    platformCode: z.string(),
    id: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodeWorldPortalsIdRequestType = z.infer<typeof getApiPlatformCodeWorldPortalsIdRequestSchema>;
export const getApiPlatformCodeWorldPortalsIdResponseSchema = z.object({ worldPortal: WorldPortal });
export type getApiPlatformCodeWorldPortalsIdResponseType = z.infer<typeof getApiPlatformCodeWorldPortalsIdResponseSchema>;

export const getApiPlatformCodeWorldPortalsSearchByWorldIdWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    Authorization: z.string(),
    spatiumCode: z.string().optional(),
})
export type getApiPlatformCodeWorldPortalsSearchByWorldIdWorldIdRequestType = z.infer<typeof getApiPlatformCodeWorldPortalsSearchByWorldIdWorldIdRequestSchema>;
export const getApiPlatformCodeWorldPortalsSearchByWorldIdWorldIdResponseSchema = z.object({ worldPortal: WorldPortal });
export type getApiPlatformCodeWorldPortalsSearchByWorldIdWorldIdResponseType = z.infer<typeof getApiPlatformCodeWorldPortalsSearchByWorldIdWorldIdResponseSchema>;

export const postApiPlatformCodeWorldReportsRequestSchema = z.object({
    body: postApiPlatformCodeWorldReportsBody,
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeWorldReportsRequestType = z.infer<typeof postApiPlatformCodeWorldReportsRequestSchema>;
export const postApiPlatformCodeWorldReportsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeWorldReportsResponseType = z.infer<typeof postApiPlatformCodeWorldReportsResponseSchema>;

export const postApiPlatformCodeWorldVisitsRequestSchema = z.object({
    body: z.object({ worldSetId: z.number().int() }),
    platformCode: z.string(),
    Authorization: z.string(),
})
export type postApiPlatformCodeWorldVisitsRequestType = z.infer<typeof postApiPlatformCodeWorldVisitsRequestSchema>;
export const postApiPlatformCodeWorldVisitsResponseSchema = z.object({ success: Message });
export type postApiPlatformCodeWorldVisitsResponseType = z.infer<typeof postApiPlatformCodeWorldVisitsResponseSchema>;

export const getApiPlatformCodePresetFurnituresUuidRequestSchema = z.object({
    uuid: z.string(),
    Authorization: z.string(),
})
export type getApiPlatformCodePresetFurnituresUuidRequestType = z.infer<typeof getApiPlatformCodePresetFurnituresUuidRequestSchema>;
export const getApiPlatformCodePresetFurnituresUuidResponseSchema = z.object({ presetFurniture: PresetFurnitureWithTextureMaterial });
export type getApiPlatformCodePresetFurnituresUuidResponseType = z.infer<typeof getApiPlatformCodePresetFurnituresUuidResponseSchema>;

export const getApiRolesRequestSchema = z.object({
    Authorization: z.string(),
})
export type getApiRolesRequestType = z.infer<typeof getApiRolesRequestSchema>;
export const getApiRolesResponseSchema = z.object({ roles: z.array(z.object({ role: z.string(), platformCode: z.string() })) });
export type getApiRolesResponseType = z.infer<typeof getApiRolesResponseSchema>;

export const postApiTemporaryUploadsNoClipRequestSchema = z.object({
    body: postApiTemporaryUploadsNoClipBody,
    Authorization: z.string(),
})
export type postApiTemporaryUploadsNoClipRequestType = z.infer<typeof postApiTemporaryUploadsNoClipRequestSchema>;
export const postApiTemporaryUploadsNoClipResponseSchema = z.object({ temporaryUpload: TemporaryUpload });
export type postApiTemporaryUploadsNoClipResponseType = z.infer<typeof postApiTemporaryUploadsNoClipResponseSchema>;

export const postApiTemporaryUploadsTextureRequestSchema = z.object({
    body: postApiTemporaryUploadsTextureBody,
    Authorization: z.string(),
})
export type postApiTemporaryUploadsTextureRequestType = z.infer<typeof postApiTemporaryUploadsTextureRequestSchema>;
export const postApiTemporaryUploadsTextureResponseSchema = z.object({ temporaryUpload: TemporaryUpload });
export type postApiTemporaryUploadsTextureResponseType = z.infer<typeof postApiTemporaryUploadsTextureResponseSchema>;

export const postInternalPlatformCodeUserGuestProfilesRequestSchema = z.object({
    body: postApiPlatformCodeUserGuestProfilesBody,
    platformCode: z.string(),
})
export type postInternalPlatformCodeUserGuestProfilesRequestType = z.infer<typeof postInternalPlatformCodeUserGuestProfilesRequestSchema>;
export const postInternalPlatformCodeUserGuestProfilesResponseSchema = z.object({ guestProfile: GuestProfile });
export type postInternalPlatformCodeUserGuestProfilesResponseType = z.infer<typeof postInternalPlatformCodeUserGuestProfilesResponseSchema>;

export const getInternalPlatformCodeUserGuestProfilesUuidRequestSchema = z.object({
    platformCode: z.string(),
    uuid: z.string(),
})
export type getInternalPlatformCodeUserGuestProfilesUuidRequestType = z.infer<typeof getInternalPlatformCodeUserGuestProfilesUuidRequestSchema>;
export const getInternalPlatformCodeUserGuestProfilesUuidResponseSchema = z.object({ guestProfile: GuestProfile });
export type getInternalPlatformCodeUserGuestProfilesUuidResponseType = z.infer<typeof getInternalPlatformCodeUserGuestProfilesUuidResponseSchema>;

export const putInternalPlatformCodeUserGuestProfilesUuidRequestSchema = z.object({
    body: putApiPlatformCodeUserGuestProfilesUuidBody,
    platformCode: z.string(),
    uuid: z.string(),
})
export type putInternalPlatformCodeUserGuestProfilesUuidRequestType = z.infer<typeof putInternalPlatformCodeUserGuestProfilesUuidRequestSchema>;
export const putInternalPlatformCodeUserGuestProfilesUuidResponseSchema = z.object({ guestProfile: GuestProfile });
export type putInternalPlatformCodeUserGuestProfilesUuidResponseType = z.infer<typeof putInternalPlatformCodeUserGuestProfilesUuidResponseSchema>;

export const getInternalPlatformCodeUserProfilesVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
})
export type getInternalPlatformCodeUserProfilesVketIdRequestType = z.infer<typeof getInternalPlatformCodeUserProfilesVketIdRequestSchema>;
export const getInternalPlatformCodeUserProfilesVketIdResponseSchema = z.object({ profile: ProfileDetails });
export type getInternalPlatformCodeUserProfilesVketIdResponseType = z.infer<typeof getInternalPlatformCodeUserProfilesVketIdResponseSchema>;

export const postInternalPlatformCodeUserProfilesVketIdRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesBody,
    platformCode: z.string(),
    vketId: z.string(),
})
export type postInternalPlatformCodeUserProfilesVketIdRequestType = z.infer<typeof postInternalPlatformCodeUserProfilesVketIdRequestSchema>;
export const postInternalPlatformCodeUserProfilesVketIdResponseSchema = z.object({ profile: Profile });
export type postInternalPlatformCodeUserProfilesVketIdResponseType = z.infer<typeof postInternalPlatformCodeUserProfilesVketIdResponseSchema>;

export const putInternalPlatformCodeUserProfilesVketIdRequestSchema = z.object({
    body: postApiPlatformCodeUserProfilesBody,
    platformCode: z.string(),
    vketId: z.string(),
})
export type putInternalPlatformCodeUserProfilesVketIdRequestType = z.infer<typeof putInternalPlatformCodeUserProfilesVketIdRequestSchema>;
export const putInternalPlatformCodeUserProfilesVketIdResponseSchema = z.object({ profile: Profile });
export type putInternalPlatformCodeUserProfilesVketIdResponseType = z.infer<typeof putInternalPlatformCodeUserProfilesVketIdResponseSchema>;

export const getInternalPlatformCodeUserRelationshipsVketIdFollowersRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    name: z.string().optional(),
})
export type getInternalPlatformCodeUserRelationshipsVketIdFollowersRequestType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdFollowersRequestSchema>;
export const getInternalPlatformCodeUserRelationshipsVketIdFollowersResponseSchema = z.object({ userCount: z.number().int(), users: z.array(RelationshipUser) });
export type getInternalPlatformCodeUserRelationshipsVketIdFollowersResponseType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdFollowersResponseSchema>;

export const getInternalPlatformCodeUserRelationshipsVketIdFollowersTargetVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    targetVketId: z.string(),
})
export type getInternalPlatformCodeUserRelationshipsVketIdFollowersTargetVketIdRequestType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdFollowersTargetVketIdRequestSchema>;
export const getInternalPlatformCodeUserRelationshipsVketIdFollowersTargetVketIdResponseSchema = z.object({ user: RelationshipUser });
export type getInternalPlatformCodeUserRelationshipsVketIdFollowersTargetVketIdResponseType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdFollowersTargetVketIdResponseSchema>;

export const getInternalPlatformCodeUserRelationshipsVketIdFollowingsRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
    name: z.string().optional(),
})
export type getInternalPlatformCodeUserRelationshipsVketIdFollowingsRequestType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdFollowingsRequestSchema>;
export const getInternalPlatformCodeUserRelationshipsVketIdFollowingsResponseSchema = z.object({ userCount: z.number().int(), users: z.array(RelationshipUser) });
export type getInternalPlatformCodeUserRelationshipsVketIdFollowingsResponseType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdFollowingsResponseSchema>;

export const getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    name: z.string().optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsRequestType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsRequestSchema>;
export const getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsResponseSchema = z.object({ userCount: z.number().int(), users: z.array(MutualRelationshipUser) });
export type getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsResponseType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsResponseSchema>;

export const getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsTargetVketIdRequestSchema = z.object({
    platformCode: z.string(),
    vketId: z.string(),
    targetVketId: z.string(),
})
export type getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsTargetVketIdRequestType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsTargetVketIdRequestSchema>;
export const getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsTargetVketIdResponseSchema = z.object({ user: MutualRelationshipUser });
export type getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsTargetVketIdResponseType = z.infer<typeof getInternalPlatformCodeUserRelationshipsVketIdMutualFollowingsTargetVketIdResponseSchema>;

export const getInternalPlatformCodeWorldPortalsSearchByWorldIdWorldIdRequestSchema = z.object({
    platformCode: z.string(),
    worldId: z.string(),
    spatiumCode: z.string().optional(),
})
export type getInternalPlatformCodeWorldPortalsSearchByWorldIdWorldIdRequestType = z.infer<typeof getInternalPlatformCodeWorldPortalsSearchByWorldIdWorldIdRequestSchema>;
export const getInternalPlatformCodeWorldPortalsSearchByWorldIdWorldIdResponseSchema = z.object({ worldPortal: InternalWorldPortal });
export type getInternalPlatformCodeWorldPortalsSearchByWorldIdWorldIdResponseType = z.infer<typeof getInternalPlatformCodeWorldPortalsSearchByWorldIdWorldIdResponseSchema>;

export const postInternalAchievementsRequestSchema = z.object({
    body: postInternalAchievementsBody,
})
export type postInternalAchievementsRequestType = z.infer<typeof postInternalAchievementsRequestSchema>;
export const postInternalAchievementsResponseSchema = z.void();
export type postInternalAchievementsResponseType = z.infer<typeof postInternalAchievementsResponseSchema>;

export const postInternalAvatarPartsRequestSchema = z.object({
    partType: z.enum(["head", "hair", "eye", "ear", "bottoms", "tops", "onePiece", "shoes", "accessory"]).optional(),
    limit: z.number().int().optional(),
    offset: z.number().int().optional(),
})
export type postInternalAvatarPartsRequestType = z.infer<typeof postInternalAvatarPartsRequestSchema>;
export const postInternalAvatarPartsResponseSchema = z.object({ avatarParts: z.array(AvatarPart), avatarPartsCount: z.number().int() });
export type postInternalAvatarPartsResponseType = z.infer<typeof postInternalAvatarPartsResponseSchema>;

export const postInternalAvatarPartsListRequestSchema = z.object({
    body: postInternalAvatarPartsListBody,
})
export type postInternalAvatarPartsListRequestType = z.infer<typeof postInternalAvatarPartsListRequestSchema>;
export const postInternalAvatarPartsListResponseSchema = z.object({ avatarParts: z.array(AvatarPart), avatarPartsCount: z.number().int() });
export type postInternalAvatarPartsListResponseType = z.infer<typeof postInternalAvatarPartsListResponseSchema>;

export const patchInternalAvatarPartsListRequestSchema = z.object({
    body: patchApiPlatformCodeAdminAvatarPartsBody,
})
export type patchInternalAvatarPartsListRequestType = z.infer<typeof patchInternalAvatarPartsListRequestSchema>;
export const patchInternalAvatarPartsListResponseSchema = z.object({ avatarParts: z.array(AvatarPart), avatarPartsCount: z.number().int() });
export type patchInternalAvatarPartsListResponseType = z.infer<typeof patchInternalAvatarPartsListResponseSchema>;

export const getInternalEmotesRequestSchema = z.object({
    emoteVersion: z.number().int().gte(1),
    avatarType: z.string(),
    spatiumCode: z.string(),
    worldId: z.string(),
})
export type getInternalEmotesRequestType = z.infer<typeof getInternalEmotesRequestSchema>;
export const getInternalEmotesResponseSchema = z.object({ emotes: z.array(Emote), initialEmotes: z.array(InitialEmote), initialWorldEmotes: z.array(InitialWorldEmote), fixedWorldEmotes: z.array(FixedWorldEmote), worldEmoteBlacklists: z.array(WorldEmoteBlacklist) });
export type getInternalEmotesResponseType = z.infer<typeof getInternalEmotesResponseSchema>;

export const postInternalEmotesRequestSchema = z.object({
    body: postInternalEmotesBody,
})
export type postInternalEmotesRequestType = z.infer<typeof postInternalEmotesRequestSchema>;
export const postInternalEmotesResponseSchema = z.object({ emote: Emote, initialEmotes: z.array(InitialEmote), initialWorldEmotes: z.array(InitialWorldEmote), fixedWorldEmotes: z.array(FixedWorldEmote), worldEmoteBlacklists: z.array(WorldEmoteBlacklist) });
export type postInternalEmotesResponseType = z.infer<typeof postInternalEmotesResponseSchema>;

export const getInternalEmotesIdRequestSchema = z.object({
    id: z.string(),
    emoteVersion: z.number().int().gte(1),
})
export type getInternalEmotesIdRequestType = z.infer<typeof getInternalEmotesIdRequestSchema>;
export const getInternalEmotesIdResponseSchema = z.object({ emote: Emote, initialEmotes: z.array(InitialEmote), initialWorldEmotes: z.array(InitialWorldEmote), fixedWorldEmotes: z.array(FixedWorldEmote), worldEmoteBlacklists: z.array(WorldEmoteBlacklist) });
export type getInternalEmotesIdResponseType = z.infer<typeof getInternalEmotesIdResponseSchema>;

export const putInternalEmotesIdRequestSchema = z.object({
    body: postInternalEmotesBody,
    id: z.string(),
})
export type putInternalEmotesIdRequestType = z.infer<typeof putInternalEmotesIdRequestSchema>;
export const putInternalEmotesIdResponseSchema = z.object({ emote: Emote, initialEmotes: z.array(InitialEmote), initialWorldEmotes: z.array(InitialWorldEmote), fixedWorldEmotes: z.array(FixedWorldEmote), worldEmoteBlacklists: z.array(WorldEmoteBlacklist) });
export type putInternalEmotesIdResponseType = z.infer<typeof putInternalEmotesIdResponseSchema>;

export const deleteInternalEmotesIdRequestSchema = z.object({
    id: z.string(),
})
export type deleteInternalEmotesIdRequestType = z.infer<typeof deleteInternalEmotesIdRequestSchema>;
export const deleteInternalEmotesIdResponseSchema = z.object({ success: Message });
export type deleteInternalEmotesIdResponseType = z.infer<typeof deleteInternalEmotesIdResponseSchema>;

export const getInternalGuestsUuidEmotePalletsRequestSchema = z.object({
    uuid: z.string(),
})
export type getInternalGuestsUuidEmotePalletsRequestType = z.infer<typeof getInternalGuestsUuidEmotePalletsRequestSchema>;
export const getInternalGuestsUuidEmotePalletsResponseSchema = z.object({ guestEmotePallets: z.array(GuestEmotePallet) });
export type getInternalGuestsUuidEmotePalletsResponseType = z.infer<typeof getInternalGuestsUuidEmotePalletsResponseSchema>;

export const postInternalGuestsUuidEmotePalletsRequestSchema = z.object({
    body: postInternalUsersVketIdEmotePalletsBody,
    uuid: z.string(),
})
export type postInternalGuestsUuidEmotePalletsRequestType = z.infer<typeof postInternalGuestsUuidEmotePalletsRequestSchema>;
export const postInternalGuestsUuidEmotePalletsResponseSchema = z.object({ guestEmotePallets: z.array(GuestEmotePallet) });
export type postInternalGuestsUuidEmotePalletsResponseType = z.infer<typeof postInternalGuestsUuidEmotePalletsResponseSchema>;

export const getInternalIngameMessagesRequestSchema = z.object({
    worldId: z.string().optional(),
    positionChannelId: z.string().optional(),
})
export type getInternalIngameMessagesRequestType = z.infer<typeof getInternalIngameMessagesRequestSchema>;
export const getInternalIngameMessagesResponseSchema = z.object({ ingameMessages: z.array(IngameMessage) });
export type getInternalIngameMessagesResponseType = z.infer<typeof getInternalIngameMessagesResponseSchema>;

export const getInternalPresentsVketIdRequestSchema = z.object({
    vketId: z.string(),
})
export type getInternalPresentsVketIdRequestType = z.infer<typeof getInternalPresentsVketIdRequestSchema>;
export const getInternalPresentsVketIdResponseSchema = z.object({ presents: z.array(InternalPresent) });
export type getInternalPresentsVketIdResponseType = z.infer<typeof getInternalPresentsVketIdResponseSchema>;

export const postInternalPushSendRequestSchema = z.object({
    body: postInternalPushSendBody,
})
export type postInternalPushSendRequestType = z.infer<typeof postInternalPushSendRequestSchema>;
export const postInternalPushSendResponseSchema = z.object({ success: Message });
export type postInternalPushSendResponseType = z.infer<typeof postInternalPushSendResponseSchema>;

export const getInternalUsersVketIdEmotePalletsRequestSchema = z.object({
    vketId: z.string(),
})
export type getInternalUsersVketIdEmotePalletsRequestType = z.infer<typeof getInternalUsersVketIdEmotePalletsRequestSchema>;
export const getInternalUsersVketIdEmotePalletsResponseSchema = z.object({ userEmotePallets: z.array(UserEmotePallet) });
export type getInternalUsersVketIdEmotePalletsResponseType = z.infer<typeof getInternalUsersVketIdEmotePalletsResponseSchema>;

export const postInternalUsersVketIdEmotePalletsRequestSchema = z.object({
    body: postInternalUsersVketIdEmotePalletsBody,
    vketId: z.string(),
})
export type postInternalUsersVketIdEmotePalletsRequestType = z.infer<typeof postInternalUsersVketIdEmotePalletsRequestSchema>;
export const postInternalUsersVketIdEmotePalletsResponseSchema = z.object({ userEmotePallets: z.array(UserEmotePallet) });
export type postInternalUsersVketIdEmotePalletsResponseType = z.infer<typeof postInternalUsersVketIdEmotePalletsResponseSchema>;

export const postInternalWorldPortalsRequestSchema = z.object({
    body: postInternalWorldPortalsBody,
})
export type postInternalWorldPortalsRequestType = z.infer<typeof postInternalWorldPortalsRequestSchema>;
export const postInternalWorldPortalsResponseSchema = z.object({ worldPortal: InternalWorldPortal });
export type postInternalWorldPortalsResponseType = z.infer<typeof postInternalWorldPortalsResponseSchema>;

export const patchInternalWorldPortalsWorldPortalIdRequestSchema = z.object({
    body: postInternalWorldPortalsBody,
    worldSetId: z.string(),
})
export type patchInternalWorldPortalsWorldPortalIdRequestType = z.infer<typeof patchInternalWorldPortalsWorldPortalIdRequestSchema>;
export const patchInternalWorldPortalsWorldPortalIdResponseSchema = z.object({ worldPortal: InternalWorldPortal });
export type patchInternalWorldPortalsWorldPortalIdResponseType = z.infer<typeof patchInternalWorldPortalsWorldPortalIdResponseSchema>;


import { z } from 'zod'
import { ensureValueOf } from '@/utils/zod'
import { todoSchema } from '@/models/_vaf/todo'
import { api } from '@/utils/_vaf/api'
import { requireRuntimeConfig } from '@/plugins/_vaf/runtimeConfig'
import { raiseError } from '@/utils/_vaf/error'

const statusSchema = z.literal('ok').or(z.literal('ng'))

export const getExampleResponseSchema = z.object({
  status: statusSchema,
  data: z.object({
    todos: z.array(todoSchema),
  }),
})
export type GetExampleResponse = z.infer<typeof getExampleResponseSchema>

export default {
  get: {
    async getExample(): Promise<GetExampleResponse> {
      const prefix =
        requireRuntimeConfig().public?.apiPrefix ?? raiseError('getExample()')
      const response = await api.get(`${prefix}/example`)
      ensureValueOf(getExampleResponseSchema, response)
      return response
    },
  } as const,
}

import { z } from 'zod'

// ファイル内で使うためのschema
const AssetAvatarDetail = z
  .object({
    motionType: z.string(),
    targetAge: z.string(),
    avatarType: z.string(),
    polygonCount: z.number().int().nullable(),
    useVketChanParts: z.boolean(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const AssetZipDetail = z
  .object({
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const AssetFurnitureDetail = z
  .object({
    subType: z.enum(['floor', 'ceiling', 'wall']),
    objectType: z.enum(['object', 'activity']),
    jsonPath: z.string().nullable(),
    polygonCount: z.number().int().nullable(),
    collisiondetection: z.boolean(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const AssetVersion = z
  .object({
    id: z.number().int(),
    uuid: z.string(),
    version: z.string(),
    thumbnail: z
      .object({
        url: z.string(),
        medium: z.object({ url: z.string() }).partial(),
      })
      .partial()
      ,
    ingameThumbnail: z
      .object({
        url: z.string(),
        medium: z.object({ url: z.string() }).partial(),
      })
      .partial()
      ,
    animation: z.object({ url: z.string() }).partial(),
    downloadUrl: z
      .object({
        url: z.string(),
        expiresOn: z.string().datetime({ offset: true }),
      })
      .partial()
      
      .nullish(),
    uploadStatus: z.string(),
    assetAvatarDetail: AssetAvatarDetail.optional(),
    assetZipDetail: AssetZipDetail.optional(),
    assetFurnitureDetail: AssetFurnitureDetail.optional(),
    secureDownloadUrls: z
      .object({
        hrmPng: z.string().nullable(),
        hrmAstc: z.string().nullable(),
        hrmEtc2: z.string().nullable(),
      })
      .partial()
      
      .nullish(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const User = z
  .object({
    id: z.number().int(),
    vketId: z.string(),
    nameJa: z.string(),
    nameEn: z.string(),
  })
  
const AssetSerializer = z
  .object({
    id: z.number().int(),
    userId: z.number().int(),
    assetSettingId: z.number().int(),
    settingLabel: z.string(),
    assetType: z.string(),
    name: z.string(),
    description: z.string(),
    deleted: z.boolean(),
    published: z.boolean(),
    latestVersion: AssetVersion.optional(),
    assetVersions: z.array(AssetVersion).optional(),
    user: User.optional(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const postAssetsBody = z
  .object({
    settingLabel: z.string(),
    name: z.string(),
    description: z.string(),
    thumbnail: z.string().optional(),
    remoteThumbnailUrl: z.string().optional(),
    ingameThumbnail: z.string().optional(),
    remoteIngameThumbnailUrl: z.string().optional(),
    published: z.boolean().optional(),
    sdkVersion: z.string().optional(),
    worldId: z.string().optional(),
    subType: z.string().optional(),
    objectType: z.string().optional(),
    jsonPath: z.string().optional(),
    polygonCount: z.number().int().optional(),
    motionType: z.literal('general').optional(),
    targetAge: z.enum(['allAges', 'r18']).optional(),
    avatarType: z.string().optional(),
  })
  
const Asset = z
  .object({
    id: z.number().int(),
    userId: z.number().int(),
    assetSettingId: z.number().int(),
    assetType: z.string(),
    name: z.string(),
    description: z.string(),
    deleted: z.boolean(),
    published: z.boolean(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const putAssetsIdBody = z
  .object({ name: z.string(), description: z.string(), published: z.boolean() })
  .partial()
  
const postAssetsIdTokensBody = z
  .object({ version: z.string(), password: z.string().optional() })
  
const Token = z
  .object({
    id: z.number().int(),
    assetVersionId: z.number().int(),
    token: z.string(),
    password: z.string(),
    expiresAt: z.string().datetime({ offset: true }),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  
const putAssetsIdVersionsAssetVersionIdBody = z
  .object({
    thumbnail: z.string(),
    remoteThumbnailUrl: z.string(),
    ingameThumbnail: z.string(),
    remoteIngameThumbnailUrl: z.string(),
    motionType: z.literal('general'),
    targetAge: z.enum(['allAges', 'r18']),
    avatarType: z.string(),
    jsonPath: z.string(),
    polygonCount: z.number().int(),
  })
  .partial()
  
const TemporaryUpload = z
  .object({
    id: z.number().int(),
    userId: z.number().int(),
    file: z.object({ url: z.string() }).partial(),
    createdAt: z.string().datetime({ offset: true }),
    updatedAt: z.string().datetime({ offset: true }),
  })
  .partial()
  
const postTokensBody = z
  .object({ uuid: z.string(), passeord: z.string().optional() })
  
const AssetSettingUserBlacklist = z
  .object({ assetSettingId: z.number().int(), vketId: z.string() })
  

// フロントエンドで使うzod定義（model）
export const assetavatardetailSchema = AssetAvatarDetail
export type assetavatardetailType = z.infer<typeof assetavatardetailSchema>
export const assetzipdetailSchema = AssetZipDetail
export type assetzipdetailType = z.infer<typeof assetzipdetailSchema>
export const assetfurnituredetailSchema = AssetFurnitureDetail
export type assetfurnituredetailType = z.infer<
  typeof assetfurnituredetailSchema
>
export const assetversionSchema = AssetVersion
export type assetversionType = z.infer<typeof assetversionSchema>
export const userSchema = User
export type userType = z.infer<typeof userSchema>
export const assetSerializerSchema = AssetSerializer
export type assetSerializerType = z.infer<typeof assetSerializerSchema>
export const postAssetsBodySchema = postAssetsBody
export type postAssetsBodyType = z.infer<typeof postAssetsBodySchema>
export const assetSchema = Asset
export type assetType = z.infer<typeof assetSchema>
export const putAssetsIdBodySchema = putAssetsIdBody
export type putAssetsIdBodyType = z.infer<typeof putAssetsIdBodySchema>
export const postAssetsIdTokensBodySchema = postAssetsIdTokensBody
export type postAssetsIdTokensBodyType = z.infer<
  typeof postAssetsIdTokensBodySchema
>
export const tokenSchema = Token
export type tokenType = z.infer<typeof tokenSchema>
export const putAssetsIdVersionsAssetVersionIdBodySchema =
  putAssetsIdVersionsAssetVersionIdBody
export type putAssetsIdVersionsAssetVersionIdBodyType = z.infer<
  typeof putAssetsIdVersionsAssetVersionIdBodySchema
>
export const temporaryuploadSchema = TemporaryUpload
export type temporaryuploadType = z.infer<typeof temporaryuploadSchema>
export const postTokensBodySchema = postTokensBody
export type postTokensBodyType = z.infer<typeof postTokensBodySchema>
export const assetsettinguserblacklistSchema = AssetSettingUserBlacklist
export type assetsettinguserblacklistType = z.infer<
  typeof assetsettinguserblacklistSchema
>

// フロントエンドで使うzod定義（api request and response）
export const getAssetSettingLabelRequestSchema = z.object({
  label: z.string(),
})
export type getAssetSettingLabelRequestType = z.infer<
  typeof getAssetSettingLabelRequestSchema
>
export const getAssetSettingLabelResponseSchema = z
  .object({
    assetSetting: z
      .object({
        label: z.string(),
        assetType: z.string(),
        restrictUpload: z.boolean(),
        uploadLimitAmount: z.number().int().nullable(),
        restrictFileSize: z.boolean(),
        fileSizeLimit: z.number().int(),
      })
      ,
    remainUploadAmount: z.number().int().nullable(),
  })
  
export type getAssetSettingLabelResponseType = z.infer<
  typeof getAssetSettingLabelResponseSchema
>

export const getAssetsRequestSchema = z.object({
  limit: z.number().int().optional(),
  offset: z.number().int().optional(),
  settingLabel: z.string().optional(),
  assetType: z.string().optional(),
  published: z.boolean().optional(),
})
export type getAssetsRequestType = z.infer<typeof getAssetsRequestSchema>
export const getAssetsResponseSchema = z
  .object({
    assetCount: z.number().int(),
    assets: z.array(AssetSerializer).optional(),
  })
  
export type getAssetsResponseType = z.infer<typeof getAssetsResponseSchema>

export const postAssetsRequestSchema = z.object({
  body: postAssetsBody,
})
export type postAssetsRequestType = z.infer<typeof postAssetsRequestSchema>
export const postAssetsResponseSchema = z
  .object({
    presignedUrl: z.string(),
    expiresOn: z.string().datetime({ offset: true }),
    asset: Asset,
    version: z.string(),
  })
  
export type postAssetsResponseType = z.infer<typeof postAssetsResponseSchema>

export const getAssetsIdRequestSchema = z.object({
  id: z.string(),
})
export type getAssetsIdRequestType = z.infer<typeof getAssetsIdRequestSchema>
export const getAssetsIdResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type getAssetsIdResponseType = z.infer<typeof getAssetsIdResponseSchema>

export const putAssetsIdRequestSchema = z.object({
  body: putAssetsIdBody,
  id: z.string(),
})
export type putAssetsIdRequestType = z.infer<typeof putAssetsIdRequestSchema>
export const putAssetsIdResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type putAssetsIdResponseType = z.infer<typeof putAssetsIdResponseSchema>

export const deleteAssetsIdRequestSchema = z.object({
  id: z.string(),
})
export type deleteAssetsIdRequestType = z.infer<
  typeof deleteAssetsIdRequestSchema
>
export const deleteAssetsIdResponseSchema = z.void()
export type deleteAssetsIdResponseType = z.infer<
  typeof deleteAssetsIdResponseSchema
>

export const postAssetsIdTokensRequestSchema = z.object({
  body: postAssetsIdTokensBody,
  id: z.string(),
})
export type postAssetsIdTokensRequestType = z.infer<
  typeof postAssetsIdTokensRequestSchema
>
export const postAssetsIdTokensResponseSchema = z
  .object({ asset: AssetSerializer, version: AssetVersion, token: Token })
  
export type postAssetsIdTokensResponseType = z.infer<
  typeof postAssetsIdTokensResponseSchema
>

export const postAssetsIdUnlimitedTokensRequestSchema = z.object({
  body: postAssetsIdTokensBody,
  id: z.string(),
})
export type postAssetsIdUnlimitedTokensRequestType = z.infer<
  typeof postAssetsIdUnlimitedTokensRequestSchema
>
export const postAssetsIdUnlimitedTokensResponseSchema = z
  .object({ asset: AssetSerializer, version: AssetVersion, token: Token })
  
export type postAssetsIdUnlimitedTokensResponseType = z.infer<
  typeof postAssetsIdUnlimitedTokensResponseSchema
>

export const getAssetsIdUpdateUrlRequestSchema = z.object({
  id: z.string(),
})
export type getAssetsIdUpdateUrlRequestType = z.infer<
  typeof getAssetsIdUpdateUrlRequestSchema
>
export const getAssetsIdUpdateUrlResponseSchema = z
  .object({
    presignedUrl: z.string(),
    expiresOn: z.string().datetime({ offset: true }),
    version: z.string(),
  })
  
export type getAssetsIdUpdateUrlResponseType = z.infer<
  typeof getAssetsIdUpdateUrlResponseSchema
>

export const putAssetsIdVersionsAssetVersionIdRequestSchema = z.object({
  body: putAssetsIdVersionsAssetVersionIdBody,
  id: z.string(),
  assetVersionId: z.string(),
})
export type putAssetsIdVersionsAssetVersionIdRequestType = z.infer<
  typeof putAssetsIdVersionsAssetVersionIdRequestSchema
>
export const putAssetsIdVersionsAssetVersionIdResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type putAssetsIdVersionsAssetVersionIdResponseType = z.infer<
  typeof putAssetsIdVersionsAssetVersionIdResponseSchema
>

export const getAssetsOwnAssetsRequestSchema = z.object({
  settingLabel: z.string().optional(),
  assetType: z.string().optional(),
  limit: z.number().int().optional(),
  offset: z.number().int().optional(),
  published: z.boolean().optional(),
})
export type getAssetsOwnAssetsRequestType = z.infer<
  typeof getAssetsOwnAssetsRequestSchema
>
export const getAssetsOwnAssetsResponseSchema = z
  .object({
    assetCount: z.number().int(),
    assets: z.array(AssetSerializer).optional(),
  })
  
export type getAssetsOwnAssetsResponseType = z.infer<
  typeof getAssetsOwnAssetsResponseSchema
>

export const getAssetsPublishedRequestSchema = z.object({
  settingLabel: z.string().optional(),
  assetType: z.string().optional(),
  vketId: z.string().optional(),
  limit: z.number().int().optional(),
  offset: z.number().int().optional(),
})
export type getAssetsPublishedRequestType = z.infer<
  typeof getAssetsPublishedRequestSchema
>
export const getAssetsPublishedResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type getAssetsPublishedResponseType = z.infer<
  typeof getAssetsPublishedResponseSchema
>

export const getAssetsPublishedAssetRequestSchema = z.object({
  label: z.string(),
})
export type getAssetsPublishedAssetRequestType = z.infer<
  typeof getAssetsPublishedAssetRequestSchema
>
export const getAssetsPublishedAssetResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type getAssetsPublishedAssetResponseType = z.infer<
  typeof getAssetsPublishedAssetResponseSchema
>

export const getInternalAssetsRequestSchema = z.object({
  settingLabel: z.string().optional(),
  assetType: z.string().optional(),
  vketId: z.string().optional(),
  limit: z.number().int().optional(),
  offset: z.number().int().optional(),
  published: z.string().optional(),
  excludeDeleted: z.boolean().optional(),
  filterUploaded: z.boolean().optional(),
  objectType: z.string().optional(),
})
export type getInternalAssetsRequestType = z.infer<
  typeof getInternalAssetsRequestSchema
>
export const getInternalAssetsResponseSchema = z
  .object({
    assetCount: z.number().int(),
    assets: z.array(AssetSerializer).optional(),
  })
  
export type getInternalAssetsResponseType = z.infer<
  typeof getInternalAssetsResponseSchema
>

export const getInternalAssetsIdRequestSchema = z.object({
  id: z.string(),
})
export type getInternalAssetsIdRequestType = z.infer<
  typeof getInternalAssetsIdRequestSchema
>
export const getInternalAssetsIdResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type getInternalAssetsIdResponseType = z.infer<
  typeof getInternalAssetsIdResponseSchema
>

export const getInternalAssetsSearchByUuidUuidRequestSchema = z.object({
  uuid: z.string(),
})
export type getInternalAssetsSearchByUuidUuidRequestType = z.infer<
  typeof getInternalAssetsSearchByUuidUuidRequestSchema
>
export const getInternalAssetsSearchByUuidUuidResponseSchema = z
  .object({ asset: AssetSerializer })
  .partial()
  
export type getInternalAssetsSearchByUuidUuidResponseType = z.infer<
  typeof getInternalAssetsSearchByUuidUuidResponseSchema
>

export const getOperatorUserBlacklistsRequestSchema = z.object({
  settingLabel: z.string().optional(),
})
export type getOperatorUserBlacklistsRequestType = z.infer<
  typeof getOperatorUserBlacklistsRequestSchema
>
export const getOperatorUserBlacklistsResponseSchema = z
  .object({ userBlacklists: z.array(AssetSettingUserBlacklist) })
  .partial()
  
export type getOperatorUserBlacklistsResponseType = z.infer<
  typeof getOperatorUserBlacklistsResponseSchema
>

export const postOperatorUserBlacklistsRequestSchema = z.object({
  body: z.object({ settingLabel: z.string() }),
})
export type postOperatorUserBlacklistsRequestType = z.infer<
  typeof postOperatorUserBlacklistsRequestSchema
>
export const postOperatorUserBlacklistsResponseSchema = z
  .object({ created: z.boolean() })
  
export type postOperatorUserBlacklistsResponseType = z.infer<
  typeof postOperatorUserBlacklistsResponseSchema
>

export const deleteOperatorUserBlacklistsRequestSchema = z.object({
  body: z.object({ settingLabel: z.string() }),
})
export type deleteOperatorUserBlacklistsRequestType = z.infer<
  typeof deleteOperatorUserBlacklistsRequestSchema
>
export const deleteOperatorUserBlacklistsResponseSchema = z.void()
export type deleteOperatorUserBlacklistsResponseType = z.infer<
  typeof deleteOperatorUserBlacklistsResponseSchema
>

export const postTemporaryUploadsRequestSchema = z.object({
  body: z
    .object({ file: z.string() })
    .partial()
    ,
})
export type postTemporaryUploadsRequestType = z.infer<
  typeof postTemporaryUploadsRequestSchema
>
export const postTemporaryUploadsResponseSchema = z
  .object({ upload: TemporaryUpload })
  
export type postTemporaryUploadsResponseType = z.infer<
  typeof postTemporaryUploadsResponseSchema
>

export const postTokensRequestSchema = z.object({
  body: postTokensBody,
})
export type postTokensRequestType = z.infer<typeof postTokensRequestSchema>
export const postTokensResponseSchema = z
  .object({ asset: AssetSerializer, version: AssetVersion, token: Token })
  
export type postTokensResponseType = z.infer<typeof postTokensResponseSchema>

export const getTokensTokenRequestSchema = z.object({
  token: z.string(),
  password: z.string(),
})
export type getTokensTokenRequestType = z.infer<
  typeof getTokensTokenRequestSchema
>
export const getTokensTokenResponseSchema = z
  .object({ asset: AssetSerializer, version: AssetVersion })
  
export type getTokensTokenResponseType = z.infer<
  typeof getTokensTokenResponseSchema
>

export const getTokensV2TokenRequestSchema = z.object({
  token: z.string(),
  password: z.string(),
})
export type getTokensV2TokenRequestType = z.infer<
  typeof getTokensV2TokenRequestSchema
>
export const getTokensV2TokenResponseSchema = z
  .object({ asset: AssetSerializer, version: AssetVersion })
  
export type getTokensV2TokenResponseType = z.infer<
  typeof getTokensV2TokenResponseSchema
>

export const postTouserWhitelistskensRequestSchema = z.object({
  body: postTokensBody,
})
export type postTouserWhitelistskensRequestType = z.infer<
  typeof postTouserWhitelistskensRequestSchema
>
export const postTouserWhitelistskensResponseSchema = z
  .object({ registered: z.boolean() })
  .partial()
  
export type postTouserWhitelistskensResponseType = z.infer<
  typeof postTouserWhitelistskensResponseSchema
>

export const getUserWhitelistsRegisteredRequestSchema = z.object({
  body: z.object({ settingLabel: z.string() }),
})
export type getUserWhitelistsRegisteredRequestType = z.infer<
  typeof getUserWhitelistsRegisteredRequestSchema
>
export const getUserWhitelistsRegisteredResponseSchema = z
  .object({ registered: z.boolean() })
  .partial()
  
export type getUserWhitelistsRegisteredResponseType = z.infer<
  typeof getUserWhitelistsRegisteredResponseSchema
>
